import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState } from 'store/selectors';
import { mapWebinars } from 'api/dataMapper';

const paginationSelector = createSelector(getGlobalState, (state) => state.pagination);

export const selectLoadedArticles = createSelector(paginationSelector, (state) => state?.articles?.content);
export const selectArticlesLoadedFlag = createSelector(paginationSelector, (state) => state?.articles?.fullyLoaded);

export const selectLoadedCourses = createSelector(paginationSelector, (state) => state?.courses?.content);
export const selectCoursesLoadedFlag = createSelector(paginationSelector, (state) => state?.courses?.fullyLoaded);

export const selectLoadedPastConferences = createSelector(paginationSelector, (state) => state?.pastConferences?.content);
export const selectPastConferencesLoadedFlag = createSelector(paginationSelector, (state) => state?.pastConferences?.fullyLoaded);
export const selectLoadedFutureConferences = createSelector(paginationSelector, (state) => state?.futureConferences?.content);
export const selectFutureConferencesLoadedFlag = createSelector(paginationSelector, (state) => state?.futureConferences?.fullyLoaded);

export const selectLoadedLectures = createSelector(paginationSelector, (state) => state?.lectures?.content);
export const selectLecturesLoadedFlag = createSelector(paginationSelector, (state) => state?.lectures?.fullyLoaded);

export const selectLoadedPastWebinars = createSelector(paginationSelector, (state) => mapWebinars(state?.pastWebinars?.content));
export const selectPastWebinarsLoadedFlag = createSelector(paginationSelector, (state) => state?.pastWebinars?.fullyLoaded);
export const selectLoadedFutureWebinars = createSelector(paginationSelector, (state) => mapWebinars(state?.futureWebinars?.content));
export const selectFutureWebinarsLoadedFlag = createSelector(paginationSelector, (state) => state?.futureWebinars?.fullyLoaded);

export const selectLoadedCourseSeries = createSelector(paginationSelector, (state) => state?.courseSeriesList?.content);
export const selectCourseSeriesLoadedFlag = createSelector(paginationSelector, (state) => state?.courseSeriesList?.fullyLoaded);

export const selectLoadedWebinarSeries = createSelector(paginationSelector, (state) => state?.webinarSeriesList?.content);
export const selectWebinarSeriesLoadedFlag = createSelector(paginationSelector, (state) => state?.webinarSeriesList?.fullyLoaded);
