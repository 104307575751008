import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectMobile } from 'store/selectors';
import { selectLecturePageButton } from 'store/widgets';

/* Components */
import { LargeCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LectureSeriesCardComponent = ({
  lecturePath,
  buttonText,
  title,
  description,
  tags,
  videoLength,
  views,
  authors,
  isMobile,
  className,
}) => (
  <LargeCard
    className={cx('component', className)}
    href={lecturePath}
    image="//security-api.ru/st/QP/Beelinelab/images/conferenceBanner.png"
    topRight={isMobile ? (
      <LargeCard.Info
        runTime={videoLength}
        views={views}
      />
    ) : (
      <LargeCard.Creators creators={authors} />
    )}
    tag={<LargeCard.Tags tags={tags}/>}
    button={(
      <LargeCard.Button>
        {buttonText}
      </LargeCard.Button>
    )}
    title={(
      <LargeCard.Title className={cx('title')}>
        {title}
      </LargeCard.Title>
    )}
    description={(
      <LargeCard.Description className={cx('description')}>
        {description}
      </LargeCard.Description>
    )}
    info={!isMobile && (
      <LargeCard.Info
        runTime={videoLength}
        views={views}
      />
    )}
  />
);

const mapStateToProps = (state, props) => ({
  isMobile: selectMobile(state),
  lecturePath: `/lecture/${props.alias}`,
  buttonText: selectLecturePageButton(state),
});

LectureSeriesCardComponent.propTypes = {
  lecturePath: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  videoLength: PropTypes.string,
  views: PropTypes.number,
  tags: PropTypes.array,
  authors: PropTypes.array,
  isMobile: PropTypes.bool,
};

export const LectureSeriesCard = connect(mapStateToProps)(LectureSeriesCardComponent);
