import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { CourseCard, CourseSeriesCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CoursesRow = ({
  content,
  shouldRedirect,
  className,
}) => (
  <div className={cx('container', className)}>
    {content?.map((item) => {
      if (item.type === 'series') return (
        <CourseSeriesCard
          key={item.alias}
          className={cx('card')}
          shouldRedirect={shouldRedirect}
          {...item}
        />
      )

      return (
        <CourseCard
          key={item.alias}
          shouldRedirect={shouldRedirect}
          className={cx('card')}
          isBottomImage
          {...item}
        />
      )
    })}
  </div>
);

CoursesRow.propTypes = {
  shouldRedirect: PropTypes.bool,
  isTablet: PropTypes.bool,
  content: PropTypes.array,
};
