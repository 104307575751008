import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Edit from '../../../../../../../images/gallery_edit.svg';
import Placeholder from '../../../../../../../images/placeholder.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Avatar = ({ avatar, onChange, className }) => {
  const handleClick = () => {
    const input = document.getElementById('file');
    input.click();
  };

  const handleChange = (e) => onChange(e.target.files[0]);

  return (
    <div
      className={cx('component', !avatar && 'placeholder', className)}
      style={{ backgroundImage: avatar ? `url("${avatar}")` : undefined }}
      onClick={handleClick}
    >
      {!avatar && <Placeholder />}
      <input
        multiple={false}
        type="file"
        id="file"
        accept="image/png, image/jpeg"
        className={cx('input')}
        onChange={handleChange}
      />
      <Edit className={cx('icon')} />
    </div>
  )
};

Avatar.propTypes = {
  avatar: PropTypes.string,
  onChange: PropTypes.func,
};
