import { createAction } from '@reduxjs/toolkit';
import { POST_AVATAR, TOKEN_ERRORS } from 'constants/profileErrors';
import { POPUP_STEP, RESULT_STATUS } from 'constants/constants';
import { PHONE_CONFIRMATION_ERRORS } from 'constants/authorizationErrors';

import { deleteCookie } from 'utils/cookie';
import { unFormatPhone } from 'utils/format-string';

import { setAuthorized } from 'store/auth';
import { changePopupStep, setResultStatus } from 'store/page';
import { confirmPhone, setPhoneInputError } from 'store/auth/actions';

import { mapUser } from 'api/dataMapper';
import { postUserAvatar, requestUser } from 'api/requests';
import { changePhone, confirmPhoneChange } from 'api/requests/profile';

export const setUserData = createAction('SET_USER_DATA');
export const resetUserData = createAction('RESET_USER_DATA');
export const setUserChanges = createAction('SET_USER_CHANGES');
export const deleteChanges = createAction('DELETE_CHANGES');

export const setName = (e) => setUserChanges({ name: e.target.value });
export const setEmail = (e) => setUserChanges({ email: e.target.value });
export const setPhone = (e) => setUserChanges({ phone: e.target.value });
export const setTown = (town = '') => setUserChanges({ town });
export const setPosition = (e) => setUserChanges({ position: e.target.value });
export const setCompany = (company = '') => setUserChanges({ company });

export const setAvatar = (avatar) => setUserData({ avatar });

export const handleTokenError = (error) => (dispatch) => {
  if (TOKEN_ERRORS[error]) {
    deleteCookie('token');
    dispatch(setAuthorized(false));
  }
};

export const getUser = () => (dispatch) => requestUser()
  .then(({ data }) => dispatch(setUserData(mapUser(data.profile))));

export const postAvatar = (file) => (dispatch) => postUserAvatar({ file })
  .then(({ data }) => dispatch(setAvatar(data.fileUrl)))
  .catch((err) => {
    const { error } = err.response?.data || {};

    if (POST_AVATAR[error]) {
      dispatch(setResultStatus(RESULT_STATUS.imageFormat));
      return;
    }

    throw err;
  });

let confirmationToken = null;

export const changePhoneRequest = ({ phone }) => (dispatch) => {
  const phoneNumber = unFormatPhone(phone);

  const handleUnexpectedError = (expectedErrors, currentError) => {
    if (!expectedErrors[currentError]) {
      dispatch(setResultStatus(RESULT_STATUS.error));
    }

    changePopupStep(POPUP_STEP.ready);
  };

  return changePhone(phoneNumber)
    .then((res) => {
      confirmationToken = res.confirmationToken;
    })
    .catch(({ response: { data } }) => {
      dispatch(setPhoneInputError(data));

      handleUnexpectedError(PHONE_CONFIRMATION_ERRORS, data.error);
    });
};

export const changePhoneConfirmRequest = (code) => (dispatch) => dispatch(confirmPhone({
  code,
  confirmationToken,
}, confirmPhoneChange));