/**
 * Функция принимает id и вертикальное выравнивание для блока, к которому необходимо скроллить
 * @param {string} id - id блока, к которому необходим скролл
 * @param {'center' | 'end' | 'nearest' | 'start' | undefined} block - вертикальное выравнивание, по умолчанию 'start'
 * @returns {undefined}
 */

export const scrollTo = (id, block = 'start') => {
  document.getElementById(id)?.scrollIntoView({ block, behavior: 'smooth' });
};
