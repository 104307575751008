import { connect } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { useHover } from 'react-use-gesture';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { cxBool } from 'utils/format-string';

import { useAccordion } from 'hooks/useAccordion';

import { selectMobile } from 'store/selectors';

import { BlackButtonOrLink, Text } from 'components';
import Arrow from '../../../../images/arrow_mini.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AccordionTabComponent = ({
  question,
  isWithBorder,
  isMobile,
  onClick,
  borderColor,
  children,
  className,
}) => {
  const { isOpen, toggle } = useAccordion();

  const [animation, setContainer] = useSpring(() => ({
    borderColor: borderColor || 'rgba(255, 255, 255, 0.12)',
    width: '0',
    config: {
      duration: 250,
    },
  }));

  const [textAnimation, setText] = useSpring(() => ({
    opacity: 0,
    y: 40,
    config: {
      duration: 400,
    },
  }));

  const handleHover = useHover(({ hovering }) => !isOpen && setContainer.start({
    borderColor: hovering ? 'rgba(255, 255, 255, 1)' : borderColor || 'rgba(255, 255, 255, 0.12)',
    width: hovering ? '100%' : '0%',
  }));

  const handleClick = () => {
    onClick?.();
    toggle();
  };

  const handleMouseUp = () => {
    setText.start({ opacity: isOpen ? 0 : 0.75, y: isOpen ? 40 : 0 });
  };

  const handleInteraction = ({
    onMouseUp: handleMouseUp,
    onClick: handleClick,
  });

  return (
    <li
      className={cx('component', cxBool({ isOpen, isWithBorder }), className)}
      {...handleHover()}
      {...handleInteraction}
    >
      <animated.div className={cx('animated')} style={animation} />
      <div className={cx('tab')}>
        <Text className={cx('question', className)}>
          {question}
        </Text>

        <BlackButtonOrLink isRound className={cx('button')}>
          <Arrow className={cx('arrow', isOpen ? 'open' : 'closed')} />
        </BlackButtonOrLink>
      </div>

      <animated.div style={textAnimation}>
        {isOpen && children}
      </animated.div>
    </li>
  );
};

AccordionTabComponent.defaultProps = {
  isWithBorder: true,
};

AccordionTabComponent.propTypes = {
  question: PropTypes.string,
  borderColor: PropTypes.string,
  isWithBorder: PropTypes.bool,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
});

export const AccordionTab = connect(mapStateToProps)(AccordionTabComponent);
