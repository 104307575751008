import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { PrimaryButtonOrLink } from 'components';
import { FForm } from '../FForm';
import { MobileIdLogin } from 'components/AuthForm/components';

/* Store */
import { setFormData } from 'store/auth/actions';
import { selectFormData } from 'store/auth/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AuthFormComponent = ({
  formData,
  validateForm,
  button,
  setNextStep,
  onSubmit,
  shouldRenderMobileIdLogin,
  children,
  className,
}) => {
  const submitForm = () => {
    onSubmit();
    setNextStep?.();
  };

  return (
    <FForm
      className={cx('form', className)}
      onSubmit={submitForm}
      initialValues={formData}
      validateForm={validateForm}
      renderSubmitButton={({ valid, handleSubmit }) => button?.text && (
        <PrimaryButtonOrLink
          type="submit"
          className={cx('button')}
          size="large"
          disabled={!valid}
          onClick={handleSubmit}
        >
          {button.text}
        </PrimaryButtonOrLink>
      )}
      shouldRenderMobileIdLogin={({ valid }) => shouldRenderMobileIdLogin && <MobileIdLogin valid={valid} />}
    >
      {children}
    </FForm>
  );
};

const mapStateToProps = (state) => ({
  formData: selectFormData(state),
});

const mapDispatchToProps = {
  saveData: setFormData,
};

AuthFormComponent.propTypes = {
  shouldRenderMobileIdLogin: PropTypes.bool,
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
  formData: PropTypes.object,
  saveData: PropTypes.func,
  validateForm: PropTypes.func,
  setNextStep: PropTypes.func,
  onSubmit: PropTypes.func,
};

export const AuthForm = connect(mapStateToProps, mapDispatchToProps)(AuthFormComponent);
