import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Benefit = ({ icon, children, className }) => (
  <div className={cx('container', className)}>
    <div className={cx('icon')}>
      {icon}
    </div>
    {children}
  </div>
);

export default Benefit;
