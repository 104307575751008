import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { requestCompanies } from 'api/requests';

import { selectCompanyInputFields } from 'store/widgets';

import { Select } from 'components';

const CompanyInputComponent = ({
  inputProps,
  disabled,
  onBlur,
  className,
}) => (
  <Select
    className={className}
    inputProps={inputProps}
    requestData={requestCompanies}
    disabled={disabled}
    onBlur={onBlur}
    Component={(option) => <Select.CompanyOption name={option?.name} tin={option.tin} />}
  />
);

const mapStateToProps = (state) => ({
  inputProps: selectCompanyInputFields(state),
});

CompanyInputComponent.propTypes = {
  inputProps: PropTypes.object,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export const CompanyInput = connect(mapStateToProps, null)(CompanyInputComponent);
