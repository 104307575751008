import { Main } from 'pages/Main';
import { Webinar, Article, Lecture, Course } from 'pages/ContentItem';
import { Webinars, Articles, Lectures, Conferences, Courses, WebinarsSeries, CoursesSeries } from 'pages/ContentList';
import { Mobile } from 'components/Navigation/Mobile';
import { FAQ } from 'pages/FAQ';
import { Search } from 'pages/Search';

const routes = [
  {
    path: '/',
    component: Main,
    exact: true,
  },
  {
    path: '/menu',
    component: Mobile,
    exact: true,
  },
  {
    path: '/faq',
    component: FAQ,
    exact: true,
  },
  {
    path: '/searchResults',
    component: Search,
  },
  {
    path: '/webinars',
    component: Webinars,
    exact: true,
  },
  {
    path: '/webinar/:alias',
    component: Webinar,
  },
  {
    path: '/series/webinar/:alias',
    component: WebinarsSeries,
  },
  {
    path: '/articles',
    component: Articles,
    exact: true,
  },
  {
    path: '/article/:alias',
    component: Article,
  },
  {
    path: '/lectures',
    component: Lectures,
    exact: true,
  },
  {
    path: '/lecture/:alias',
    component: Lecture,
  },
  {
    path: '/conferences',
    component: Conferences,
    exact: true,
  },
  {
    path: '/courses',
    component: Courses,
    exact: true,
  },
  {
    path: '/course/:alias',
    component: Course,
  },
  {
    path: '/series/course/:alias',
    component: CoursesSeries,
  },
];

export default routes;

