import React from 'react';

import { ServerRenderContext } from '../context';


export function withSSRDiscovery() {
  return (WrappedComponent) => (
    (props) => (
      /** В данном контексте - передача value без значения выглядит слишком по-уродски */
      /* eslint-disable-next-line react/jsx-boolean-value */
      <ServerRenderContext.Provider value={true}>
        <WrappedComponent {...props} />
      </ServerRenderContext.Provider>
    )
  );
}
