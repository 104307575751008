import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import {
  selectCourseScorm,
  selectCourseScormIsVertical,
  selectCourseScormPlaceholder,
} from 'store/course/selectors';

import { Placeholder, Vertical, Horizontal } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ScormComponent = ({ src, isVertical, placeholder, className }) => {
  if (!src) return null;

  const [displayPlaceholder, setPlaceholder] = useState(false);

  useEffect(() => {
    setPlaceholder(!!placeholder);
  }, [placeholder]);

  const startScorm = () => setPlaceholder(false);

  return (
    <div className={cx('container', className)}>
      {displayPlaceholder ? (
        <Placeholder className={cx('placeholder')} src={placeholder} onClick={startScorm} />
      ) : isVertical ? (
        <Vertical src={src} />
      ) : (
        <Horizontal src={src} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  src: selectCourseScorm(state),
  isVertical: selectCourseScormIsVertical(state),
  placeholder: selectCourseScormPlaceholder(state),
});

export const Scorm = connect(mapStateToProps)(ScormComponent);
