import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { SocialButton, Text } from 'components';

/* Store */
import { selectMobileIdButton } from 'store/widgets';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MobileIdAuthButtonComponent = ({ disabled, button, onClick, className }) => (
  <SocialButton
    className={cx('component', className)}
    size="large"
    disabled={disabled}
    onClick={onClick}
  >
    <Text className={cx('text')} isCentered>
      {button}
    </Text>
  </SocialButton>
);

const mapStateToProps = (state) => ({
  button: selectMobileIdButton(state),
});

MobileIdAuthButtonComponent.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export const MobileIdAuthButton = connect(mapStateToProps, null)(MobileIdAuthButtonComponent);
