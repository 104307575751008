import { useDispatch } from 'react-redux';
import { useOverlay } from 'hooks/useOverlay';

/* Store */
import { resetPopup, setPopup, setResultStatus } from 'store/page/actions';

/* Constants */
import { RESULT_STATUS, POPUP_STEP, POPUP_TYPE } from 'constants/constants';

export const usePopup = () => {
  const dispatch = useDispatch();
  const { addOverlay, removeOverlay } = useOverlay();

  const openPopup = ({ type, step, eventType }) => {
    dispatch(setPopup({ type, step, eventType }));
    addOverlay();
  };

  const closePopup = () => {
    dispatch(resetPopup());
    removeOverlay();
  };

  const openAuthPopup = () => openPopup({ type: POPUP_TYPE.auth, step: POPUP_STEP.form });

  const openAccountPopup = () => openPopup({ type: POPUP_TYPE.userAccount, step: POPUP_STEP.form });

  const openChangeEmailPopup = () => openPopup({ type: POPUP_TYPE.userAccount, step: POPUP_STEP.changeEmail });

  const openChangePhonePopup = () => openPopup({ type: POPUP_TYPE.userAccount, step: POPUP_STEP.changePhone });

  const openEmailConfirmedPopup = () => {
    openPopup({ type: POPUP_TYPE.auth, step: POPUP_STEP.ready });
    dispatch(setResultStatus(RESULT_STATUS.emailConfirmed));
  };

  const openConfirmationErrorPopup = () => {
    openPopup({ type: POPUP_TYPE.auth, step: POPUP_STEP.ready });
    dispatch(setResultStatus(RESULT_STATUS.confirmationError));
  };

  return {
    openPopup,
    closePopup,
    openAuthPopup,
    openAccountPopup,
    openChangeEmailPopup,
    openChangePhonePopup,
    openEmailConfirmedPopup,
    openConfirmationErrorPopup,
  };
};
