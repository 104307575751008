import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { selectNavigationTabs } from 'store/widgets';

import { Tab } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TabsComponent = ({ activeTab, tabs, onChange }) => (
  <div className={cx('tabs')}>
    {tabs?.map(({ key, name }) => (
      <Tab
        key={key}
        active={activeTab === key}
        onChange={() => onChange(key)}
      >
        {name}
      </Tab>
    ))}
  </div>
);

const mapStateToProps = (state) => ({
  tabs: selectNavigationTabs(state),
});

export const Tabs = connect(mapStateToProps)(TabsComponent);
