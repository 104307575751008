import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { requestBanners } from 'api/requests';

/* Store */
import { selectDesktop } from 'store/selectors';
import { filterBannersByAccessLevel } from 'store/banners/actions';
import { selectBanners } from 'store/banners/selectors';
import { selectIsAuthorized } from 'store/auth/selectors';
import { selectMainPageBanners, selectMainTitle } from 'store/widgets';

/* Components */
import { Wrapper, MenuButton, DeskNavigation as Navigation, BannersBlock } from 'components';
import { FutureConferences, FutureWebinars, WebinarSeries, CourseSeries } from 'pages/ConnectedContent';

import styles from './styles.pcss';
import { AnimatedTitle } from 'pages/Main/components/AnimatedTitle';

const cx = classNames.bind(styles);

const MainComponent = ({
  title,
  isDesktop,
  requestedBanners,
  onRequestBanners,
  banners,
  isAuthorized,
}) => {
  useEffect(() => {
    if (typeof requestedBanners?.join(',') === 'string') {
      requestBanners(requestedBanners, onRequestBanners);
    }
  }, [requestedBanners, onRequestBanners, isAuthorized]);

  return (
    <>
      <div className={cx('header')}>
        <AnimatedTitle titles={title} className={styles.title} />
      </div>

      <Wrapper className={cx('container')} fullWidth={!isDesktop} width={1472}>
        <Navigation className={cx('navigation')} />

        <FutureConferences />

        <FutureWebinars />

        <WebinarSeries />

        <CourseSeries />

        <BannersBlock banners={banners} />
      </Wrapper>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  title: selectMainTitle(state),
  requestedBanners: selectMainPageBanners(state),
  banners: selectBanners(state),
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = {
  onRequestBanners: filterBannersByAccessLevel,
};

MainComponent.propTypes = {
  isDesktop: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  title: PropTypes.arrayOf(PropTypes.string),
};

export const Main = connect(mapStateToProps, mapDispatchToProps)(MainComponent);
