import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectCoursePageTitle } from 'store/widgets';

/* Components */
import { CoursesBlock, CourseSeries } from 'pages/ConnectedContent';
import { PageTemplate } from '../PageTemplate';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CoursesComponent = ({ title }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/courses_page_banner.png"
    backgroundClassName={cx('background')}
  >
    <CourseSeries />

    <CoursesBlock />
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectCoursePageTitle(state),
});

CoursesComponent.propTypes = {
  title: PropTypes.string,
};

export const Courses = connect(mapStateToProps, null)(CoursesComponent);
