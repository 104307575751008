import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Store */
import { selectResultStatus, selectErrorMessage } from 'store/page/selectors';

/* Components */
import { Result } from 'components';

const ResultStepComponent = ({ onClose, texts, resultStatus, errorMessage }) => {
  const stepTexts = useMemo(
    () => (errorMessage ? { text: errorMessage } : texts[resultStatus]),
    [errorMessage, resultStatus, texts],
  );

  return (
    <Result
      onClose={onClose}
      {...stepTexts}
    />
  );
};

const mapStateToProps = (state) => ({
  resultStatus: selectResultStatus(state),
  errorMessage: selectErrorMessage(state),
});

ResultStepComponent.propTypes = {
  onClose: PropTypes.func,
  texts: PropTypes.object,
  resultStatus: PropTypes.string,
  errorMessage: PropTypes.string,
};

export const ResultStep = connect(mapStateToProps)(ResultStepComponent);
