import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardImage = ({ image, imageClassName }) => (
  <div className={cx('image', imageClassName)} style={{ backgroundImage: `url("${image}")` }} />
);

CardImage.propTypes = {
  image: PropTypes.string,
};
