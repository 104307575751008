import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState, selectMobile, selectTablet } from 'store/selectors';
import { authSelector } from 'store/auth/selectors';
import { filterBanners } from 'api/dataMapper';

const webinarSelector = createSelector(getGlobalState, (state) => state.webinar || {});

export const selectWebinarData = webinarSelector;
export const selectWebinarId = (state) => webinarSelector(state).id;
export const selectWebinarTitle = (state) => webinarSelector(state).title;
export const selectWebinarImage = (state) => webinarSelector(state).image;
export const selectWebinarLength = (state) => webinarSelector(state).videoLength;
export const selectWebinarTags = (state) => webinarSelector(state).tags;
export const selectWebinarAuthors = (state) => webinarSelector(state).authors;
export const selectWebinarPlacesLeft = (state) => webinarSelector(state).placesLeft;
export const selectWebinarRegistered = (state) => webinarSelector(state).registered;
export const selectWebinarDate = (state) => webinarSelector(state).date;
export const selectWebinarStatus = (state) => webinarSelector(state).isFuture;
export const selectWebinarAlias = (state) => webinarSelector(state).alias;
export const selectWebinarTheme = (state) => webinarSelector(state).theme;
export const selectWebinarContent = (state) => webinarSelector(state).content;
export const selectWebinarVideo = (state) => webinarSelector(state).video;
export const selectWebinarSource = (state) => webinarSelector(state).isExternal;
export const selectWebinarRelatedContent = (state) => webinarSelector(state).relatedContent;

export const selectWebinarBanners = createSelector(
  [webinarSelector, authSelector],
  ({ banners }, { isAuthorized }) => filterBanners(banners, isAuthorized),
);

export const selectWebinarRelatedContentSlides = createSelector(
  [selectMobile, selectTablet],
  (isMobile, isTablet) => {
    if (isMobile) return 1;
    if (isTablet) return 2;

    return 3;
  },
);
