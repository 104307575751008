import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { setPosition, setCompany } from 'store/user';

/* Components */
import { Title } from '../Title';
import { CompanyInput, PositionInput } from 'components/Inputs';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ExperienceComponent = ({ title, onSetPosition, onSetCompany, className }) => (
  <div className={className}>
    <Title>
      {title}
    </Title>

    <div className={cx('form')}>
      <CompanyInput className={cx('field')} onBlur={onSetCompany} />

      <PositionInput className={cx('field')} onBlur={onSetPosition} />
    </div>
  </div>
);

ExperienceComponent.propTypes = {
  title: PropTypes.string,
};

const mapDispatchToProps = {
  onSetPosition: setPosition,
  onSetCompany: setCompany,
};

export const Experience = connect(null, mapDispatchToProps)(ExperienceComponent);
