import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthorized, selectMobileIdNeedsOTPError } from 'store/auth/selectors';
import { resetErrors } from 'store/auth/actions';
import { handleMask } from 'utils/mask';
import { validatePhone } from 'utils/formValidators';

export const usePhoneInput = () => {
  const dispatch = useDispatch();
  const success = useSelector(selectIsAuthorized);
  const needsOTP = useSelector(selectMobileIdNeedsOTPError);

  const [codeInput, setCodeInput] = useState(false);
  const [valid, setValid] = useState(false);

  const displayCodeInput = () => setCodeInput(true);

  const handleValidate = (value) => validatePhone(value);

  const handleChange = (e) => {
    setValid(!handleValidate(e.target.value));

    return handleMask(e.target.value, 'phone', '+7 ');
  };

  const handleEditNumber = () => {
    setCodeInput(false);

    dispatch(resetErrors());
  };

  useEffect(() => {
    if (success) {
      setCodeInput(false);
    }
  }, [success]);

  useEffect(() => {
    if (needsOTP) {
      setCodeInput(true);
    }
  }, [needsOTP]);

  return {
    codeInput,
    success,
    handleEditNumber,
    handleChange,
    valid,
    handleValidate,
    displayCodeInput,
  };
};
