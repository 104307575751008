import { withErrorMessage, withLabel, withFinalField, withIcon, withSubmitButton } from './hocs';
import { Input } from './components';
import { CodeInput } from 'components/Inputs/FFInputs';
export { EditButton, SubmitButton, EmailConfirmButton } from './components';
export { Select } from './components/Select';
export {
  NameInput,
  EmailInput,
  CompanyInput,
  TownInput,
  PositionInput,
  CodeInput,
  PhoneInput,
} from './FFInputs';

export const BasicInput = withErrorMessage(withLabel(Input));
export const InputWithIcon = withIcon(withLabel(Input));
export const FFInput = withFinalField(BasicInput);
export const FFInputWithIcon = withErrorMessage(withFinalField(InputWithIcon));
export const FFPhoneInput = withErrorMessage(withFinalField(withSubmitButton(InputWithIcon)));
