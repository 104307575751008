import { createReducer } from '@reduxjs/toolkit';

import { setCurrentWebinar } from 'store/webinar/actions';

export const webinar = createReducer({}, {
  [setCurrentWebinar.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
