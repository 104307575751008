import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectMobile } from 'store/selectors';

import styles from './styles.pcss';

/* Components */
import { Title, Label, Description, Button, EmailForm } from './components';
import { BANNER_BUTTON_ACTIONS } from 'constants/constants';

const cx = classNames.bind(styles);

const BannerComponent = ({
  title,
  description,
  image,
  label,
  link,
  action,
  eventId,
  eventType,
  buttonText,
  notMobile,
  className,
}) => notMobile && (
  <div className={cx('component', className)}>
    <div className={cx('content')}>
      {label && (
        <Banner.Label className={cx('label')}>
          {label}
        </Banner.Label>
      )}

      {title && (
        <Banner.Title className={cx('title')}>
          {title}
        </Banner.Title>
      )}

      {description && (
        <Banner.Description className={cx('description')}>
          {description}
        </Banner.Description>
      )}

      {action === BANNER_BUTTON_ACTIONS.formSubmit && <EmailForm />}

      {buttonText && (
        <Banner.Button
          link={link}
          buttonText={buttonText}
          action={action}
          eventId={eventId}
          eventType={eventType}
        />
      )}
    </div>

    <div className={cx('image')} style={{ backgroundImage: `url("${image}")` }} />
  </div>
);

BannerComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  action: PropTypes.string,
  eventId: PropTypes.number,
  eventType: PropTypes.string,
  link: PropTypes.object,
  notMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  notMobile: !selectMobile(state),
});

export const Banner = connect(mapStateToProps)(BannerComponent);

Banner.Title = Title;
Banner.Label = Label;
Banner.Description = Description;
Banner.Button = Button;
