import { connect } from 'react-redux';

import { validateText } from 'utils/formValidators';

import { selectInputWarningText, selectPositionInputFields } from 'store/widgets';

import { FFInput } from 'components/Inputs';

const PositionInputComponent = ({ inputProps, message, ...restProps }) => (
  <FFInput
    {...inputProps}
    {...restProps}
    validate={validateText}
    message={message}
  />
);

const mapStateToProps = (state) => ({
  inputProps: selectPositionInputFields(state),
  message: selectInputWarningText(state),
});

export const PositionInput = connect(mapStateToProps)(PositionInputComponent);
