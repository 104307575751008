import { createAction } from '@reduxjs/toolkit';
import {
  requestMoreArticles,
  requestMoreConferences,
  requestMoreCourses,
  requestMoreCourseSeries,
  requestMoreLectures,
  requestMoreWebinars,
  requestMoreWebinarSeries,
} from 'api/requests/pagination';

export const setMoreArticles = createAction('SET_MORE_ARTICLES');
export const setMorePastConferences = createAction('SET_MORE_PAST_CONFERENCES');
export const setMoreFutureConferences = createAction('SET_MORE_FUTURE_CONFERENCES');
export const setMoreCourses = createAction('SET_MORE_COURSES');
export const setMoreLectures = createAction('SET_MORE_LECTURES');
export const setMorePastWebinars = createAction('SET_MORE_PAST_WEBINARS');
export const setMoreFutureWebinars = createAction('SET_MORE_FUTURE_WEBINARS');
export const setMoreCourseSeries = createAction('SET_MORE_COURSE_SERIES');
export const setMoreWebinarSeries = createAction('SET_MORE_WEBINAR_SERIES');

export const loadMoreArticles = (pageNumber) => (dispatch) => requestMoreArticles(pageNumber)
  .then((data) => dispatch(setMoreArticles(data)));

export const loadMoreCourses = (pageNumber) => (dispatch) => requestMoreCourses(pageNumber)
  .then((data) => dispatch(setMoreCourses(data)));

const loadMoreConferences = (pageNumber, isFuture) => (dispatch) => requestMoreConferences(pageNumber, isFuture)
  .then((data) => (isFuture ? dispatch(setMoreFutureConferences(data)) : dispatch(setMorePastConferences(data))));

export const loadMorePastConferences = (pageNumber) => loadMoreConferences(pageNumber);

export const loadMoreFutureConferences = (pageNumber) => loadMoreConferences(pageNumber, true);

export const loadMoreLectures = (pageNumber) => (dispatch) => requestMoreLectures(pageNumber)
  .then((data) => dispatch(setMoreLectures(data)));

const loadMoreWebinars = (pageNumber, isFuture) => (dispatch) => requestMoreWebinars(pageNumber, isFuture)
  .then((data) => (isFuture ? dispatch(setMoreFutureWebinars(data)) : dispatch(setMorePastWebinars(data))));

export const loadMorePastWebinars = (pageNumber) => loadMoreWebinars(pageNumber);

export const loadMoreFutureWebinars = (pageNumber) => loadMoreWebinars(pageNumber, true);

export const loadMoreCourseSeries = (pageNumber) => (dispatch) => requestMoreCourseSeries(pageNumber)
  .then((data) => dispatch(setMoreCourseSeries(data)));

export const loadMoreWebinarSeries = (pageNumber) => (dispatch) => requestMoreWebinarSeries(pageNumber)
  .then((data) => dispatch(setMoreWebinarSeries(data)));
