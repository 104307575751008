import { storeActions } from 'utils/loadData';
import { mappers } from 'utils/mappers';
import { setTexts } from 'store/widgets';

export const setDataInStore = ({ data, dispatch, shouldLoadWidgets }) => {
  Object.keys(data).forEach((key) => {
    if (storeActions[key]) {
      const action = storeActions[key];
      const mapper = mappers[key];
      dispatch(action(mapper(data[key])));
    } else {
      if (shouldLoadWidgets) {
        dispatch(setTexts(data.widgets));
      }
    }
  });
};
