import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Image = ({ image, className }) => (
  <div className={cx('component')}>
    <div className={cx('image', className)} style={{ backgroundImage: `url("${image}")` }} />
  </div>
);

