import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectArticlePageTitle } from 'store/widgets';

/* Components */
import { PageTemplate } from '../PageTemplate';
import { ArticlesBlock } from 'pages/ConnectedContent';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ArticlesComponent = ({ title }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/articles_page_banner.png"
    backgroundClassName={cx('background')}
  >
    <ArticlesBlock />
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectArticlePageTitle(state),
});

ArticlesComponent.propTypes = {
  title: PropTypes.string,
};

export const Articles = connect(mapStateToProps, null)(ArticlesComponent);
