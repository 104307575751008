import React from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';

import { filter, map, passAsIs } from 'utils/fn';
import { BackendDataContext, ServerRenderContext } from '../context';
import { useFetchData, useFetchMeta } from 'hooks/useFetchData';

export function useBackendData(schema) {
  const backendData = React.useContext(BackendDataContext);
  const params = useParams();

  const data = map(schema, (item, key) => item.convertData(backendData[key]));

  const missingSchema = filter(schema, (item, key) => !data[key] || (key !== 'widgets' && params?.alias !== data[key]?.alias));

  const { fetchedData, isLoading } = useFetchData(missingSchema);
  const meta = useFetchMeta();

  return [{ ...data, ...fetchedData }, meta, isLoading];
}

export function withBackendData() {
  return (WrappedComponent) => ({ data, ...otherProps }) => (
    <BackendDataContext.Provider value={data}>
      <WrappedComponent {...otherProps} context={data} />
    </BackendDataContext.Provider>
  );
}

/**
 * BackEnd data provides a unified way to call endpoints both for backend SSR and frontend client routing
 * @param composeUrlSchema - () => <Record<string, Endpoint> - keys are item slugs, and values are urls to request data from
 * @param convertData - takes data from all the sources in schema and joins them somehow
 */
export function withEndpointData(composeUrlSchema, convertData = passAsIs) {
  return (WrappedComponent) => (
    ({ meta, ...restProps }) => {
      const { search } = useLocation();
      const params = useParams();
      const schema = composeUrlSchema({ search, params });
      const isDiscoveryCall = React.useContext(ServerRenderContext);

      const [data, fetchedMeta, isLoading] = useBackendData(isDiscoveryCall ? {} : schema);

      return isDiscoveryCall ? (
        <Helmet>
          {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
          <html {...map(schema, (item) => item.getUrl())} />
        </Helmet>
      ) : (
        <WrappedComponent {...restProps} data={convertData(data)} meta={fetchedMeta || meta} isLoading={isLoading} />
      );
    }
  );
}

export class Endpoint {
  constructor(url, dataConverter = passAsIs) {
    this.url = url;
    this.dataConverter = dataConverter;
    this.fetchData = async () => await axios.get(url).then(({ data }) => data);
  }

  getUrl() { return this.url; }

  convertData(rawData = null) {
    try {
      return this.dataConverter(rawData);
    } catch (err) {
      console.error(new Error(JSON.stringify({
        error: 'SSR Data conversion failed',
        details: {
          endpoint: this.url,
          rawData,
        },
      }, null, 2)));
    }
  }
}
