import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectLecturePageButton } from 'store/widgets';

/* Components */
import { VerticalCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LectureCardComponent = ({
  title,
  videoLength,
  tags,
  authors,
  partners,
  views,
  lecturePath,
  buttonText,
  className,
}) => (
  <VerticalCard
    className={cx('component', className)}
    href={lecturePath}
    tags={<VerticalCard.Tags className={cx('tags')} tags={tags} />}
    topRight={(
      <VerticalCard.Info className={cx('topRightInfo')} runTime={videoLength} views={views} />
    )}
    button={(
      <VerticalCard.Button className={cx('button')}>
        {buttonText}
      </VerticalCard.Button>
    )}
  >
    <div className={cx('content')}>
      <VerticalCard.Title className={cx('title')}>
        {title}
      </VerticalCard.Title>

      <VerticalCard.Creators
        authors={authors}
        partners={partners}
        className={cx('authors')}
        shouldRenderInfo={authors?.length <= 1}
      />
    </div>
  </VerticalCard>
);

const mapStateToProps = (state, props) => ({
  lecturePath: `/lecture/${props.alias}`,
  buttonText: selectLecturePageButton(state),
});

LectureCardComponent.propTypes = {
  title: PropTypes.string,
  lecturePath: PropTypes.string,
  buttonText: PropTypes.string,
  videoLength: PropTypes.string,
  views: PropTypes.number,
  tags: PropTypes.array,
  authors: PropTypes.array,
  partners: PropTypes.array,
};

export const LectureCard = connect(mapStateToProps)(LectureCardComponent);
