import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';

/* Components */
import { LargeCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CourseSeriesCardComponent = ({
  image,
  seriesPath,
  title,
  description,
  tags,
  isDesktop,
  speakers,
  date,
  className,
}) => (
  <LargeCard
    className={cx('component', className)}
    href={seriesPath}
    image={image}
    topRight={isDesktop ? (
      <LargeCard.Creators creators={speakers} />
    ) : (
      <LargeCard.Info date={date} />
    )}
    tag={<LargeCard.Tags tags={tags} />}
    title={(
      <LargeCard.Title className={cx('title')}>
        {title}
      </LargeCard.Title>
    )}
    description={(
      <LargeCard.Description className={cx('description')}>
        {description}
      </LargeCard.Description>
    )}
  />
);

const mapStateToProps = (state, props) => ({
  isDesktop: selectDesktop(state),
  seriesPath: `/series/course/${props.alias}`,
});

CourseSeriesCardComponent.propTypes = {
  seriesPath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.array,
  speakers: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export const CourseSeriesCard = connect(mapStateToProps)(CourseSeriesCardComponent);
