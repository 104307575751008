import { connect } from 'react-redux';

/* Store */
import { selectCourseSeriesAmount, selectCourseSeries } from 'store/content/selectors';
import {
  loadMoreCourseSeries,
  selectCourseSeriesLoadedFlag,
  selectLoadedCourseSeries,
} from 'store/pagination';

/* Components */
import { Template } from './Template';

const SeriesComponent = ({ series, loaded, amount, fullyLoaded, onLoadMore }) => (
  <Template
    courses={series}
    loaded={loaded}
    amount={amount}
    fullyLoaded={fullyLoaded}
    onLoadMore={onLoadMore}
  />
);

const mapStateToProps = (state) => ({
  series: selectCourseSeries(state),
  loaded: selectLoadedCourseSeries(state),
  amount: selectCourseSeriesAmount(state),
  fullyLoaded: selectCourseSeriesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreCourseSeries,
};

export const CourseSeries = connect(mapStateToProps, mapDispatchToProps)(SeriesComponent);
