import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useAdaptiveSize } from 'hooks/useAdaptiveSize';

/**
 * Listens resize to determine resolution, and pushes current breakpoint value into store
 */
export function withAdaptivity({ breakpoints, setBreakpoint }) {
  return (WrappedComponent) => {
    const ResizeListener = ({ onSetBreakpoint, ...props }) => {
      const breakpoint = useAdaptiveSize(breakpoints);

      React.useEffect(() => {
        onSetBreakpoint(breakpoint);
      }, [breakpoint]);

      return React.useMemo(() => (
        <WrappedComponent {...props} />
      ), []);
    };

    ResizeListener.propTypes = {
      onSetBreakpoint: PropTypes.func,
    };

    return connect(null, { onSetBreakpoint: setBreakpoint })(ResizeListener);
  };
}
