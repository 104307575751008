import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

/* Hooks */
import { useTabs } from 'hooks/useTabs';
import { usePopup } from 'hooks/usePopup';

/* Components */
import { Popup, Step, Wizard, ResultStep } from 'components';
import { FormStep } from 'components/AuthPopup/Steps';

/* Store */
import { selectIsAuthorized } from 'store/auth/selectors';
import { selectAuthResultScreens, selectDefaultPopupImage, selectMainAuthTexts } from 'store/widgets';
import {
  selectActivePopupStep,
  selectAuthPopupOpened,
  selectResultStatus,
} from 'store/page/selectors';
import { changePopupStep, resetResultStatus } from 'store/page';
import { resetErrors, resetFormData, resetLoginProgress } from 'store/auth/actions';
import { selectDesktop, selectMobile } from 'store/selectors';

/* Constants */
import { DEFAULT_AUTH_TAB, POPUP_STEP } from 'constants/constants';

const cx = classNames.bind(styles);

const AuthPopupComponent = ({
  auth,
  resultStatus,
  authResultScreens,
  activeStep,
  onResetError,
  onChangeStep,
  onResetResult,
  onResetProgress,
  onResetFormData,
  isDesktop,
  isMobile,
  isFullView,
  isOpened,
  defaultImage,
}) => {
  const { activeTab, changeTab } = useTabs(DEFAULT_AUTH_TAB);
  const { closePopup } = usePopup();

  const handleTabChange = (tab) => {
    onResetError();
    onResetProgress();

    changeTab(tab);
  };

  const setNextStep = () => {
    onChangeStep(POPUP_STEP.ready);
  };

  const handleClose = () => {
    onResetError();
    onResetProgress();

    if (resultStatus) {
      onResetResult();
    }

    closePopup();
  };

  useEffect(() => {
    if (resultStatus && authResultScreens[resultStatus]) {
      onChangeStep(POPUP_STEP.ready);
    }
  }, [resultStatus]);

  return (
    <Wizard
      className={cx('container')}
      activeStep={activeStep}
      onClose={handleClose}
      opened={isOpened}
      image={!isFullView && <Popup.Image image={resultStatus ? defaultImage : auth[activeTab]?.image} />}
      closeButton={(
        <Popup.CloseButton
          mode={isDesktop ? 'secondary' : 'black'}
          onClose={handleClose}
        />
      )}
      withNavBar={!isMobile || isFullView}
      isFullView={isFullView}
    >
      <Step name={POPUP_STEP.form}>
        <FormStep active={activeTab} onClick={handleTabChange} onNextStep={setNextStep} />
      </Step>

      <Step name={POPUP_STEP.ready}>
        <ResultStep
          texts={authResultScreens}
          onClose={() => {
            handleClose();
            onResetFormData();
          }}
        />
      </Step>
    </Wizard>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: !!selectIsAuthorized(state),
  resultStatus: selectResultStatus(state),
  authResultScreens: selectAuthResultScreens(state),
  auth: selectMainAuthTexts(state),
  activeStep: selectActivePopupStep(state),
  isDesktop: selectDesktop(state),
  isMobile: selectMobile(state),
  isFullView: !selectDesktop(state),
  defaultImage: selectDefaultPopupImage(state),
  isOpened: selectAuthPopupOpened(state),
});

const mapDispatchToProps = {
  onChangeStep: changePopupStep,
  onResetError: resetErrors,
  onResetResult: resetResultStatus,
  onResetProgress: resetLoginProgress,
  onResetFormData: resetFormData,
};

const CustomPropTypes = PropTypes.shape({
  tabName: PropTypes.string,
  fields: PropTypes.array,
  button: PropTypes.object,
  additional: PropTypes.object,
});

AuthPopupComponent.defaultProps = {
  auth: {},
};

AuthPopupComponent.propTypes = {
  auth: PropTypes.shape({
    registration: CustomPropTypes,
    login: CustomPropTypes,
  }),
  authResultScreens: PropTypes.object,
  resultStatus: PropTypes.string,
  activeStep: PropTypes.string,
  defaultImage: PropTypes.string,
  onChangeStep: PropTypes.func,
  onResetError: PropTypes.func,
  onResetResult: PropTypes.func,
  onResetProgress: PropTypes.func,
  onResetFormData: PropTypes.func,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isFullView: PropTypes.bool,
  isOpened: PropTypes.bool,
};

export const AuthPopup = connect(mapStateToProps, mapDispatchToProps)(AuthPopupComponent);
