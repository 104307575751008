import { useState, useRef, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { promisifyObject, map } from 'utils/fn';
import { requestMeta } from 'api/requests';

export const useFetchData = (schema) => {
  const params = useParams();
  const prevAlias = useRef(params.alias);
  const shouldFetchData = useRef(!!Object.keys(schema).length);

  const [fetchedData, setFetchedData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (prevAlias.current !== params.alias) {
      shouldFetchData.current = true;
      prevAlias.current = params.alias;
    }

    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      prevAlias.current = params.alias;
      setLoading(true);

      promisifyObject(map(schema, (item) => item.fetchData().then((res) => item.dataConverter(res))))
        .then(setFetchedData)
        .finally(() => setLoading(false));
    }
  }, [schema, params]);

  return {
    isLoading,
    fetchedData,
  };
};

export const useFetchMeta = () => {
  const { pathname } = useLocation();
  const shouldFetchData = useRef(false);
  const prevUrl = useRef(pathname);

  const [meta, setMeta] = useState(null);

  useEffect(() => {
    if (prevUrl.current !== pathname) {
      shouldFetchData.current = true;
      prevUrl.current = pathname;
    }

    if (shouldFetchData.current) {
      shouldFetchData.current = false;
      prevUrl.current = pathname;

      requestMeta(pathname).then(setMeta);
    }
  }, [pathname]);

  return meta;
};
