import { useDispatch } from 'react-redux';

/* Store */
import { removeOverlay as onRemoveOverlay, setOverlay } from 'store/page/actions';

export const useOverlay = () => {
  const dispatch = useDispatch();

  const addOverlay = () => {
    dispatch(setOverlay(true));
    document.body.classList.add('fixed');
  };

  const removeOverlay = () => {
    dispatch(onRemoveOverlay());
    document.body.classList.remove('fixed');
  };


  return { addOverlay, removeOverlay };
};
