import axios from 'axios';

import { deleteCookie, getCookie, setAuthTokenCookie, setRefreshTokenCookie } from 'utils/cookie';

export const refreshAccessToken = async () => {
  const currentRefreshToken = getCookie('refresh');
  deleteCookie('token');

  const { accessToken, refreshToken } = await axios.post('/auth/refresh', { refreshToken: currentRefreshToken })
    .then((res) => res.data);

  if (accessToken && accessToken !== '') {
    setAuthTokenCookie(accessToken);
  }

  if (refreshToken && refreshToken !== '') {
    setRefreshTokenCookie(refreshToken);
  }
};
