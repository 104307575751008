import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useScroll } from 'react-use-gesture';
import { useDebounce } from 'ahooks';

import { useEventListener } from 'hooks/useEventListener';

import { getClientRect } from 'utils/getClientRect';

import { PANELS } from 'constants/components';

import { selectMobile } from 'store/selectors';
import { selectAnyPopupOpened } from 'store/page/selectors';

export const useFloatingInfoPanel = ({ ref, defaultPanel }) => {
  const [panelShown, showPanel] = useState(false);
  const [scrollUp, setScrollUp] = useState(false);
  const [panel, setPanel] = useState(defaultPanel || PANELS.pageNavigation);

  const notMobile = !useSelector(selectMobile);
  const popupOpened = useSelector(selectAnyPopupOpened);

  const debounced = {
    showPanel: useDebounce(panelShown, { wait: 250 }),
    currentPanel: useDebounce(panel, { wait: 400 }),
  };

  useScroll(({ scrolling, direction: [, y] }) => {
    if (scrolling) {
      if (!defaultPanel && notMobile) {
        setPanel(y > 0 ? PANELS.pageNavigation : PANELS.globalNavigation);
      }

      setScrollUp(y < 0);
    }
  }, {
    domTarget: typeof window === 'undefined' ? null : window,
  });

  const handleScroll = () => {
    showPanel(getClientRect(ref)?.top < -100);
  };

  if (typeof window !== 'undefined') {
    useEventListener('scroll', handleScroll, window);
  }

  useEffect(() => showPanel(!popupOpened && getClientRect(ref)?.top < -100), [popupOpened, ref]);

  return {
    renderPanel: debounced.showPanel,
    panel: debounced.currentPanel,
    scrollUp,
  };
};
