import classNames from 'classnames/bind';

import { ButtonOrLink } from 'components';
import Arrow from '../../../../images/arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SubmitButton = ({ query, onClick, className }) => (
  <ButtonOrLink
    className={cx('component', className)}
    isLink
    routerLink
    href={`/searchResults?search=${query}`}
    onClick={onClick}
    animation={{ animated: false }}
  >
    <Arrow />
  </ButtonOrLink>
);
