import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Tag } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Tags = ({ tags, className }) => {
  if (!tags) return null;

  return (
    <div className={cx('tags', className)}>
      {tags.map((tag) => (
        <Tag
          key={tag.id}
          className={cx('tag')}
          size="large"
          type={tag.type}
          text={tag.text}
        />
      ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
};
