import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { RouterLink } from 'components';
import { CardButton, CardInfo, CardTags, CardTitle } from '../../components';
import { CardCreators, CardImage } from '../components';
import { VerticalArticleCard } from './Article';
import { VerticalLectureCard } from './Lecture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const VerticalCard = ({
  image,
  topRight,
  info,
  tags,
  button,
  href,
  isBottomImage,
  className,
  imageClassName,
  children,
}) => (
  <RouterLink to={href} className={cx('component', isBottomImage && 'reverse', className)}>
    {image && <CardImage image={image} imageClassName={imageClassName} />}

    <div className={cx('container')}>
      <div className={cx('topBar')}>
        {tags}
        {topRight}
      </div>

      {children}

      <div className={cx('bottomBar')}>
        {info}
        {button}
      </div>
    </div>
  </RouterLink>
);

VerticalCard.propTypes = {
  href: PropTypes.string,
  image: PropTypes.string,
  info: PropTypes.node,
  tags: PropTypes.node,
  button: PropTypes.node,
  topRight: PropTypes.node,
  isBottomImage: PropTypes.bool,
};

VerticalCard.Title = CardTitle;
VerticalCard.Tags = CardTags;
VerticalCard.Button = CardButton;
VerticalCard.Info = CardInfo;
VerticalCard.Creators = CardCreators;
VerticalCard.Image = CardImage;

VerticalCard.Article = VerticalArticleCard;
VerticalCard.Lecture = VerticalLectureCard;
