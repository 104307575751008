import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { setPrevUrl } from 'store/page/actions';
import { selectNavigationMenu } from 'store/widgets';

/* Hooks */
import { useOnMount } from 'hooks/useOnMount';

/* Components & Icons */
import { BlackButtonOrLink } from 'components';
import Cross from '../../images/close.svg'

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MenuButtonComponent = ({
  isActive,
  isLink,
  routerLink,
  href,
  text,
  onClick,
  onSetPrevUrl,
}) => {
  const { path } = useRouteMatch();

  useOnMount(() => path !== '/menu' && onSetPrevUrl(path));

  return (
    <BlackButtonOrLink
      isLink={isLink}
      routerLink={routerLink}
      href={href}
      onClick={onClick}
      className={cx('button', { isActive })}
    >
      {isActive ? <Cross /> : text}
    </BlackButtonOrLink>
  );
};

MenuButtonComponent.propTypes = {
  isActive: PropTypes.bool,
  isLink: PropTypes.bool,
  routerLink: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  onSetPrevUrl: PropTypes.func,
  onResetPrevUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  text: selectNavigationMenu(state),
});

const mapDispatchToProps = {
  onSetPrevUrl: setPrevUrl,
};

export const MenuButton = connect(mapStateToProps, mapDispatchToProps)(MenuButtonComponent);
