import { BREAKPOINT_1004, BREAKPOINT_768, BREAKPOINT_1280, BREAKPOINT_1680, BREAKPOINT_SET } from './constants';

const initialState = {
  breakpoint: BREAKPOINT_1004,
  deviceType: 'tablet',
};

/**
 * Should only be called when breakpoint actually changes,
 * all that logic is up to AdaptivityProvider implementation
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case BREAKPOINT_SET: {
      const { value } = action;
      const setDeviceType = () => {
        if (value < BREAKPOINT_768) {
          return 'mobile';
        }

        if (value < BREAKPOINT_1280 && value >= BREAKPOINT_768) {
          return 'tablet';
        }

        if (value < BREAKPOINT_1680 && value >= BREAKPOINT_1280) {
          return 'desktop';
        }

        return 'largeDesktop';
      };
      return {
        breakpoint: value,
        deviceType: setDeviceType(),
      };
    }
    default:
      return state;
  }
}
