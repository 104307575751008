import { createReducer } from '@reduxjs/toolkit';

import { setCurrentWebinarSeries, setCurrentCourseSeries } from 'store/series';

export const series = createReducer({}, {
  [setCurrentWebinarSeries.type]: (state, action) => {
    state.webinarSeries = action.payload;
  },
  [setCurrentCourseSeries.type]: (state, action) => {
    state.courseSeries = action.payload;
  },
});
