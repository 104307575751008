import classNames from 'classnames/bind';

/* Utils */
import { useFloatingButton } from './useFloatingButton';

/* Components */
import { BlackButtonOrLink } from 'components';
import Arrow from '../../images/arrow_up.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FloatingUpButton = () => {
  const { onClick } = useFloatingButton();

  return (
    <BlackButtonOrLink className={cx('button')} isRound onClick={onClick} reversed>
      <Arrow className={cx('icon')} />
    </BlackButtonOrLink>
  );
};
