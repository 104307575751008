import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { passAsIs } from 'utils/fn';
import { allowParamOmit } from 'hocs/allowParamsOmit';

function withFinalField({ type } = { }) {
  return (WrappedInput) => {
    class FFInput extends React.PureComponent {
      constructor(props) {
        super(props);
        const { meta: { error, initial } } = props;
        this.initialError = initial && error;
      }

      handleFocus = (v, e) => {
        const { onFocus, input } = this.props;
        input.onFocus(e);
        onFocus?.(v, e);
      }

      handleBlur = (v, e) => {
        const { onBlur, input } = this.props;
        input.onBlur(e);
        onBlur?.(v, e);
      }

      render() {
        const {
          input: { checked, name, value: originalValue, onChange },
          meta: { touched, error, submitError, dirtySinceLastSubmit, submitFailed, active, valid },
          error: forcedError,
          valueProxy,
          onChangeProxy,
          errorProxy,
          ...inheritedProps
        } = this.props;
        const value = type === 'checkbox' || type === 'radio' ? checked : originalValue;

        const handleChange = typeof onChangeProxy === 'function' ? (val, event) => onChange(onChangeProxy(val, event), event) : onChange;
        const val = typeof valueProxy === 'function' ? valueProxy(value) : value;
        const err = typeof errorProxy === 'function' ? errorProxy(error) : error;

        /**
         * Error should not be shown initially on an empty field
         */
        const shouldShowError = touched || submitFailed || this.initialError;
        return (
          <WrappedInput
            {...inheritedProps}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={handleChange}
            value={(typeof val !== 'undefined' && val !== null) ? val : undefined}
            name={name}
            /** There are three sources of errors - manually passed error, validation error and submission error */
            error={shouldShowError && (forcedError || err || !dirtySinceLastSubmit && submitError) || null}
            status={shouldShowError && (forcedError || err || !dirtySinceLastSubmit && submitError) && 'fail' || null}
            active={active}
            valid={valid}
          />
        );
      }
    }

    FFInput.propTypes = {
      valueProxy: PropTypes.func, /** to override value before passing it to underlying component */
      onChangeProxy: PropTypes.func, /** to override value before passing it back to redux forms */
      errorProxy: PropTypes.func,

      /** final-form props */
      input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.any,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
      }),
      meta: PropTypes.shape({
        active: PropTypes.bool,
        touched: PropTypes.bool,
        submitFailed: PropTypes.bool,
        initial: PropTypes.any,
        error: PropTypes.string,
        submitError: PropTypes.string,
        dirtySinceLastSubmit: PropTypes.bool,
      }),

      // inheritedProps
      type: PropTypes.string,
      error: PropTypes.string,
    };

    /**
     * Override component name by prepending `FF~`
     * to make it look nice, for example: `FFTextInput`
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
      FFInput.displayName = `FF${WrappedComponentName}`;
    }

    const FFInputField = ({ value, ...props }) => (
      <Field
        component={FFInput}
        parse={passAsIs}
        format={passAsIs}
        allowNull
        type={type}
        {...{ [type === 'checkbox' ? 'checked' : 'value']: value }}
        {...props}
      />
    );

    /**
     * FFInputField has same props as Wrapped component
     */
    FFInputField.propTypes = {
      /** prop used for internal representation of checkbox */
      value: PropTypes.any,
      name: PropTypes.string.isRequired,
      /** inherited props */
    };

    /** furthermore, wrap all FF payFormFields with NestedFFContext, to support nesting */
    // return withFinalFormContext(FFInputField);
    return FFInputField;
  };
}

export default allowParamOmit(withFinalField);
