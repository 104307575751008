import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

import { SecondaryButtonOrLink } from 'components';

export const ReadMoreButton = ({ text, onClick, className }) => (
  <SecondaryButtonOrLink
    className={cx('component', className)}
    size="large"
    arrow={{
      render: true,
      mode: 'normal',
      color: 'white'
    }}
    onClick={onClick}
  >
    {text}
  </SecondaryButtonOrLink>
);

ReadMoreButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};
