import { createReducer } from '@reduxjs/toolkit';

import { setCurrentArticle } from 'store/article/actions';

export const article = createReducer({}, {
  [setCurrentArticle.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
