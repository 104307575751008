export const validate = (type, value) => {
  if (!value?.length) return false;

  if (type === 'text') {
    const textReg = /^[A-Za-zА-Яа-яЁё0-9-_\s]*$/;
    return !textReg.test(value);
  }

  if (type === 'phone') {
    const phoneReg = /^\+7[ -]9\d{2}[ -]\d{3}[ -]\d{2}[ -]\d{2}$/;
    return !phoneReg.test(value);
  }

  if (type === 'email') {
    const emailReg = /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[a-z]{2,6}$/;
    return !emailReg.test(value);
  }

  if (type === 'code') {
    const codeReg = /^\d[ ]\d[ ]\d[ ]\d[ ]$/;
    return !codeReg.test(value);
  }
};
