import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectSendEmailsInput } from 'store/widgets';
import { setResultStatus } from 'store/page/actions';
import { selectChangedEmail } from 'store/user/selectors';

/* Hooks */
import { useMailingInput } from './useMailingInput';
import { useProfileUpdate } from 'hooks/useMakeRequest';

/* Constants */
import { RESULT_STATUS } from 'constants/constants';

/* Components */
import { Field } from 'react-final-form';
import { ButtonOrLink } from 'components';
import Arrow from '../../images/arrow_diagonal.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TYPES = {
  telegram: 'telegram',
  email: 'email',
};

const SendEmailsInputComponent = ({
  type,
  changedEmail,
  texts,
  withBorder,
  onSetResultStatus,
  className,
  inputClassName,
}) => {
  const {
    isActive,
    displayButton,
    handleFocus,
    handleBlur,
    handleChange,
  } = useMailingInput(type);
  const updateProfile = useProfileUpdate();

  const handleSuccess = () => onSetResultStatus(RESULT_STATUS.profileUpdated);

  return (
    <Field
      id={type}
      name={type}
      type="text"
      render={({ input, meta: { modified } }) => {
        const prefix = texts?.[type]?.prefix;

        const onChange = (event) => {
          handleChange(event);
          if (prefix && event.target.value.indexOf(prefix) !== 0) {
            input.onChange(`${prefix}${event.target.value.replaceAll(prefix, '')}`);
            return;
          }

          input.onChange(event.target.value);
        };

        const onFocus = () => {
          input.onFocus();
          handleFocus();
        };

        const onBlur = (event) => {
          if (event.target.value === prefix) {
            input.onChange('');
          }

          input.onBlur(event);
          handleBlur(event, modified);
        };

        const handleClick = () => {
          if (type === TYPES.email) {
            updateProfile({ email: changedEmail }, handleSuccess);
          }
        };

        return (
          <div className={cx('component', { isActive, withBorder }, className)}>
            <input
              className={cx('input', inputClassName)}
              placeholder={texts?.[type]?.placeholder}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={input.value}
            />

            {displayButton && (
            <ButtonOrLink className={cx('button')} onClick={handleClick}>
              <Arrow />
            </ButtonOrLink>
            )}
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  texts: selectSendEmailsInput(state),
  changedEmail: selectChangedEmail(state),
});

const mapDispatchToProps = {
  onSetResultStatus: setResultStatus,
};

SendEmailsInputComponent.propTypes = {
  type: PropTypes.string,
  changedEmail: PropTypes.string,
  texts: PropTypes.object,
  withBorder: PropTypes.bool,
  onSetResultStatus: PropTypes.func,
};

export const SendEmailsInput = connect(mapStateToProps, mapDispatchToProps)(SendEmailsInputComponent);

