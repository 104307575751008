import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Store */
import { selectArticles, selectArticlesAmount } from 'store/content/selectors';
import {
  loadMoreArticles,
  selectLoadedArticles,
  selectArticlesLoadedFlag,
} from 'store/pagination';

/* Components */
import { ArticlesRow, ShowMoreButton } from 'components';

const ArticlesComponent = ({ fullyLoaded, articles, loaded, amount, onLoadMore }) => {
  if (!articles || !articles?.length) return null;

  return (
    <>
      <ArticlesRow articles={loaded?.length > 0 ? [...articles, ...loaded] : articles} shouldRedirect={false} />

      {!fullyLoaded && useMemo(() => (
        <ShowMoreButton total={amount} onClick={onLoadMore} />
      ), [onLoadMore])}
    </>
  );
};

const mapStateToProps = (state) => ({
  articles: selectArticles(state),
  loaded: selectLoadedArticles(state),
  amount: selectArticlesAmount(state),
  fullyLoaded: selectArticlesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreArticles,
};

ArticlesComponent.propTypes = {
  articles: PropTypes.array,
  loaded: PropTypes.array,
  amount: PropTypes.number,
  onLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
};

export const ArticlesBlock = connect(mapStateToProps, mapDispatchToProps)(ArticlesComponent);
