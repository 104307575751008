import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

/* Components */
import { NameInput, EmailInput, CompanyInput } from 'components/Inputs';
import { Text } from 'components';
import { AuthForm } from './Form';
import { ChangeTab, AuthPhoneInput } from './components';

/* Store */
import { selectLoginText, selectRequireCompany } from 'store/widgets';
import { selectPhoneInputErrorType } from 'store/auth/selectors';
import { profileConfirm, profileCreate, setCompany, setEmail, setName } from 'store/auth/actions';

/* Constants */
import { LOGIN_AUTH_TAB, POPUP_STEP } from 'constants/constants';
import { ALREADY_REGISTERED } from 'constants/authorizationErrors';

import styles from './styles.pcss';
import { registrationForm } from 'utils/formValidators';

const cx = classNames.bind(styles);

const RegistrationFormComponent = ({
  content,
  setNextStep,
  requireCompany,
  displayLogin,
  loginText,
  changeTab,
  onCreateProfile,
  onConfirmProfile,
  onSaveCompany,
  onSaveName,
  onSaveEmail,
}) => {
  const [step, setStep] = useState(POPUP_STEP.initial);

  const { terms } = content;

  const handleValidateForm = (values) => registrationForm(values, step, requireCompany);

  const handleSubmit = () => setStep(POPUP_STEP.phone);

  const handleRegistrationConfirm = (code) => onConfirmProfile(code);

  return (
    <div>
      <AuthForm
        className={cx('phoneForm')}
        validateForm={handleValidateForm}
        setNextStep={setNextStep}
        onSubmit={handleSubmit}
        {...content}
        button={step === POPUP_STEP.initial && content?.button}
      >
        {step === POPUP_STEP.initial && (
          <>
            <NameInput className={cx('field')} onBlur={onSaveName} />

            <CompanyInput className={cx('field')} onBlur={onSaveCompany} />

            <EmailInput className={cx('field', 'email')} onBlur={onSaveEmail} />
          </>
        )}

        {step === POPUP_STEP.phone && (
          <>
            <AuthPhoneInput className={cx('field')} onConfirm={handleRegistrationConfirm} onRequestCode={onCreateProfile} />

            {displayLogin && (
              <ChangeTab className={cx('register')} onClick={() => changeTab(LOGIN_AUTH_TAB)}>
                {loginText}
              </ChangeTab>
            )}
          </>
        )}
      </AuthForm>

      {step === POPUP_STEP.initial && (
        <Text className={cx('regTerms')} isCentered>
          {terms}
        </Text>
      )}
    </div>
  );
};

RegistrationFormComponent.propTypes = {
  content: PropTypes.shape({
    terms: PropTypes.string,
  }),
  setNextStep: PropTypes.func,
  requireCompany: PropTypes.bool,
  displayLogin: PropTypes.bool,
  loginText: PropTypes.string,
  changeTab: PropTypes.func,
  onCreateProfile: PropTypes.func,
  onConfirmProfile: PropTypes.func,
  onSaveName: PropTypes.func,
  onSaveCompany: PropTypes.func,
  onSaveEmail: PropTypes.func,
};

const mapStateToProps = (state) => ({
  requireCompany: selectRequireCompany(state),
  displayLogin: selectPhoneInputErrorType(state) === ALREADY_REGISTERED,
  loginText: selectLoginText(state),
});

const mapDispatchToProps = {
  onCreateProfile: profileCreate,
  onConfirmProfile: profileConfirm,
  onSaveCompany: setCompany,
  onSaveName: setName,
  onSaveEmail: setEmail,
};

export const RegistrationForm = connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent);
