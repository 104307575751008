import { MASKS } from 'constants/input';

export const handleMask = (val, type, prefix) => {
  const { regEx } = MASKS[type];
  let newMask = MASKS[type]?.mask;
  const maskSpecialCharLength = newMask.replace(/[^_]/g, '').length;

  const filteredValue = val.replace(regEx, '').split('');
  const reverseValue = filteredValue.reverse().slice(0, maskSpecialCharLength);
  const resultValue = reverseValue.reverse();

  let lastChar;
  resultValue.forEach((char) => {
    if (newMask.indexOf('_') >= 0) {
      newMask = newMask.replace('_', char);
      lastChar = char;
    }
  });

  const newValue = newMask.slice(0, newMask.lastIndexOf(lastChar) + 1);

  if (prefix && newValue.indexOf(prefix) !== 0) {
    return `${prefix}${newValue}`;
  }

  return newValue;
};
