import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { SIZES } from './constants';
import { cxBool } from 'utils/format-string';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Heading = ({
  children,
  size,
  level,
  isPrimary,
  isAccent,
  isCentered,
  isBold,
  tagName,
  className,
}) => {
  const Tag = tagName || `h${level}`;

  return (
    <Tag
      className={cx('component', className, SIZES[size], { [`h${[level]}`]: level }, cxBool({
        isPrimary,
        isAccent,
        isCentered,
        isBold,
      }))}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
};

Heading.defaultProps = {
  size: 'regular',
  level: 3,
  isPrimary: true,
};

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  size: PropTypes.string,
  isPrimary: PropTypes.bool,
  isBold: PropTypes.bool,
  isAccent: PropTypes.bool,
  isCentered: PropTypes.bool,
};
