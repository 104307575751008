import { connect } from 'react-redux';

import { selectDesktop } from 'store/selectors';
import { selectDefaultPopupImage, selectEventSubScreens } from 'store/widgets';
import {
  selectActivePopupStep,
  selectEventSubPopupOpened,
  selectEventType,
  selectResultStatus,
} from 'store/page/selectors';

import { POPUP_STEP } from 'constants/constants';

import { Popup, Result, Step, Wizard } from 'components';

import { useEventSubPopup } from 'hooks/useEventSubPopup';

const EventSubscriptionPopupComponent = ({
  defaultImage,
  eventSubScreens,
  eventType,
  activeStep,
  resultStatus,
  isOpened,
  isDesktop,
}) => {
  const { close } = useEventSubPopup();

  return (
    <Wizard
      activeStep={activeStep}
      onClose={close}
      opened={isOpened}
      image={<Popup.Image image={defaultImage} />}
      closeButton={(
        <Popup.CloseButton
          mode={isDesktop ? 'secondary' : 'black'}
          onClose={close}
        />
      )}
      withNavBar
    >
      <Step name={POPUP_STEP.ready}>
        <Result
          onClose={close}
          {...eventSubScreens?.[eventType]?.[resultStatus]}
        />
      </Step>
    </Wizard>
  );
};

const mapStateToProps = (state) => ({
  eventSubScreens: selectEventSubScreens(state),
  defaultImage: selectDefaultPopupImage(state),
  isOpened: selectEventSubPopupOpened(state),
  isDesktop: selectDesktop(state),
  eventType: selectEventType(state),
  activeStep: selectActivePopupStep(state),
  resultStatus: selectResultStatus(state),
});

export const EventSubscriptionPopup = connect(mapStateToProps)(EventSubscriptionPopupComponent);
