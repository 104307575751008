import classNames from 'classnames/bind';

import { Label } from '../../components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const withLabel = (WrappedComponent) => ({
  label,
  value,
  error,
  active,
  withBorder = true,
  className,
  ...restProps
}) => (
  <div
    className={cx(
      'component',
      !!label && 'withLabel',
      (!!value || active) && 'withValue',
      { error, active, withBorder },
      className,
    )}
  >
    {(!!value || active) && !!label && <Label label={label} />}
    <WrappedComponent {...restProps} placeholder={!active ? label : ''} value={value} />
  </div>
);
