import { useSelector } from 'react-redux';
import { selectIsAuthorized } from 'store/auth/selectors';
import { usePopup } from 'hooks/usePopup';

export const useRestrictedAccess = () => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const { openAuthPopup } = usePopup();

  const handleRestrictedAccess = (fn, elseFn) => {
    if (isAuthorized) {
      fn();
    } else {
      elseFn?.();
      openAuthPopup();
    }
  }

  return { handleRestrictedAccess };
};
