import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text, Select } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const DefaultOption = ({ onClick, defaultOption, className }) => (
  <Select.Option
    className={cx('component', className)}
    onClick={onClick}
    option={defaultOption}
  >
    <Text className={cx('text')}>
      {defaultOption?.name}
    </Text>
  </Select.Option>
);

DefaultOption.propTypes = {
  defaultOption: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onClick: PropTypes.func,
};
