import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Description = ({ description }) => {
  if (!description) return null;

  return (
    <Text className={cx('description')}>
      {description}
    </Text>
  );
};
