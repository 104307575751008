import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { FForm } from 'components/FForm';
import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ChangeData = ({ initialValues, title, handleValidate, className, children }) => (
  <FForm className={cx('component')} validateForm={handleValidate} initialValues={initialValues}>
    <Text className={cx('title')}>
      {title}
    </Text>

    <div className={cx('inputContainer', className)}>
      {children}
    </div>
  </FForm>
);

ChangeData.propTypes = {
  title: PropTypes.string,
  handleValidate: PropTypes.func,
  initialValues: PropTypes.object,
};
