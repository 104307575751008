import { createAction } from '@reduxjs/toolkit';

/* Utils */
import { deleteCookie, getCookie } from 'utils/cookie';
import { unFormatPhone } from 'utils/format-string';

/* Constants */
import {
  ALREADY_REGISTERED,
  NOT_REGISTERED,
  PHONE_CONFIRMATION_ERRORS,
  MOBILE_ID,
} from 'constants/authorizationErrors';
import { POPUP_STEP, RESULT_STATUS } from 'constants/constants';

/* Store */
import { getUser } from 'store/user/actions';
import { setUserData, resetUserData } from 'store/user';
import { setResultStatus, changePopupStep } from 'store/page';

/* Api */
import { mapError, mapProfileData } from 'api/dataMapper';
import { confirmMobileIdCode, startMobileIdAuth } from 'api/requests/auth';
import { confirmProfile, createProfile, requestLogin, requestLoginConfirm, requestLogout } from 'api/requests';
import { filter } from 'utils/fn';


export const setFormData = createAction('SET_FORM_DATA');
export const setAuthorized = createAction('SET_AUTH');
export const setLoginInProgress = createAction('SET_LOGIN_IN_PROGRESS');
export const setMobileIdAuth = createAction('SET_MOBILE_ID_AUTH');
export const setError = createAction('SET_AUTH_ERROR', ({ error, type }) => ({ payload: { [type]: mapError(error) } }));
export const resetError = createAction('RESET_ERROR');
export const resetFormData = createAction('RESET_FORM_DATA');

export const setName = (e) => setFormData({ name: e.target.value });
export const setEmail = (e) => setFormData({ email: e.target.value });
export const setPhone = (e) => setFormData({ phone: e.target.value });
export const setCompany = (company = '') => setFormData({ company });

export const resetLoginProgress = () => (dispatch, getState) => {
  if (getState()?.auth?.loginInProgress) {
    dispatch(setLoginInProgress(false));
  }
};

export const resetErrors = () => (dispatch, getState) => {
  const errors = filter(getState()?.auth?.error, (item) => item);

  if (Object.keys(errors).length > 0) {
    dispatch(resetError());
  }
};
export const setPhoneInputError = (error) => setError({ error, type: 'phoneInput' });
export const setCodeInputError = (error) => setError({ error, type: 'codeInput' });
export const setMobileIdError = (error) => setError({ error, type: 'mobileId' });

let confirmationToken = null;

export const confirmPhone = (args, request, successStatus) => (dispatch) => {
  const handleUnexpectedError = (expectedErrors, currentError) => {
    if (!expectedErrors[currentError]) {
      dispatch(setResultStatus(RESULT_STATUS.error));
      changePopupStep(POPUP_STEP.ready);
    }
  };

  return request(args)
    .then((res) => {
      dispatch(setUserData({ userId: res.userId }));
      dispatch(setAuthorized(!!getCookie('token')));
      successStatus && dispatch(setResultStatus(successStatus));
      dispatch(getUser());
    })
    .catch(({ response: { data } }) => {
      dispatch(setCodeInputError(data));

      handleUnexpectedError(PHONE_CONFIRMATION_ERRORS, data.error);
    });
};

export const profileCreate = () => (dispatch, getState) => {
  const phoneNumber = unFormatPhone(getState().auth.form.phone);

  const handleUnexpectedError = (expectedErrors, currentError) => {
    if (getCookie('token')) {
      deleteCookie('token');
    }

    if (currentError === ALREADY_REGISTERED) {
      dispatch(setResultStatus(RESULT_STATUS.alreadyRegistered));
    } else if (!expectedErrors[currentError]) {
      dispatch(setResultStatus(RESULT_STATUS.error));

      changePopupStep(POPUP_STEP.ready);
    }
  };

  return createProfile(phoneNumber)
    .then((res) => {
      confirmationToken = res.confirmationToken;
    })
    .catch(({ response: { data } }) => {
      dispatch(setPhoneInputError(data));

      handleUnexpectedError(PHONE_CONFIRMATION_ERRORS, data.error);
    });
};

export const profileConfirm = (code) => (dispatch, getState) => dispatch(confirmPhone({
  code,
  confirmationToken,
  formData: mapProfileData(getState().auth.form),
}, confirmProfile, RESULT_STATUS.registrationSuccess));

export const loginRequest = () => (dispatch, getState) => {
  const phoneNumber = unFormatPhone(getState().auth.form.phone);

  const handleUnexpectedError = (expectedErrors, currentError) => {
    if (currentError === NOT_REGISTERED) {
      dispatch(setResultStatus(RESULT_STATUS.notRegistered));
      return;
    }

    if (!expectedErrors[currentError]) {
      dispatch(setResultStatus(RESULT_STATUS.error));
    }

    changePopupStep(POPUP_STEP.ready);
  };

  return requestLogin(phoneNumber)
    .then((res) => {
      confirmationToken = res.confirmationToken;
    })
    .catch(({ response: { data } }) => {
      dispatch(setPhoneInputError(data));

      handleUnexpectedError(PHONE_CONFIRMATION_ERRORS, data.error);
    });
};

export const loginConfirmRequest = (code) => (dispatch) => dispatch(confirmPhone({
  code,
  confirmationToken,
}, requestLoginConfirm, RESULT_STATUS.loginSuccess));

export const logout = () => (dispatch) => requestLogout()
  .then(() => {
    deleteCookie('token');
    deleteCookie('refresh');
    dispatch(setAuthorized(false));
    dispatch(resetUserData());
  });

export const mobileIdLogin = () => (dispatch, getState) => {
  const phoneNumber = unFormatPhone(getState().auth.form.phone);

  dispatch(setLoginInProgress(true));
  dispatch(setMobileIdAuth(true));

  return startMobileIdAuth({ phoneNumber })
    .then((res) => {
      dispatch(resetLoginProgress());
      dispatch(setUserData({ userId: res.userId }));
      dispatch(setAuthorized(!!getCookie('token')));
      dispatch(setResultStatus(RESULT_STATUS.loginSuccess));
      dispatch(getUser());
    })
    .catch(({ response: { data } }) => {
      if (MOBILE_ID[data.error]) {
        dispatch(setMobileIdError(data));
        return;
      }

      dispatch(setResultStatus(RESULT_STATUS.error));
    })
    .finally(() => {
      dispatch(setMobileIdAuth(false));
    });
};

export const mobileIdCodeConfirm = (code) => (dispatch) => confirmMobileIdCode({ code })
  .catch(({ response: { data } }) => {
    dispatch(setCodeInputError(data));
  });