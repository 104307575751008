import { setCurrentArticle } from 'store/article';
import { setCurrentCourse } from 'store/course';
import { setCurrentLecture } from 'store/lecture';
import { setCurrentWebinar } from 'store/webinar';
import { setCurrentCourseSeries, setCurrentWebinarSeries } from 'store/series';
import {
  setArticles,
  setCourses,
  setCoursesSeries,
  setLectures,
  setWebinarsSeries,
  setFutureConferences,
  setPastConferences,
  setFutureWebinars,
  setPastWebinars,
} from 'store/content';

export const storeActions = {
  article: setCurrentArticle,
  course: setCurrentCourse,
  lecture: setCurrentLecture,
  webinar: setCurrentWebinar,
  articles: setArticles,
  courses: setCourses,
  lectures: setLectures,
  webinarSeries: setCurrentWebinarSeries,
  courseSeries: setCurrentCourseSeries,
  webinarSeriesList: setWebinarsSeries,
  courseSeriesList: setCoursesSeries,
  futureWebinars: setFutureWebinars,
  pastWebinars: setPastWebinars,
  futureConferences: setFutureConferences,
  pastConferences: setPastConferences,
};
