import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';

import { selectIsAuthorized } from 'store/auth/selectors';

export const useIFrame = () => {
  const notAuthorized = !useSelector(selectIsAuthorized);
  const { openAuthPopup } = usePopup();

  const handleIFrame = () => {
    if (notAuthorized) {
      openAuthPopup();
    }
  };

  return { handleIFrame, notAuthorized };
};
