import { createAction } from '@reduxjs/toolkit';

import { selectIsAuthorized } from 'store/auth/selectors';
import { filterBanners } from 'api/dataMapper';

export const setBanners = createAction('SET_BANNERS');

export const filterBannersByAccessLevel = (banners) => (dispatch, getState) => {
  const isAuthorized = selectIsAuthorized(getState());
  const filteredBanners = filterBanners(banners, isAuthorized);

  dispatch(setBanners(filteredBanners));
};
