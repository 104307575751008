import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSpring, animated } from 'react-spring';
import { useHover } from 'react-use-gesture';

/* Components */
import { RouterLink } from 'components';
import { CardCreators } from './components';
import {
  CardButton,
  CardDescription,
  CardTags,
  CardTitle,
  CardInfo,
} from '../components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const LargeCard = ({
  image,
  tag,
  topRight,
  info,
  button,
  className,
  title,
  description,
  href,
}) => {
  const [{ opacity }, set] = useSpring(() => ({ opacity: 0.4, config: { duration: 150 } }));

  const handleHover = useHover(({ hovering }) => set({ opacity: hovering ? 0.7 : 0.4 }));

  return (
      <animated.div
        className={cx('component', className)}
        style={{ backgroundImage: `url(${image})` }}
        {...handleHover()}
      >
        <RouterLink className={cx('link')} to={href}>
          <animated.div className={cx('wrapper')} style={{ opacity }} />

          <div className={cx('contentWrapper')}>
            <div className={cx('topBar')}>
              {tag}
              {topRight}
            </div>

            <div className={cx('content')}>
              {title}
              {description}
            </div>

            <div className={cx('bottomBar')}>
              {info}
              {button}
            </div>
          </div>
        </RouterLink>
      </animated.div>
  );
};

LargeCard.propTypes = {
  href: PropTypes.string,
  image: PropTypes.string,
  topRight: PropTypes.node,
  info: PropTypes.node,
  button: PropTypes.node,
  tag: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
};

LargeCard.Title = CardTitle;
LargeCard.Tags = CardTags;
LargeCard.Description = CardDescription;
LargeCard.Button = CardButton;
LargeCard.Creators = CardCreators;
LargeCard.Info = CardInfo;
