import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDocumentsLabel } from 'store/widgets';

/* Components */
import { DocumentDownload, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DocumentsComponent = ({ documents, label, className }) => {
  if (!documents) return null;

  return (
    <div className={cx('container', className)}>
      <Text className={cx('label')}>
        {label}
      </Text>
      <div className={cx('wrapper')}>
        {documents.map((doc) => (
          <DocumentDownload key={doc.id} className={cx('document')} {...doc} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  label: selectDocumentsLabel(state),
});

DocumentsComponent.propTypes = {
  label: PropTypes.string,
  documents: PropTypes.array,
};

export const Documents = connect(mapStateToProps)(DocumentsComponent);
