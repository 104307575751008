import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

/* Components & Icons */
import { PrimaryButtonOrLink } from 'components';
import Arrow from '../../../../images/arrow.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SubmitButton = ({ disabled, onClick, children, className }) => (
  <PrimaryButtonOrLink
    className={cx('component', { disabled }, className)}
    size="small"
    disabled={disabled}
    onClick={onClick}
    animation={{ animated: false }}
  >
    <div className={cx('content')}>
      {children || <Arrow />}
    </div>
  </PrimaryButtonOrLink>
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

