import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { setName, setTown } from 'store/user';
import { selectEmailConfirmed, selectUserEmail } from 'store/user/selectors';

/* Hooks */
import { usePopup } from 'hooks/usePopup';

/* Components */
import {NameInput, EmailInput, TownInput, PhoneInput, EmailConfirmButton} from 'components/Inputs';
import { Title } from '../Title';

/* Icons */
import Success from 'images/success.svg';
import Alert from 'images/alert.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AboutUserComponent = ({ title, email, emailConfirmed, onSetName, onSetTown, className }) => {
  const { openChangeEmailPopup, openChangePhonePopup } = usePopup();

  return (
    <div className={className}>
      <Title>
        {title}
      </Title>

      <div className={cx('form')}>
        <NameInput className={cx('field')} onBlur={onSetName} />

        <TownInput className={cx('field')} onBlur={onSetTown} />

        <EmailInput
          className={cx('field', 'email')}
          disabled
          editButton={<PhoneInput.Edit className={cx('editButton')} onClick={openChangeEmailPopup} />}
          confirmButton={email && !emailConfirmed && <EmailConfirmButton className={cx('confirmButton')} />}
        >
          {emailConfirmed ? <Success className={cx('status')} /> : <Alert className={cx('status', 'alert')} />}
        </EmailInput>

        <PhoneInput
          className={cx('field')}
          disabled
          editButton={<PhoneInput.Edit className={cx('editButton')} onClick={openChangePhonePopup} />}
        />
      </div>
    </div>
  );
};

AboutUserComponent.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string,
  onSetName: PropTypes.func,
  onSetTown: PropTypes.func,
  emailConfirmed: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  emailConfirmed: selectEmailConfirmed(state),
  email: selectUserEmail(state),
});

const mapDispatchToProps = {
  onSetName: setName,
  onSetTown: setTown,
};

export const AboutUser = connect(mapStateToProps, mapDispatchToProps)(AboutUserComponent);
