import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { cxBool } from 'utils/format-string';
import { ANIMATION_ORIENTATION } from 'constants/components';

/* Components */
import { EmptyButton, Link } from 'components';

/* Icons */
import Arrow from '../../images/arrow.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ButtonOrLink = ({
  children,
  className,
  size,
  mode,
  theme,
  isRound,
  isAdaptive,
  disabled,
  animation: {
    animated,
    reversed,
    orientation,
  },
  isLink,
  arrow,
  ...props
}) => {
  const Component = isLink ? Link : EmptyButton;

  return (
    <Component
      className={cx('component', cxBool({ isRound, isAdaptive }), { disabled }, size, mode, theme, className)}
      disabled={disabled}
      {...props}
    >
      <div className={cx('content')}>
        <div className={cx('children', { animated, reversed }, orientation)}>
          {children}
        </div>
        {arrow?.render && <Arrow className={cx('arrow', { animated }, arrow?.mode, arrow.color)} />}
      </div>
    </Component>
  );
};

ButtonOrLink.defaultProps = {
  animation: {
    animated: true,
    orientation: ANIMATION_ORIENTATION.vertical,
  },
  arrow: {
    color: 'black',
  },
};

ButtonOrLink.propTypes = {
  size: PropTypes.string,
  mode: PropTypes.string,
  theme: PropTypes.string,
  isRound: PropTypes.bool,
  isAdaptive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  arrow: PropTypes.shape({
    render: PropTypes.bool,
    mode: PropTypes.oneOf(['normal', 'reversed']),
    color: PropTypes.oneOf(['white', 'black']),
  }),
  animation: PropTypes.shape({
    animated: PropTypes.bool,
    reversed: PropTypes.bool,
    orientation: PropTypes.oneOf(Object.keys(ANIMATION_ORIENTATION)),
  }),
};

export const PrimaryButtonOrLink = ({ children, ...props }) => (
  <ButtonOrLink mode="primary" {...props}>
    {children}
  </ButtonOrLink>
);

export const SecondaryButtonOrLink = ({ theme, arrow, children, ...props }) => (
  <ButtonOrLink mode="secondary" theme={theme} arrow={{ ...arrow, color: 'white' }} {...props}>
    {children}
  </ButtonOrLink>
);

SecondaryButtonOrLink.defaultProps = {
  theme: 'dark',
};

SecondaryButtonOrLink.propTypes = {
  theme: PropTypes.string,
};

export const BlackButtonOrLink = ({ children, ...props }) => (
  <ButtonOrLink mode="black" {...props}>
    {children}
  </ButtonOrLink>
);

export const SocialButton = ({ disabled, className, children, ...props }) => (
  <ButtonOrLink className={cx('social', { disabled }, className)} {...props}>
    {children}
  </ButtonOrLink>
);

export default ButtonOrLink;
