import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { Heading } from 'components';

import styles from './styles.pcss';
const cx = classnames.bind(styles);

const WORD_DURATION = 2.3;
const LETTER_DURATION = 0.03;

const useAnimationSteps = (length, interval) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!length) return;

    let timeoutId = setTimeout(() => {
      setStep(step >= length - 1 ? 0 : step + 1);
    }, interval);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [step, length]);

  return step;
}

export const AnimatedTitle = ({ titles, className }) => {
  const step = useAnimationSteps(titles?.length, WORD_DURATION * 1000);

  return (
    <Heading level={1} className={cx('component', className)}>
      {titles?.map((title, index) => (
        <div className={cx('word', index === step && 'active')} key={+index}>
          {title.split('').map((letter, iIndex) => (
            <span
              style={{ animationDelay: `${iIndex * LETTER_DURATION}s` }}
              key={+iIndex}
            >
              {letter}
            </span>
          ))}
        </div>
      ))}
    </Heading>
  );
};
