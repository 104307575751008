import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import endpoints from 'api/endpoints';
import { compose } from 'utils/fn';
import { withDataLoading, Endpoint } from 'hocs';
import { useFloatingButton } from 'components/FloatingUpButton/useFloatingButton';
import { usePopup } from 'hooks/usePopup';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectReadMoreButton } from 'store/widgets';
import {
  selectArticleBanners,
  selectArticleContent,
  selectArticleDescription,
  selectArticleBackgroundImage,
  selectArticleReadTime,
  selectArticleRelatedContent,
  selectArticleRelatedContentSlides,
  selectArticleTags,
  selectArticleTitle,
  selectArticleViews,
} from 'store/article/selectors';

/* Components */
import {
  Wrapper,
  ContentContainer,
  FloatingUpButton,
  Disclaimer,
  MenuButton,
  DeskNavigation as Navigation,
  BannersBlock,
  RelatedContent,
  ArticleCard,
} from 'components';
import { PageHeader } from 'components/PageHeader';
import { FloatingPanel, ReadMoreButton } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ArticlePage = ({
  title,
  description,
  image,
  tags,
  readTime,
  readMore,
  views,
  slides,
  content,
  banners,
  relatedContent,
  isDesktop,
  isAuthorized,
}) => {
  const container = useRef(null);

  const { renderButton } = useFloatingButton(container);
  const { openAuthPopup } = usePopup();

  const handleClick = () => openAuthPopup();

  return (
    <>
      <Wrapper padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
        <Navigation className={cx('navigation')} />
      </Wrapper>

      <PageHeader
        className={cx('header')}
        image={(
          <PageHeader.Image
            className={cx('background')}
            src={image}
            absolute
          />
        )}
        tags={<PageHeader.Tags className={cx('tags')} tags={tags} />}
        title={(
          <PageHeader.Title className={cx('title')}>
            {title}
          </PageHeader.Title>
        )}
        theme={(
          <PageHeader.Theme className={cx('description')}>
            {description}
          </PageHeader.Theme>
        )}
        info={(
          <PageHeader.InfoPanel
            readTime={readTime}
            views={views}
          />
        )}
      />
      <div ref={container}>
        <Wrapper className={cx('content', !isAuthorized && 'hidden')} padding={isDesktop ? 0 : 20} fullWidth={!isDesktop} width={800}>
          <ContentContainer className={cx('parsedContent')}>
            {content}
          </ContentContainer>

          <Disclaimer className={cx('disclaimer')} />

          {!isAuthorized && <ReadMoreButton className={cx('readMore')} text={readMore} onClick={handleClick} />}
        </Wrapper>

        <Wrapper className={cx('banners')} padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
          <BannersBlock banners={banners} />

          {relatedContent && (
            <RelatedContent slides={slides}>
              {relatedContent.map((article) => (
                <ArticleCard className={cx('card')} key={article.id} shouldRedirect {...article} />
              ))}
            </RelatedContent>
          )}
        </Wrapper>

        {isDesktop && renderButton && <FloatingUpButton />}

        <FloatingPanel container={container} />
      </div>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  title: selectArticleTitle(state),
  image: selectArticleBackgroundImage(state),
  tags: selectArticleTags(state),
  description: selectArticleDescription(state),
  readTime: selectArticleReadTime(state),
  views: selectArticleViews(state),
  content: selectArticleContent(state),
  readMore: selectReadMoreButton(state),
  banners: selectArticleBanners(state),
  relatedContent: selectArticleRelatedContent(state),
  slides: selectArticleRelatedContentSlides(state),
});

ArticlePage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  readTime: PropTypes.string,
  readMore: PropTypes.string,
  views: PropTypes.number,
  slides: PropTypes.number,
  tags: PropTypes.array,
  banners: PropTypes.array,
  relatedContent: PropTypes.array,
  isDesktop: PropTypes.bool,
  isAuthorized: PropTypes.bool,
};

export const Article = compose(
  withDataLoading(({ alias }) => ({
    article: new Endpoint(`${endpoints.requestArticle}/?alias=${alias}`),
  })),
  connect(mapStateToProps, null),
)(ArticlePage);
