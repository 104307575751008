import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { animated } from 'react-spring';

/* Store */
import { selectMobile } from 'store/selectors';

/* Components */
import { DescriptionText, Button, Avatar, PersonalInfo } from './components';

/* Hooks */
import { useCardToggle } from './useCardToggle';
import { useAnimations } from './animations';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PersonCardComponent = ({
  name,
  info,
  description,
  image,
  isMobile,
  className,
}) => {
  const Component = isMobile ? 'div' : animated.div;

  const { open, onClick } = useCardToggle();
  const showInfo = useMemo(() => info && open, [open]);

  const {
    container,
    avatar,
    textAnimation,
    handleHover,
    handleMouseDown,
    handleMouseUp,
  } = useAnimations({ open });

  const handleInteraction = ({
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onClick,
  });

  return (
    <Component
      className={cx('component', className)}
      style={{ ...container }}
      {...(info && handleHover())}
      {...handleInteraction}
    >
      <div className={cx('container', info && 'withInfo')}>
        <Avatar animation={!isMobile && info ? avatar : {}} image={image} />

        <div className={cx('contentContainer')}>
          <div className={cx('content')}>
            <PersonalInfo className={cx('personalInfo')} name={name} description={description} />

            {info && <Button className={cx('arrow', open ? 'open' : 'closed')} isMobile={isMobile} />}
          </div>

          {showInfo && !isMobile && <DescriptionText text={info} textAnimation={textAnimation} />}
        </div>
      </div>

      {showInfo && isMobile && <DescriptionText text={info} textAnimation={textAnimation} />}
    </Component>
  );
};

PersonCardComponent.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  info: PropTypes.string,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
});

export const PersonCard = connect(mapStateToProps)(PersonCardComponent);
