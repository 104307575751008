import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';

/* Components */
import { LargeCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WebinarSeriesCardComponent = ({
  seriesPath,
  title,
  description,
  tags,
  speakers,
  isDesktop,
  className,
}) => (
  <LargeCard
    className={cx('component', className)}
    href={seriesPath}
    image="//security-api.ru/st/QP/Beelinelab/images/conferenceBanner.png"
    topRight={isDesktop && <LargeCard.Creators creators={speakers} />}
    tag={(
      <LargeCard.Tags
        href="/webinars"
        onlyMainTag
        tags={tags}
      />
    )}
    title={(
      <LargeCard.Title className={cx('title')}>
        {title}
      </LargeCard.Title>
    )}
    description={(
      <LargeCard.Description className={cx('description')}>
        {description}
      </LargeCard.Description>
    )}
  />
);

const mapStateToProps = (state, props) => ({
  isDesktop: selectDesktop(state),
  seriesPath: `/series/webinar/${props.alias}`,
});

WebinarSeriesCardComponent.propTypes = {
  seriesPath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
  speakers: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export const WebinarSeriesCard = connect(mapStateToProps)(WebinarSeriesCardComponent);
