import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectTablet } from 'store/selectors';

/* Components */
import { ArticleCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ArticlesRowComponent = ({
  articles,
  shouldRedirect,
  isTablet,
  className,
}) => {
  const shouldRenderExtraCard = useMemo(() => articles.length > 2 && !(articles.length % 2 === 0) && isTablet, [articles, isTablet]);

  return (
    <div className={cx('container', className)}>
      {articles?.map((article) => (
        <ArticleCard
          key={article.id}
          shouldRedirect={shouldRedirect}
          className={cx('card')}
          {...article}
        />
      ))}

      {shouldRenderExtraCard && <ArticleCard className={cx('card', 'empty')} isLastEmptyCard />}
    </div>
  )
};

const mapStateToProps = (state) => ({
  isTablet: selectTablet(state),
});

ArticlesRowComponent.propTypes = {
  content: PropTypes.array,
  shouldRedirect: PropTypes.bool,
  isTablet: PropTypes.bool,
  articles: PropTypes.array,
};

export const ArticlesRow = connect(mapStateToProps)(ArticlesRowComponent);
