import React, { useRef } from 'react';

/**
 * Similar to https://github.com/donavon/use-event-listener
 * Except accepts domNodes and refs as domTarget
 */
export const useEventListener = (eventName, handler, domTarget = global, { mode, useLayoutEffect } = {}) => {
  const savedHandler = useRef();
  const getDOMNode = () => domTarget && domTarget.current || domTarget;

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const useEffect = useLayoutEffect ? React.useLayoutEffect : React.useEffect;
  useEffect(
    () => {
      const domNode = getDOMNode();
      if (!domNode || !domNode.addEventListener) return undefined;

      const eventListener = (event) => savedHandler.current(event);
      domNode.addEventListener(eventName, eventListener, mode);
      return () => {
        domNode.removeEventListener(eventName, eventListener, mode);
      };
    },
    [eventName, getDOMNode()],
  );
};
