import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useWrapperParams } from './useWrapperParams';
import { WrapperContext } from './context';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

/**
 * ContentWrapper passes defined width down the context,
 * that trick allow reusing previous wrapper size without explicitly defining it
 */
export function Wrapper({
  children,
  fullWidth,
  width,
  padding,
  className,
  id,
  backgroundImage,
  ref,
}) {
  [width, padding] = useWrapperParams([width, padding]);
  return (
    <WrapperContext.Provider value={[width, padding]}>
      <div
        ref={ref}
        className={cx('container', className)}
        id={id}
        style={{
          width: fullWidth ? '100%' : `${width}px`,
          paddingLeft: `${padding}px`,
          paddingRight: `${padding}px`,
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        }}
      >
        {children}
      </div>
    </WrapperContext.Provider>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
};
