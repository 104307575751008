import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState, selectMobile, selectTablet } from 'store/selectors';
import { authSelector } from 'store/auth/selectors';
import { filterBanners } from 'api/dataMapper';

const courseSelector = createSelector(getGlobalState, (state) => state.course);

export const selectCourseId = (state) => courseSelector(state).id;
export const selectCourseTitle = (state) => courseSelector(state).title;
export const selectCourseBackgroundImage = (state) => courseSelector(state).backgroundImage;
export const selectCourseLength = (state) => courseSelector(state).videoLength;
export const selectCourseTags = (state) => courseSelector(state).tags;
export const selectCourseAlias = (state) => courseSelector(state).alias;
export const selectCourseTheme = (state) => courseSelector(state).description;
export const selectCourseContent = (state) => courseSelector(state).content;
export const selectCourseVideo = (state) => courseSelector(state).video;
export const selectCourseScorm = (state) => courseSelector(state).scorm;
export const selectCourseScormPlaceholder = (state) => courseSelector(state).placeholder;
export const selectCourseScormIsVertical = (state) => courseSelector(state).isVertical;
export const selectCourseSource = (state) => courseSelector(state).isExternal;
export const selectCourseViews = (state) => courseSelector(state).views;
export const selectCourseTeachers = (state) => courseSelector(state).teachers;
export const selectCourseDocuments = (state) => courseSelector(state).documents;
export const selectCourseRelatedContent = (state) => courseSelector(state).relatedContent;

export const selectCourseBanners = createSelector(
  [courseSelector, authSelector],
  ({ banners }, { isAuthorized }) => filterBanners(banners, isAuthorized),
);

export const selectCourseRelatedContentSlides = createSelector(
  [selectMobile, selectTablet],
  (isMobile, isTablet) => {
    if (isMobile) return 1;
    if (isTablet) return 2;

    return 3;
  },
);
