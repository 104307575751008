import { createReducer } from '@reduxjs/toolkit';

import {
  setMobileOS,
  setOverlay,
  setPopup,
  setSearchMode,
  setPrevUrl,
  setResultStatus,
  setErrorMessage,
} from './actions';

const defaultState = {
  mobileOS: '',
  searchMode: 'button',
  overlay: false,
  popup: {
    type: null,
    step: '',
    eventType: null,
  },
  resultStatus: '',
  errorMessage: null,
  prevUrl: '',
};

export const page = createReducer(defaultState, {
  [setSearchMode.type]: (state, action) => {
    state.searchMode = action.payload;
  },
  [setMobileOS.type]: (state, action) => {
    state.mobileOS = action.payload;
  },
  [setOverlay.type]: (state, action) => {
    state.overlay = action.payload;
  },
  [setPopup.type]: (state, action) => {
    state.popup = {
      ...state.popup,
      ...action.payload,
    };
  },
  [setPrevUrl.type]: (state, action) => {
    state.prevUrl = action.payload;
  },
  [setResultStatus.type]: (state, action) => {
    state.resultStatus = action.payload;
  },
  [setErrorMessage.type]: (state, action) => {
    state.errorMessage = action.payload;
  },
});
