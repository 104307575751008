import axios from 'axios';
import endpoints from '../endpoints';
import { TAG_TYPES } from 'constants/components';

const defaultCount = 10;
const defaultFilters = [];
// const defaultFilters = ['conference', 'webinar', 'course', 'article', 'lecture'];

const mapSearchResults = ({ data }) => {
  if (!data) return;

  const categories = data?.results?.map?.((item) => item.type.toLowerCase());
  const filteredData = categories?.filter((value, index) => categories.indexOf(value) === index)
    ?.map?.((item) => data?.results?.filter?.((result) => result?.type?.toLowerCase() === item));

  return filteredData.map((filteredItem) => ({
    alias: filteredItem[0].type.toLowerCase(),
    title: TAG_TYPES[filteredItem[0].type.toLowerCase()]?.name,
    items: filteredItem.map((item) => ({
      title: item.title,
      url: item.urn,
      id: item.id,
    })),
  }));
};

export const searchData = ({ value, max = defaultCount, filters = defaultFilters }) => axios.post(endpoints.searchData, {
  q: value,
  count: max,
  filters,
}).then(mapSearchResults);
