import classNames from 'classnames/bind';

/* Components */
import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardTitle = ({ children, className }) => (
  <Text className={cx('component', 'text', className)}>
    {children}
  </Text>
);
