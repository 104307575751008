import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState } from 'store/selectors';

const contentSelector = createSelector(getGlobalState, (state) => state.content);

export const selectArticles = (state) => (contentSelector(state).articles?.total > 0 ? contentSelector(state).articles?.data : null);
export const selectArticlesAmount = (state) => contentSelector(state).articles?.total;

export const selectCourses = (state) => (contentSelector(state).courses?.data?.length > 0 ? contentSelector(state).courses.data : null);
export const selectCoursesAmount = (state) => contentSelector(state).courses?.total;

export const selectLectures = (state) => (contentSelector(state).lectures?.total > 0 ? contentSelector(state).lectures?.data : null);
export const selectLecturesAmount = (state) => contentSelector(state).lectures?.total;

export const selectWebinarSeries = createSelector(contentSelector, (state) => state.webinarSeriesList?.series);
export const selectWebinarSeriesAmount = (state) => selectWebinarSeries(state)?.length || 0;

export const selectCourseSeries = createSelector(contentSelector, (state) => state.courseSeriesList?.series);
export const selectCourseSeriesAmount = (state) => selectCourseSeries(state)?.length || 0;

export const selectFutureWebinars = createSelector(contentSelector, (state) => state.futureWebinars?.data || null);
export const selectPastWebinars = createSelector(contentSelector, (state) => state.pastWebinars?.data || null);
export const selectPastWebinarsAmount = createSelector(contentSelector, (state) => state.pastWebinars?.total
  - state.futureWebinars?.data?.length || null);

export const selectFutureConferences = createSelector(contentSelector, (state) => state.futureConferences?.data || null);
export const selectPastConferences = createSelector(contentSelector, (state) => state.pastConferences?.data || null);
export const selectPastConferencesAmount = createSelector(contentSelector, (state) => state.pastConferences?.total
  - state.futureConferences?.data?.length || null);

export const selectFutureConferencesAmount = (state) => selectFutureConferences(state)?.length || 0;
export const selectFutureWebinarsAmount = (state) => selectFutureWebinars(state)?.length || 0;

export const selectContentAmount = (state, contentType) => contentSelector(state)[contentType]?.total;
export const selectFutureEventsAmount = (state) => selectFutureConferencesAmount(state) + selectFutureWebinarsAmount(state);
export const selectPastEventsAmount = (state) => selectPastConferencesAmount(state) + selectPastWebinarsAmount(state);
