import { createSelector } from '@reduxjs/toolkit';
import { selectAuthPopupTexts } from 'store/widgets';

// Auth
export const selectCodeTimeout = createSelector(selectAuthPopupTexts, (state) => state.codeTimeoutInSeconds);
export const selectRegisterText = createSelector(selectAuthPopupTexts, (state) => state.register);
export const selectLoginText = createSelector(selectAuthPopupTexts, (state) => state.login);
export const selectEmailWarningText = createSelector(selectAuthPopupTexts, (state) => state.emailInputWarning);

// Auth Form Fields
export const selectAuthFormFields = (state) => selectAuthPopupTexts(state).authFormFields;
export const selectCodeInputFields = (state) => selectAuthFormFields(state).codeInput;
export const selectPhoneInputFields = (state) => selectAuthFormFields(state).phoneInput;
export const selectCompanyInputFields = (state) => selectAuthFormFields(state).companyInput;
export const selectEmailInputFields = (state) => selectAuthFormFields(state).emailInput;
export const selectUserNameInputFields = (state) => selectAuthFormFields(state).userNameInput;
export const selectPositionInputFields = (state) => selectAuthFormFields(state).positionInput;
export const selectTownInputFields = (state) => selectAuthFormFields(state).townInput;
export const selectRepeatButton = (state) => selectCodeInputFields(state).repeatButton;

// Auth Result Screens
export const selectAuthResultScreens = (state) => selectAuthPopupTexts(state).authResultScreens;
