import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Store */
import { selectLectureTitle } from 'store/lecture/selectors';

/* Components */
import { FloatingInfoPanel } from 'components';

const FloatingPanelComponent = ({ title, container }) => (
  <FloatingInfoPanel
    title={(
      <FloatingInfoPanel.Title>
        {title}
      </FloatingInfoPanel.Title>
    )}
    backButton={<FloatingInfoPanel.BackButton />}
    shareButton={<FloatingInfoPanel.ShareButton fullSize />}
    container={container}
  />
);

const mapStateToProps = (state) => ({
  title: selectLectureTitle(state),
});

FloatingPanelComponent.propTypes = {
  title: PropTypes.string,
};

export const FloatingPanel = connect(mapStateToProps)(FloatingPanelComponent);
