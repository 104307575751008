import { createSelector } from '@reduxjs/toolkit';
import {
  getGlobalState,
  selectLargeDesktop,
  selectMobile,
  selectTablet,
} from 'store/selectors';
import { authSelector } from 'store/auth/selectors';
import { filterBanners } from 'api/dataMapper';

const lectureSelector = createSelector(getGlobalState, (state) => state.lecture);

export const selectLectureTitle = (state) => lectureSelector(state).title;
export const selectLectureImage = (state) => lectureSelector(state).image;
export const selectLectureDuration = (state) => lectureSelector(state).duration;
export const selectLectureTags = (state) => lectureSelector(state).tags;
export const selectLectureAlias = (state) => lectureSelector(state).alias;
export const selectLectureDescription = (state) => lectureSelector(state).description;
export const selectLectureContent = (state) => lectureSelector(state).content;
export const selectLectureVideo = (state) => lectureSelector(state).video;
export const selectLectureVideoSource = (state) => lectureSelector(state).isExternal;
export const selectLectureViews = (state) => lectureSelector(state).views;
export const selectLectureAuthors = (state) => lectureSelector(state).authors;
export const selectLecturePartners = (state) => lectureSelector(state).partners;
export const selectLectureRelatedContent = (state) => lectureSelector(state).relatedContent;

export const selectLectureBanners = createSelector(
  [lectureSelector, authSelector],
  ({ banners }, { isAuthorized }) => filterBanners(banners, isAuthorized),
);

export const selectLectureRelatedContentSlides = createSelector(
  [selectMobile, selectTablet, selectLargeDesktop],
  (isMobile, isTablet, isLargeDesktop) => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    if (isLargeDesktop) return 4;

    return 3;
  },
);