import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import { changeEmailForm, validateEmail } from 'utils/formValidators';

/* Hooks */
import { useProfileUpdate } from 'hooks/useMakeRequest';

/* Constants */
import { RESULT_STATUS } from 'constants/constants';

/* Store */
import { selectChangeEmailTitle } from 'store/widgets';
import { selectChangedEmail } from 'store/user/selectors';
import { setResultStatus } from 'store/page/actions';
import { setEmail } from 'store/user/actions';

/* Components */
import { EmailInput, SubmitButton } from 'components/Inputs';
import { ChangeData } from '../ChangeData';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ChangeEmailComponent = ({ title, changedEmail, onSetEmail, onSetResultStatus }) => {
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState(changedEmail);
  const updateProfile = useProfileUpdate();
  const isValid = useMemo(() => !!value?.length && !validateEmail(value), [value]);

  const handleSuccess = () => {
    setDisabled(false);
    onSetResultStatus(RESULT_STATUS.emailUpdated);
  };

  const handleChange = useCallback((e) => setValue(e.target.value), []);

  const handleSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    updateProfile({ email: changedEmail }, handleSuccess);
  };

  return (
    <ChangeData title={title} handleValidate={changeEmailForm} initialValues={{ email: changedEmail }}>
      <EmailInput className={cx('input')} onChangeProxy={handleChange} onBlur={onSetEmail} />
      <SubmitButton className={cx('button')} onClick={handleSubmit} disabled={!isValid || disabled} />
    </ChangeData>
  );
};

const mapStateToProps = (state) => ({
  title: selectChangeEmailTitle(state),
  changedEmail: selectChangedEmail(state),
});

const mapDispatchToProps = {
  onSetEmail: setEmail,
  onSetResultStatus: setResultStatus,
};

ChangeEmailComponent.propTypes = {
  title: PropTypes.string,
  changedEmail: PropTypes.string,
  onSetEmail: PropTypes.func,
  onSetResultStatus: PropTypes.func,
};

export const ChangeEmail = connect(mapStateToProps, mapDispatchToProps)(ChangeEmailComponent);
