import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import { loginForm } from 'utils/formValidators';

/* Components */
import { AuthPhoneInput, ChangeTab, LoginOptions } from './components';
import { AuthForm } from './Form';

/* Constants */
import { NOT_REGISTERED } from 'constants/authorizationErrors';
import { DEFAULT_AUTH_TAB, LOGIN_OPTIONS, RESULT_STATUS } from 'constants/constants';

/* Store */
import { selectRegisterText } from 'store/widgets';
import { loginConfirmRequest, loginRequest, mobileIdCodeConfirm } from 'store/auth/actions';
import { selectPhoneInputErrorType } from 'store/auth/selectors';
import { setResultStatus } from 'store/page';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LoginFormComponent = ({
  setNextStep,
  saveResultStatus,
  displayRegister,
  registerText,
  changeTab,
  onLoginRequest,
  onLoginConfirmRequest,
  onMobileIdConfirmRequest,
}) => {
  const [option, setOption] = useState(LOGIN_OPTIONS.sms);
  const isMobileId = useMemo(() => option === LOGIN_OPTIONS.mobileID, [option]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveResultStatus(RESULT_STATUS.loginSuccess);
  };

  const handleLoginConfirm = (code) => onLoginConfirmRequest(code);

  const handleMobileIdConfirm = (code) => onMobileIdConfirmRequest(code);

  const handleConfirm = isMobileId ? handleMobileIdConfirm : handleLoginConfirm;

  return (
    <div>
      <AuthForm
        className={cx('phoneForm')}
        onSubmit={handleSubmit}
        setNextStep={setNextStep}
        shouldRenderMobileIdLogin={isMobileId}
        validateForm={loginForm}
      >
        <LoginOptions
          className={cx('optionTabs')}
          selectedOption={option}
          onClick={(option) => setOption(option)}
        />

        <AuthPhoneInput
          className={cx('field')}
          onConfirm={handleConfirm}
          onRequestCode={onLoginRequest}
          hideSubmit={isMobileId}
        />

        {displayRegister && (
          <ChangeTab onClick={() => changeTab(DEFAULT_AUTH_TAB)}>
            {registerText}
          </ChangeTab>
        )}
      </AuthForm>
    </div>
  );
};

const mapStateToProps = (state) => ({
  displayRegister: selectPhoneInputErrorType(state) === NOT_REGISTERED,
  registerText: selectRegisterText(state),
});

const mapDispatchToProps = {
  saveResultStatus: setResultStatus,
  onLoginRequest: loginRequest,
  onLoginConfirmRequest: loginConfirmRequest,
  onMobileIdConfirmRequest: mobileIdCodeConfirm,
};

LoginFormComponent.propTypes = {
  registerText: PropTypes.string,
  displayRegister: PropTypes.bool,
  setNextStep: PropTypes.func,
  saveResultStatus: PropTypes.func,
  changeTab: PropTypes.func,
  onLoginRequest: PropTypes.func,
  onLoginConfirmRequest: PropTypes.func,
  onMobileIdConfirmRequest: PropTypes.func,
};

export const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent);
