import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState, selectMobile, selectTablet } from 'store/selectors';
import { authSelector } from 'store/auth/selectors';
import { filterBanners } from 'api/dataMapper';

const articleSelector = createSelector(getGlobalState, (state) => state.article);

export const selectArticleTitle = (state) => articleSelector(state).title;
export const selectArticleBackgroundImage = (state) => articleSelector(state).backgroundImage;
export const selectArticleTags = (state) => articleSelector(state).tags;
export const selectArticleAlias = (state) => articleSelector(state).alias;
export const selectArticleDescription = (state) => articleSelector(state).description;
export const selectArticleContent = (state) => articleSelector(state).content;
export const selectArticleReadTime = (state) => articleSelector(state).readTime;
export const selectArticleViews = (state) => articleSelector(state).views;
export const selectArticleRelatedContent = (state) => articleSelector(state).relatedContent;

export const selectArticleBanners = createSelector(
  [articleSelector, authSelector],
  ({ banners }, { isAuthorized }) => filterBanners(banners, isAuthorized),
);

export const selectArticleRelatedContentSlides = createSelector(
  [selectMobile, selectTablet],
  (isMobile, isTablet) => {
    if (isMobile) return 1;
    if (isTablet) return 3;

    return 4;
  },
);
