import { useRef, useState } from 'react';
import { useEventListener } from 'hooks/useEventListener';
import { getClientRect } from 'utils/getClientRect';

export const useFloatingButton = (ref) => {
  const [buttonShown, showButton] = useState(false);

  const handleClick = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    showButton(getClientRect(ref)?.top < 0);
  };

  if (typeof window !== 'undefined') {
    useEventListener('scroll', handleScroll, window);
  }

  return {
    renderButton: buttonShown,
    onClick: handleClick,
  }
}
