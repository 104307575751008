import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Link, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Links = ({ links, className }) => (
  <ul className={cx('component', className)}>
    {links.map(({ title, href, isExternal }) => (
      <Link key={title} routerLink={!isExternal} href={href} className={cx('link')}>
        <Text isInline className={cx('text', isExternal && 'external' )}>
          {title}
        </Text>
      </Link>
    ))}
  </ul>
);

Links.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    isExternal: PropTypes.bool,
  })),
};
