import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import endpoints from 'api/endpoints';
import { compose } from 'utils/fn';
import { scrollTo } from 'utils/scrollTo';

/* Hocs */
import { withDataLoading, Endpoint } from 'hocs';

/* Hooks */
import { useEventSubPopup } from 'hooks/useEventSubPopup';
import { useRestrictedAccess } from 'hooks/useRestricredAccess';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectWebinarPageButton, selectWebinarPageSpeakersLabel } from 'store/widgets';
import {
  selectWebinarTitle,
  selectWebinarTheme,
  selectWebinarTags,
  selectWebinarPlacesLeft,
  selectWebinarRegistered,
  selectWebinarDate,
  selectWebinarLength,
  selectWebinarContent,
  selectWebinarImage,
  selectWebinarVideo,
  selectWebinarSource,
  selectWebinarBanners,
  selectWebinarId,
  selectWebinarAuthors,
  selectWebinarRelatedContent,
  selectWebinarRelatedContentSlides,
  selectWebinarStatus,
} from 'store/webinar/selectors';

/* Components */
import {
  Wrapper,
  ContentContainer,
  FloatingUpButton,
  MenuButton,
  VideoBlock,
  BannersBlock,
  SpeakersBlock,
  DeskNavigation as Navigation,
  RelatedContent,
  WebinarCard,
} from 'components';
import { PageHeader } from 'components/PageHeader';
import { FloatingPanel } from './components';
import { useFloatingButton } from 'components/FloatingUpButton/useFloatingButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WebinarPage = ({
  id,
  title,
  tags,
  theme,
  date,
  registered,
  placesLeft,
  length,
  buttonText,
  content,
  isDesktop,
  isFuture,
  thumbnail,
  src,
  isExternal,
  banners,
  speakers,
  blockLabel,
  relatedContent,
  slides,
}) => {
  const container = useRef(null);

  const { renderButton } = useFloatingButton(container);
  const { open } = useEventSubPopup();
  const { handleRestrictedAccess } = useRestrictedAccess();

  const handleWatchClick = () => scrollTo('video');

  const handleEventSub = () => handleRestrictedAccess(() => open('webinar', id));

  const handleClick = isFuture ? handleEventSub : handleWatchClick;

  return (
    <>
      <Wrapper padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
        <Navigation className={cx('navigation')} />
      </Wrapper>

      <PageHeader
        className={cx('header')}
        image={(
          <PageHeader.Image
            className={cx('background')}
            // src="//security-api.ru/st/QP/Beelinelab/images/webinar_page_banner.png"
            absolute
          />
        )}
        tags={<PageHeader.Tags className={cx('tags')} tags={tags} />}
        title={(
          <PageHeader.Title className={cx('title')}>
            {title}
          </PageHeader.Title>
        )}
        theme={(
          <PageHeader.Theme className={cx('theme')}>
            {theme}
          </PageHeader.Theme>
        )}
        info={(
          <PageHeader.InfoPanel
            date={date}
            registered={registered}
            placesLeft={placesLeft}
            length={length}
          />
        )}
        button={(
          <PageHeader.Button className={cx('button')} onClick={handleClick} isAdaptive>
            {buttonText}
          </PageHeader.Button>
        )}
      />
      <div ref={container}>
        <Wrapper className={cx('content')} padding={isDesktop ? 0 : 20} fullWidth={!isDesktop} width={800}>
          <VideoBlock className={cx('video')} thumbnail={thumbnail} src={src} isExternal={isExternal} />

          <div className={cx('innerContent')}>
            <SpeakersBlock className={cx('speakers')} speakers={speakers} blockLabel={blockLabel} />

            <ContentContainer className={cx('parsedContent')}>
              {content}
            </ContentContainer>


          </div>
        </Wrapper>

        <Wrapper className={cx('banners')} padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
          <BannersBlock banners={banners} />

          {relatedContent && (
            <RelatedContent slides={slides}>
              {relatedContent.map((webinar) => (
                <WebinarCard className={cx('card')} key={webinar.id} shouldRedirect {...webinar} />
              ))}
            </RelatedContent>
          )}
        </Wrapper>

        {isDesktop && renderButton && <FloatingUpButton />}

        <FloatingPanel container={container} onMainButtonClick={handleClick} />
      </div>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  id: selectWebinarId(state),
  title: selectWebinarTitle(state),
  tags: selectWebinarTags(state),
  theme: selectWebinarTheme(state),
  placesLeft: selectWebinarPlacesLeft(state),
  registered: selectWebinarRegistered(state),
  date: selectWebinarDate(state),
  length: selectWebinarLength(state),
  isFuture: selectWebinarStatus(state),
  buttonText: selectWebinarPageButton(state),
  content: selectWebinarContent(state),
  thumbnail: selectWebinarImage(state),
  src: selectWebinarVideo(state),
  isExternal: selectWebinarSource(state),
  banners: selectWebinarBanners(state),
  speakers: selectWebinarAuthors(state),
  relatedContent: selectWebinarRelatedContent(state),
  slides: selectWebinarRelatedContentSlides(state),
  blockLabel: selectWebinarPageSpeakersLabel(state),
});

WebinarPage.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  length: PropTypes.string,
  buttonText: PropTypes.string,
  thumbnail: PropTypes.string,
  src: PropTypes.string,
  blockLabel: PropTypes.string,
  isExternal: PropTypes.bool,
  id: PropTypes.number,
  registered: PropTypes.number,
  placesLeft: PropTypes.number,
  slides: PropTypes.number,
  tags: PropTypes.array,
  banners: PropTypes.array,
  speakers: PropTypes.array,
  relatedContent: PropTypes.array,
  isDesktop: PropTypes.bool,
  isFuture: PropTypes.bool,
};

export const Webinar = compose(
  withDataLoading(({ alias }) => ({
    webinar: new Endpoint(`${endpoints.requestWebinar}/?alias=${alias}`)
  })),
  connect(mapStateToProps, null),
)(WebinarPage);
