import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { validateEmail } from 'utils/formValidators';

import { selectEmailInputFields, selectEmailWarningText } from 'store/widgets';

import { FFInput } from 'components/Inputs';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const EmailInputComponent = ({
  children,
  editButton,
  confirmButton,
  errorMessage,
  inputProps,
  className,
  ...restProps
}) => (
  <div className={cx('wrapper', className)}>
    <div className={cx('component')}>
      <FFInput
        {...inputProps}
        {...restProps}
        className={cx('input')}
        message={errorMessage}
        validate={validateEmail}
      >
        {children}
      </FFInput>

      {editButton}
    </div>

    {confirmButton}
  </div>
);

const mapStateToProps = (state) => ({
  inputProps: selectEmailInputFields(state),
  errorMessage: selectEmailWarningText(state),
});

EmailInputComponent.propTypes = {
  errorMessage: PropTypes.string,
  isAuthorized: PropTypes.bool,
  editButton: PropTypes.node,
  confirmButton: PropTypes.node,
  inputProps: PropTypes.object,
};

export const EmailInput = connect(mapStateToProps, null)(EmailInputComponent);
