import classNames from 'classnames/bind';

import { BlackButtonOrLink } from 'components';
import Cross from '../../../../images/close.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CancelButton = ({ onClick, className }) => (
  <BlackButtonOrLink className={cx('component', className)} isRound onClick={onClick}>
    <Cross />
  </BlackButtonOrLink>
);
