import React, { useRef, useEffect } from 'react';

import styles from './styles.pcss';

export const TransitionObserver = ({ children }) => {
  if (typeof window === 'undefined') return null;

  const containerRef = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const handleDOMChange = () => {
      const { height } = componentRef.current.getBoundingClientRect();
      containerRef.current.style.minHeight = `${height}px`;
      containerRef.current.style.maxHeight = `${height}px`;
    };

    const observer = new MutationObserver(handleDOMChange);
    observer.observe(componentRef.current, { childList: true, subtree: true });

    handleDOMChange();

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.stretchContainer} ref={containerRef}>
      <div ref={componentRef}>
        {children}
      </div>
    </div>
  );
};
