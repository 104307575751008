import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePopup } from 'hooks/usePopup';

export const useEmailConfirmed = () => {
  const history = useHistory();
  const emailConfirmed = useLocation()?.search?.includes('emailConfirmed=true');
  const confirmationError = useLocation()?.search?.includes('emailConfirmed=false');
  const { openEmailConfirmedPopup, openConfirmationErrorPopup } = usePopup();

  useEffect(() => {
    if (emailConfirmed) {
      openEmailConfirmedPopup();
      history.replace({ search: '' });
    } else if (confirmationError) {
      openConfirmationErrorPopup();
    }
  }, []);
};
