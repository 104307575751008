import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

/* Components */
import { Text } from 'components';

const cx = classNames.bind(styles);

export const Tab = ({ active, onClick, children, className }) => (
  <Text className={cx('tab', { active }, className)} onClick={onClick}>
    {children}
  </Text>
);

Tab.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
