import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectIsPopupStepForm } from 'store/page/selectors';

/* Components */
import { Wrapper } from 'components';
import { CloseButton, NavBar, Image } from './components';

const cx = classNames.bind(styles);

const PopupComponent = ({
  image,
  closeButton,
  navBar,
  withLogo,
  isDesktop,
  isFullView,
  className,
  children,
}) => (
  <div className={cx('wrapper', { isFullView }, className)}>
    <Wrapper
      className={cx('container')}
      fullWidth={!isDesktop}
      width={1472}
      padding={isDesktop ? 44 : 0}
    >
      {navBar && (
        <Popup.NavBar
          className={cx('navbar', { isFullView })}
          withLogo={withLogo}
          closeButton={closeButton}
        />
      )}
      <div className={cx('component', isFullView ? 'fullView' : 'modal')}>
        {children}
        {image}
      </div>
    </Wrapper>
  </div>
);

const mapStateToProps = (state, props) => ({
  isDesktop: selectDesktop(state),
  isFullView: props?.isFullView || (selectIsPopupStepForm(state) && !selectDesktop(state)),
});

export const Popup = connect(mapStateToProps, null)(PopupComponent);

PopupComponent.propTypes = {
  isDesktop: PropTypes.bool,
  closeButton: PropTypes.node,
  image: PropTypes.node,
  navBar: PropTypes.bool,
};

Popup.CloseButton = CloseButton;
Popup.NavBar = NavBar;
Popup.Image = Image;
