import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop, selectMobile } from 'store/selectors';

/* Components */
import { Wrapper } from 'components';
import {
  Image,
  Theme,
  Tags,
  Title,
  Button,
  ButtonsContainer,
  InfoPanel,
} from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PageHeaderComponent = ({
  image,
  title,
  tags,
  theme,
  info,
  button,
  isMobile,
  isDesktop,
  className,
}) => (
  <div className={className}>
    {image}

    <Wrapper className={cx('container')} fullWidth={!isDesktop} width={1472} padding={isMobile ? 20 : 44}>
      {tags}
      {title}
      {theme}

      {info && (
        <ButtonsContainer className={cx('infoPanel', button && 'reversed')} button={button}>
          {info}
        </ButtonsContainer>
      )}
    </Wrapper>
  </div>
);

PageHeaderComponent.propTypes = {
  image: PropTypes.node,
  tags: PropTypes.node,
  title: PropTypes.node,
  theme: PropTypes.node,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
  isDesktop: selectDesktop(state),
});

export const PageHeader = connect(mapStateToProps)(PageHeaderComponent);

PageHeader.Image = Image;
PageHeader.Title = Title;
PageHeader.Tags = Tags;
PageHeader.Theme = Theme;
PageHeader.InfoPanel = InfoPanel;
PageHeader.Button = Button;
