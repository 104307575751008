import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectEmailButtonText, selectEmailTerms } from 'store/widgets';

/* Components */
import { PrimaryButtonOrLink, Text } from 'components';
import { FForm } from 'components/FForm';
import { EmailInput } from 'components/Inputs';

/* Hooks */
import { usePopup } from 'hooks/usePopup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const EmailFormComponent = ({ buttonText, terms }) => {
  const { openAuthPopup } = usePopup();

  const handleClick = () => openAuthPopup();

  return (
    <div>
      <FForm className={cx('form')}>
        <EmailInput className={cx('input')} />

        <PrimaryButtonOrLink
          size="large"
          className={cx('submitButton')}
          onClick={handleClick}
          arrow={{
            render: true,
            mode: 'normal',
          }}
        >
          {buttonText}
        </PrimaryButtonOrLink>
      </FForm>

      {terms && (
        <Text className={cx('terms')}>
          {terms}
        </Text>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  buttonText: selectEmailButtonText(state),
  terms: selectEmailTerms(state),
});

EmailFormComponent.propTypes = {
  buttonText: PropTypes.string,
  terms: PropTypes.string,
};

export const EmailForm = connect(mapStateToProps, null)(EmailFormComponent);
