import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { repeatEmail } from 'api/requests/auth';

import { selectEmailConfirmText, selectRepeatWarningText } from 'store/widgets';
import { selectUserId } from 'store/user/selectors';

import { ButtonOrLink, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const EmailConfirmButtonComponent = ({ text, repeatWarningText, userId, className }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = () => {
    setDisabled(true);
    repeatEmail({ userId }).catch(() => setError(true));
  };

  return (
    <div>
      <ButtonOrLink
        animation={{ animated: false }}
        className={cx('component', className)}
        onClick={handleClick}
        disabled={disabled}
      >
        <Text className={cx({ disabled })}>
          {text}
        </Text>
      </ButtonOrLink>

      {error && (
        <Text>
          {repeatWarningText}
        </Text>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  text: selectEmailConfirmText(state),
  repeatWarningText: selectRepeatWarningText(state),
  userId: selectUserId(state),
});

EmailConfirmButtonComponent.propTypes = {
  text: PropTypes.string,
  repeatWarningText: PropTypes.string,
  userId: PropTypes.number,
};

export const EmailConfirmButton = connect(mapStateToProps)(EmailConfirmButtonComponent);
