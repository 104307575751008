import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState } from 'store/selectors';

const seriesSelector = createSelector(getGlobalState, (state) => state.series);
const courseSeriesSelector = createSelector(seriesSelector, (state) => state?.courseSeries);
const webinarSeriesSelector = createSelector(seriesSelector, (state) => state?.webinarSeries);

// CourseSeries
export const selectCourseSeriesAlias = (state) => courseSeriesSelector(state)?.alias;
export const selectCourseSeriesTitle = (state) => courseSeriesSelector(state)?.title;
export const selectCourseSeriesCourses = (state) => courseSeriesSelector(state)?.series;

// WebinarSeries
export const selectWebinarSeriesAlias = (state) => webinarSeriesSelector(state)?.alias;
export const selectWebinarSeriesTitle = (state) => webinarSeriesSelector(state)?.title;
export const selectWebinarSeriesWebinars = (state) => webinarSeriesSelector(state)?.series;
