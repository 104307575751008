import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectNavigationContentButtons } from 'store/widgets';

/* Components */
import { ContentButton } from '../components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DeskNavigationComponent = ({ path, contentButtons, isDesktop, hideEmpty, className }) => isDesktop && (
<div className={cx('container', className)}>
  {contentButtons?.map(({ key, title, matchPath, amount }) => (
    <ContentButton
      className={cx('button')}
      key={key}
      url={matchPath[0]}
      isActive={matchPath.some((item) => item === path)}
      text={(
        <ContentButton.Part className={cx('text')}>
          {title}
        </ContentButton.Part>
      )}
      amount={(
        <ContentButton.Part className={cx('amount')}>
          {hideEmpty ? (amount || '') : amount}
        </ContentButton.Part>
      )}
    />
  ))}
</div>
);

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  contentButtons: selectNavigationContentButtons(state),
});

DeskNavigationComponent.propTypes = {
  contentButtons: PropTypes.array,
  isDesktop: PropTypes.bool,
  path: PropTypes.string,
  hideEmpty: PropTypes.bool,
};

export const DeskNavigationPlain = connect(mapStateToProps)(DeskNavigationComponent);

export const DeskNavigation = (props) => {
  const { path } = useRouteMatch();
  return <DeskNavigationPlain {...props} path={path} />;
};

