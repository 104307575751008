import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Store */
import { selectLectures, selectLecturesAmount } from 'store/content/selectors';
import { loadMoreLectures, selectLecturesLoadedFlag, selectLoadedLectures } from 'store/pagination';

/* Components */
import { LecturesRow, ShowMoreButton } from 'components';

const LecturesComponent = ({ lectures, loaded, amount, fullyLoaded, onLoadMore, className }) => {
  if (!lectures || !lectures?.length) return null;

  return (
    <>
      <LecturesRow
        className={className}
        lectures={loaded?.length > 0 ? [...lectures, ...loaded] : lectures}
        shouldRedirect={false}
      />

      {!fullyLoaded && useMemo(() => (
        <ShowMoreButton total={amount} onClick={onLoadMore} />
      ), [onLoadMore])}
    </>
  );
};

const mapStateToProps = (state) => ({
  lectures: selectLectures(state),
  loaded: selectLoadedLectures(state),
  amount: selectLecturesAmount(state),
  fullyLoaded: selectLecturesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreLectures,
};

LecturesComponent.propTypes = {
  lectures: PropTypes.array,
  loaded: PropTypes.array,
  amount: PropTypes.number,
  onLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
};

export const LecturesBlock = connect(mapStateToProps, mapDispatchToProps)(LecturesComponent);
