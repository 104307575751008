import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import { compose } from 'utils/fn';
import endpoints from 'api/endpoints';

/* Store */
import { selectCourseSeriesTitle, selectCourseSeriesCourses } from 'store/series';

/* Components */
import { CoursesRow } from 'components';
import { PageTemplate } from 'pages/ContentList';

/* Hocs */
import { withDataLoading, Endpoint } from 'hocs';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CoursesSeriesComponent = ({ title, courses }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/courses_page_banner.png"
    backgroundClassName={cx('background')}
  >
    {courses && (
      <CoursesRow content={courses} shouldRedirect={false} />
    )}
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectCourseSeriesTitle(state),
  courses: selectCourseSeriesCourses(state),
});

CoursesSeriesComponent.propTypes = {
  title: PropTypes.string,
  courses: PropTypes.array,
};

export const CoursesSeries = compose(
  withDataLoading(({ alias }) => ({
    courseSeries: new Endpoint(`${endpoints.requestCourseSeries}/?alias=${alias}`),
  })),
  connect(mapStateToProps, null),
)(CoursesSeriesComponent);
