import React, { createContext } from 'react';

export const IsExternalModule = createContext(false);

export const withIsExternalModule = (isExternalModule = true) => (
  (WrappedComponent) => (
    (props) => (
      <IsExternalModule.Provider value={isExternalModule}>
        <WrappedComponent {...props} />
      </IsExternalModule.Provider>
    )
  )
);
