import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { ButtonOrLink, Text } from 'components';
import Logout from '../../../../../../images/logout.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Header = ({ title, logout, onClick }) => (
  <div className={cx('component')}>
    <Text className={cx('title')}>
      {title}
    </Text>

    <ButtonOrLink className={cx('button')} onClick={onClick} animation={{ animated: false }}>
      <Text className={cx('text')}>
        {logout}
      </Text>

      <Logout className={cx('icon')} />
    </ButtonOrLink>
  </div>
);

Header.propTypes = {
  title: PropTypes.string,
  logout: PropTypes.string,
  onClick: PropTypes.func,
};
