import { Form } from 'react-final-form';

export const FForm = ({
  onSubmit = () => {},
  initialValues,
  validateForm,
  className,
  renderSubmitButton,
  shouldRenderMobileIdLogin,
  children,
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validateForm}
    initialValues={initialValues}
    render={({ handleSubmit, valid }) => (
      <form className={className} onSubmit={handleSubmit}>
        {children}
        {renderSubmitButton?.({ valid, handleSubmit })}
        {shouldRenderMobileIdLogin?.({ valid })}
      </form>
    )}
  />
);
