import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PageNavigation = ({ title, backButton, shareButton, mainButton, scrollUp }) => (
  <>
    {scrollUp && (
      <div className={cx('essential')}>
        {backButton}
        {title}
      </div>
    )}

    <div className={cx('additionalButtons')}>
      {shareButton}
      {mainButton}
    </div>
  </>
);