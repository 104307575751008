import { useEffect, useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;
let itemsLeft = null;

export const useShowMoreButton = ({ total, onClick }) => {
  let pageNumber = 1;

  const [shouldDisplayButton, setDisplayButton] = useState(total > DEFAULT_PAGE_SIZE);

  useEffect(() => setDisplayButton(total > DEFAULT_PAGE_SIZE), []);

  itemsLeft = total;

  const handleClick = () => {
    pageNumber = pageNumber + 1;
    onClick(pageNumber);
    itemsLeft = itemsLeft - DEFAULT_PAGE_SIZE;
    setDisplayButton(itemsLeft > 0);
  };

  return { shouldDisplayButton, handleClick };
};
