import { createReducer } from '@reduxjs/toolkit';

import { setUserData, setUserChanges, resetUserData, deleteChanges } from 'store/user/actions';

const defaultState = {
  id: null,
  name: null,
  avatar: null,
  email: null,
  phoneNumber: null,
  position: null,
  telegram: null,
  mail: false,
  events: null,
  town: {
    id: null,
    name: null,
  },
  company: {
    id: null,
    name: null,
  },
  changes: null,
};

export const user = createReducer(defaultState, {
  [setUserData.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [setUserChanges.type]: (state, action) => {
    state.changes = ({
      ...state.changes,
      ...action.payload,
    });
  },
  [resetUserData.type]: () => defaultState,
  [deleteChanges.type]: (state) => {
    if (state.changes?.email) {
      state.changes = ({
        email: state.changes?.email,
      });
    } else {
      state.changes = defaultState.changes;
    }
  },
});
