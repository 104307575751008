import { Children } from 'react';
import PropTypes from 'prop-types';

import { FadeSlide } from './Animations';
import { Popup, TransitionObserver } from 'components';
import { useWizard } from './useWizard';
import { isIE } from 'utils/detectIE';

export const Step = (children) => (
  <div>
    {children}
  </div>
);

Step.displayName = 'Step';

export const Wizard = ({
  activeStep,
  opened,
  image,
  onClose,
  children,
  closeButton,
  withNavBar,
  withLogo,
  isFullView,
}, props) => {
  const { step, nextStep, handleExited } = useWizard({ opened, activeStep });

  const params = {};

  Children.forEach(children, (el) => {
    params[el.props.name] = {
      onClose: el.props.onClose,
    };
  });

  return opened && (
    <Popup
      {...props}
      onClose={(params?.[activeStep]?.onClose) || onClose}
      image={image}
      closeButton={closeButton}
      navBar={withNavBar}
      withLogo={withLogo}
      isFullView={isFullView || isIE()}
    >
      <TransitionObserver>
        {Children.map(children, (element) => (
          element.type.displayName === Step.displayName && (
            <FadeSlide
              timeout={{ enter: 300, exit: 100 }}
              in={step === element.props.name}
              onExited={() => handleExited(params[nextStep])}
              mountOnEnter
              unmountOnExit
              appear
            >
              <div>
                {element.props.children}
              </div>
            </FadeSlide>
          )
        ))}
      </TransitionObserver>
    </Popup>
  );
};

Wizard.propTypes = {
  activeStep: PropTypes.string.isRequired,
  opened: PropTypes.bool,
  backLinkText: PropTypes.string,
  onClose: PropTypes.func,
};
