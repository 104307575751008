import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { animated } from 'react-spring';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const DescriptionText = ({ text, textAnimation }) => {
  const Component = textAnimation ? animated.div : 'div';

  return (
    <Component style={{...textAnimation}}>
      <Text className={cx('component')}>
        {text}
      </Text>
    </Component>
  )
};

DescriptionText.propTypes = {
  text: PropTypes.string,
  textAnimation: PropTypes.object,
};
