import { createReducer } from '@reduxjs/toolkit';

import { setTexts } from 'store/widgets/actions';

export const texts = createReducer({
  main: null,
  auth: null,
  'page_lecture': null,
  'page_webinar': null,
}, {
  [setTexts.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
