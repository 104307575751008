import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

/* Components */
import { BlackButtonOrLink } from 'components';
import BackArrow from '../../../../images/arrow_mini.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BackButton = ({ onBack, className }) => {
  const history = useHistory();

  const handleClick = () => {
    onBack?.();

    history.goBack();
  };

  return (
    <BlackButtonOrLink className={cx('component', className)} onClick={handleClick}>
      <BackArrow className={cx('icon')} />
    </BlackButtonOrLink>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func,
};
