import { connect } from 'react-redux';

/* Store */
import { selectFutureWebinars, selectFutureWebinarsAmount } from 'store/content/selectors';
import {
  loadMoreFutureWebinars,
  selectFutureWebinarsLoadedFlag,
  selectLoadedFutureWebinars,
} from 'store/pagination';

/* Components */
import { Template } from './Template';

const FutureWebinarsComponent = ({ webinars, loaded, amount, fullyLoaded, onLoadMore, className }) => (
  <Template
    content={webinars}
    loaded={loaded}
    amount={amount}
    fullyLoaded={fullyLoaded}
    onLoadMore={onLoadMore}
    className={className}
  />
);

const mapStateToProps = (state) => ({
  amount: selectFutureWebinarsAmount(state),
  webinars: selectFutureWebinars(state),
  loaded: selectLoadedFutureWebinars(state),
  fullyLoaded: selectFutureWebinarsLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreFutureWebinars,
};

export const FutureWebinars = connect(mapStateToProps, mapDispatchToProps)(FutureWebinarsComponent);
