import { connect } from 'react-redux';

import { validateText } from 'utils/formValidators';

import { selectInputWarningText, selectUserNameInputFields } from 'store/widgets';

import { FFInput } from 'components/Inputs';

const NameInputComponent = ({ inputProps, message, ...restProps }) => (
  <FFInput
    {...inputProps}
    {...restProps}
    validate={validateText}
    message={message}
  />
);

const mapStateToProps = (state) => ({
  inputProps: selectUserNameInputFields(state),
  message: selectInputWarningText(state),
});

export const NameInput = connect(mapStateToProps)(NameInputComponent);
