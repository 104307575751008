import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectShowMoreText } from 'store/widgets';

/* Hooks */
import { useShowMoreButton } from './useShowMoreButton';

/* Components */
import { SecondaryButtonOrLink } from 'components';
import Arrow from '../../images/arrow_mini.svg'

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ShowMoreComponent = ({ total, text, onClick, onFullyLoaded, className }) => {
  const { shouldDisplayButton, handleClick } = useShowMoreButton({ total, onClick, onFullyLoaded });

  return shouldDisplayButton && (
    <SecondaryButtonOrLink className={cx('component', className)} size="large" onClick={handleClick}>
      {text}
      <Arrow className={cx('arrow')} />
    </SecondaryButtonOrLink>
  );
};

const mapStateToProps = (state) => ({
  text: selectShowMoreText(state),
});

ShowMoreComponent.propTypes = {
  text: PropTypes.string,
  total: PropTypes.number,
  onClick: PropTypes.func,
  onFullyLoaded: PropTypes.func,
};

export const ShowMoreButton = connect(mapStateToProps)(ShowMoreComponent);
