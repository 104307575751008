import { createSelector } from '@reduxjs/toolkit';
import { selectCourseTeachers } from 'store/course/selectors';
import { selectCourseTexts } from 'store/widgets';

export const selectCoursePageTitle = (state) => selectCourseTexts(state).title;
export const selectCoursePageButton = (state) => selectCourseTexts(state).buttonText;

export const selectCoursePageSpeakersLabel = createSelector(
  [selectCourseTexts, selectCourseTeachers],
  (texts, teachers) => (teachers?.length > 1 ? texts?.teachers?.multiple : texts?.teachers?.single),
);