import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectMobile } from 'store/selectors';

/* Components */
import { PrimaryButtonOrLink, SecondaryButtonOrLink } from 'components';

/* Constants */
import { ANIMATION_ORIENTATION, BUTTON_SIZE } from 'constants/components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CardButtonComponent = ({
  href,
  routerLink,
  isMobile,
  isFuture,
  isLink,
  onClick,
  className,
  children,
  disabled,
}) => {
  const Button = isFuture ? PrimaryButtonOrLink : SecondaryButtonOrLink;

  return (
    <Button
      size={isMobile ? 'small' : 'medium'}
      className={cx('component', className)}
      arrow={{
        render: true,
        mode: 'normal',
      }}
      theme="light"
      isLink={isLink}
      onClick={onClick}
      disabled={disabled}
      href={href}
      routerLink={routerLink}
    >
      {children}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
});

CardButtonComponent.propTypes = {
  href: PropTypes.string,
  routerLink: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(BUTTON_SIZE),
  onClick: PropTypes.func,
};

export const CardButton = connect(mapStateToProps, null)(CardButtonComponent);
