import {
  formatConferenceDate,
  formatCourseDate,
  formatRuntime,
  formatWebinarDate,
  translate,
} from 'utils/format-string';
import { ACCESS_LEVELS, ACCESS_LEVELS_KEYS, MAIN_TAGS } from 'constants/constants';
import {TAG_TYPES} from "constants/components";

export const mapError = (error) => ({
  error: !!error?.error,
  errorType: error?.error,
  errorMessage: error?.errorMessage,
  errorCode: error?.errorCode,
});

const mapRelatedContent = (content, mapper) => {
  if (!content || !content.length) return null;

  return content.map((item) => mapper(item));
};

export const mapDocument = (document) => ({
  id: document.id,
  link: document.link,
  text: document.text,
  size: document.size,
});

const mapDocuments = (documents) => {
  if (!documents || !documents.length) return null;

  return documents.map((document) => mapDocument(document));
};

export const mapTag = (tag = {}) => ({
  id: tag.id,
  type: TAG_TYPES[tag.alias] ? tag.alias : 'default',
  text: tag.text,
  isHidden: tag.hide,
  weight: tag.weight,
});

export const mapTags = (tags) => {
  if (!tags || !tags?.length) return null;

  const mappedTags = tags.map((tag) => ({ ...mapTag(tag), isMain: !!MAIN_TAGS[tag.alias] }));
  const mainTag = mappedTags.find((tag) => tag.isMain);
  return [mainTag, ...mappedTags.filter((tag) => tag !== mainTag)].filter((tag) => !tag?.isHidden);
};

export const mapBanner = (data = {}) => ({
  alias: data?.alias,
  action: data?.type,
  title: data?.name,
  description: data?.text,
  label: data?.description,
  image: data?.picUrl,
  buttonText: data?.buttonText,
  link: data?.url ? {
    url: data.url,
    isExternal: data?.isExternal,
  } : null,
  eventId: data?.eventId,
  accessStatus: ACCESS_LEVELS_KEYS[data?.auth],
});

export const mapBanners = (banners) => {
  if (!banners || !banners.length) return null;

  return banners.map((banner) => mapBanner(banner));
};

export const filterBanners = (banners, isAuthorized) => {
  if (!banners) return null;

  return banners.filter(({ accessStatus }) => accessStatus === ACCESS_LEVELS.unlimited || (isAuthorized ? accessStatus === ACCESS_LEVELS.authOnly : accessStatus === ACCESS_LEVELS.nonAuthOnly));
};

export const mapArticle = (data = {}) => ({
  id: data?.id,
  alias: data?.alias,
  title: data?.name,
  description: data?.description,
  content: data?.text,
  tags: mapTags(data?.tags),
  backgroundImage: data?.pageBackgroundPicUrl,
  cardImage: data?.cardBannerPicUrl,
  readTime: data?.readTime ? `${data?.readTime} ${translate('minutes', data?.readTime)}` : null,
  views: data?.readCount || null,
  banners: mapBanners(data?.bannerRefs),
  relatedContent: mapRelatedContent(data?.relatedContent, mapArticle),
});

export const mapSpeaker = (data = {}) => ({
  id: data?.id,
  name: data?.name,
  info: data?.description,
  description: data?.summary,
  image: data?.picUrl,
});

const mapSpeakers = (speakers) => {
  if (!speakers || !speakers.length) return null;

  return speakers.map((speaker) => mapSpeaker(speaker));
};

export const mapPartner = (data = {}) => ({
  id: data?.name,
  name: data?.name,
  image: data?.image,
});

const mapPartners = (partners) => {
  if (!partners || !partners.length) return null;

  return partners.map((partner) => mapPartner(partner));
};

export const mapLecture = (data = {}) => ({
  id: data?.id,
  alias: data?.alias,
  title: data?.name,
  description: data?.description,
  content: data?.text,
  tags: mapTags(data?.tags),
  image: data?.picUrl,
  video: data?.videoUrl,
  views: data?.readCount || null,
  videoLength: formatRuntime(data?.duration),
  isExternal: data?.source !== 'internal',
  authors: mapSpeakers(data?.speakers),
  partners: mapPartners(data?.partners),
  banners: mapBanners(data?.bannerRefs),
  relatedContent: mapRelatedContent(data?.relatedContent, mapLecture),
});

export const mapCourse = (data = {}) => ({
  id: data?.id,
  alias: data?.alias,
  title: data?.name,
  description: data?.text,
  content: data?.content,
  tags: mapTags(data?.tags),
  dateFrom: formatCourseDate(data?.dateFrom, data?.dateTo),
  backgroundImage: data?.pageBackgroundPicUrl,
  cardImage: data?.cardBannerPicUrl,
  video: data?.videoUrl,
  scorm: data?.scorm,
  placeholder: data?.placeholder,
  isVertical: data?.isVertical,
  views: data?.readCount || null,
  videoLength: formatRuntime(data?.duration),
  teachers: mapSpeakers(data?.speakers),
  documents: mapDocuments(data?.documents),
  banners: mapBanners(data?.banners),
  relatedContent: mapRelatedContent(data?.relatedContent, mapCourse),
});

export const mapWebinar = (data = {}) => ({
  id: data?.id,
  alias: data?.alias,
  title: data?.name,
  theme: data?.description,
  content: data?.text,
  tags: mapTags(data?.tags),
  image: data?.picUrl,
  video: data?.videoUrl,
  isExternal: data?.source !== 'internal',
  date: formatWebinarDate(data?.date),
  isFuture: new Date(data?.date) > Date.now(),
  placesLeft: data?.places || null,
  registered: data?.registered || null,
  videoLength: formatRuntime(data?.length),
  authors: mapSpeakers(data?.speakers),
  banners: mapBanners(data?.bannerRefs),
  relatedContent: mapRelatedContent(data?.relatedContent, mapWebinar),
});

export const mapConference = (data = {}) => ({
  id: data?.id,
  alias: data?.alias,
  title: data?.name,
  description: data?.description,
  place: data?.location,
  date: formatConferenceDate(data?.dateFrom, data?.dateTo),
  isOnline: data?.online,
  image: data?.picUrl,
  placesLeft: data?.places || null,
  registered: data?.registered || null,
  content: data?.text,
  tags: mapTags(data?.tags),
});

export const mapEvent = (event) => ({
  id: event?.id,
  name: event?.name,
  description: event?.summary,
  date: event?.date,
  tags: mapTags(event?.tags),
  isOffline: event?.isOffline,
});

export const mapEvents = (events) => {
  if (!events || !events.length) return null;

  return events.map((event) => mapEvent(event));
};

export const mapUser = (data = {}) => ({
  id: data?.userId,
  phoneNumber: data?.ctn,
  position: data?.job,
  events: mapEvents(data?.events),
  mail: data?.emailSendingEnabled,
  emailConfirmed: data?.emailConfirmed,
  name: data?.name,
  avatar: data?.avatar,
  email: data?.email,
  company: data?.company,
  telegram: data?.telegram,
  town: data?.town,
});

export const unMapUser = (data = {}) => ({
  userId: data?.id,
  ctn: data?.phoneNumber,
  job: data?.position,
  emailSendingEnabled: data?.mail,
  emailConfirmed: data?.emailConfirmed,
  userName: data?.name,
  avatar: data?.avatar,
  email: data?.email,
  company: data?.company,
  telegram: data?.telegram,
  town: data?.town,
});

export const mapProfileData = (data = {}) => ({
  userName: data.name,
  company: data.company,
  email: data.email,
  phone: data.phone,
});

export const mapSeries = (data) => ({
  alias: data?.alias,
  title: data?.name,
  description: data?.description,
  image: data?.picUrl,
  tags: mapTags(data?.tags),
  speakers: mapSpeakers(data?.speakers),
});

export const mapWebinarSeries = (data) => ({
  ...mapSeries(data),
  type: 'series',
  series: data?.webinars?.map((series) => mapWebinar(series)),
});

export const mapCourseSeries = (data) => ({
  ...mapSeries(data),
  type: 'series',
  series: data?.courses?.map((series) => mapCourse(series)),
});

export const mapWebinars = (content) => content?.reduce((acc, item) => {
  acc.push({ ...item, type: 'webinar' });
  return acc;
}, []);
