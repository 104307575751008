import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const POSITIONS = {
  before: 'before',
  after: 'after',
};

const Icon = ({ icon, className }) => (
  <div className={cx('icon', className)}>
    {icon}
  </div>
);

export const withIcon = (WrappedComponent) => ({ icon, position = POSITIONS.after, className, ...restProps }) => (
  <WrappedComponent className={cx('component', className)} {...restProps}>
    {icon && <Icon className={cx(position)} icon={icon} />}
  </WrappedComponent>
);
