export const PHONE_CONFIRMATION_ERRORS = {
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  TOO_MANY_CODE_ATTEMPTS: 'TOO_MANY_CODE_ATTEMPTS',
  INCORRECT_CODE: 'INCORRECT_CODE',
  INVALID_CODE: 'INVALID_CODE',
  ALREADY_CONFIRMED: 'ALREADY_CONFIRMED',
};

export const ALREADY_REGISTERED = 'ALREADY_REGISTERED';

export const NOT_REGISTERED = 'NOT_REGISTERED';

export const TOKEN_ERRORS = {
  INCORRECT_AUTH_TOKEN: 'INCORRECT_AUTH_TOKEN',
  INVALID_AUTH_TOKEN: 'INVALID_AUTH_TOKEN',
};

export const TOO_MANY_RETRIES = 'AUTH_TOO_MANY_RETRIES';

export const MOBILE_ID = {
  UNSUPPORTED_OPERATOR: 'UNSUPPORTED_OPERATOR',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  MOBILEID_NEEDS_OTP: 'MOBILEID_NEEDS_OTP',
};
