import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectLecturePageTitle } from 'store/widgets';

/* Components */
import { PageTemplate } from '../PageTemplate';
import { LecturesBlock } from 'pages/ConnectedContent';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LecturesComponent = ({ title }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/lecture_page_banner.png"
    backgroundClassName={cx('background')}
  >
    <LecturesBlock className={cx('lectures')} />
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectLecturePageTitle(state),
});

LecturesComponent.propTypes = {
  title: PropTypes.string,
};

export const Lectures = connect(mapStateToProps, null)(LecturesComponent);
