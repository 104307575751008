import { useEffect, useState } from 'react';

let timerId = null;

export const useTimer = (timeout, additionalMessage) => {
  const [time, setTime] = useState(timeout);
  const messageTime = new Date(time * 100);

  const seconds = () => {
    const timeSeconds = messageTime.getSeconds();

    return timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds;
  };

  const timeMessage = time === 0
    ? additionalMessage || ''
    : `Осталось: ${messageTime.getMinutes() > 0 ? messageTime.getMinutes() : '0'}:${seconds()}`;

  useEffect(() => {
    if (time > 0) {
      timerId = setTimeout(() => {
        setTime(time - 10);
      }, 1000);
    }
  }, [time]);

  const resetTime = () => setTime(timeout);

  useEffect(() => {
    if (time <= 0) {
      resetTime();

      return () => clearTimeout(timerId);
    }
  }, []);

  return { timeMessage, resetTime, time };
};
