import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';

import { resetResults, setSearchQuery } from 'store/search';

import { Field } from 'react-final-form';
import { SubmitButton } from '../SubmitButton';
import { CancelButton } from '../CancelButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const InputComponent = ({
  renderCancelButton,
  renderSubmitButton,
  validate,
  onClick,
  onChange,
  onSetSearchQuery,
  onResetResults,
  className,
  inputClassName,
  children,
}) => {
  const history = useHistory();

  return (
    <Field
      id="search"
      name="search"
      validate={validate}
      render={({ input, meta: { valid, modified } }) => {
        const handleChange = (e) => {
          input.onChange(e.target.value);
          onChange(e.target.value);

          if (!e.target.value) {
            onResetResults();
          }
        };

        const resetInput = () => {
          input.onChange('');
          onSetSearchQuery?.('');
          onChange('');
          onResetResults();
          history.replace({ search: '' });
        };

        const handleBlur = (e) => {
          input.onBlur();

          if (modified) {
            onSetSearchQuery?.(e.target.value);
            if (!valid) {
              onResetResults();
            }
          }
        };

        return (
          <div className={cx('container', className)}>
            <input
              className={cx('input', inputClassName)}
              {...input}
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Поиск"
            />

            {!!input.value && renderCancelButton && <CancelButton className={cx('cancel')} onClick={resetInput} />}

            {valid ? (
              <>
                {renderSubmitButton && <SubmitButton query={input.value} onClick={onClick} />}
                {children}
              </>
            ) : null}
          </div>
        );
      }}
    />
  );
};

const mapDispatchToProps = {
  onSetSearchQuery: setSearchQuery,
  onResetResults: resetResults,
};

export const Input = connect(null, mapDispatchToProps)(InputComponent);
