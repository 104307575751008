import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardCreators = ({ creators, className }) => {
  if (!creators || !creators.length) return null;

  return (
    <div className={cx('component', className)}>
      {creators.map(({ image }) => (
        <div className={cx('image')} style={{ backgroundImage: `url("${image}")` }} />
      ))}
    </div>
  );
};

CardCreators.defaultProps = {
  creators: [],
};

CardCreators.propTypes = {
  creators: PropTypes.array,
};
