import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

import { SecondaryButtonOrLink } from 'components';
import Edit from '../../../../images/edit.svg';

export const EditButton = ({ disabled, onClick, className }) => (
  <SecondaryButtonOrLink
    theme="light"
    className={cx('component', { disabled }, className)}
    onClick={onClick}
    disabled={disabled}
  >
    <Edit />
  </SecondaryButtonOrLink>
);

EditButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
