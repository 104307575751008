import axios from 'axios';
import endpoints from 'api/endpoints';
import { mappers } from 'utils/mappers';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';

export const requestMoreArticles = (pageNumber) => axios.get(endpoints.requestArticles, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  },
}).then(({ data }) => mappers.articles(data));

export const requestMoreCourses = (pageNumber) => axios.get(endpoints.requestCourses, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  },
}).then(({ data }) => mappers.courses(data));

export const requestMoreLectures = (pageNumber) => axios.get(endpoints.requestLectures, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  },
}).then(({ data }) => mappers.lectures(data));

export const requestMoreConferences = (pageNumber, isFuture = false) => axios.get(endpoints.requestConferences, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
    isFuture,
  },
}).then(({ data }) => (isFuture ? mappers.futureConferences(data) : mappers.pastConferences(data)));

export const requestMoreWebinars = (pageNumber, isFuture = false) => axios.get(endpoints.requestWebinars, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
    isFuture,
  },
}).then(({ data }) => (isFuture ? mappers.futureWebinars(data) : mappers.pastWebinars(data)));

export const requestMoreCourseSeries = (pageNumber) => axios.get(endpoints.requestCourseSeriesList, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  },
}).then(({ data }) => mappers.courseSeriesList(data));

export const requestMoreWebinarSeries = (pageNumber) => axios.get(endpoints.requestWebinarSeriesList, {
  params: {
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  },
}).then(({ data }) => mappers.webinarSeriesList(data));
