import { useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { selectMobile } from 'store/selectors';

import { IFrame } from 'components';

import { useIFrame } from 'hooks/useIFrame';
import { useFixedSize } from '../useFixedSize';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Vertical = ({ src }) => {
  if (typeof window === 'undefined') return null;

  const containerRef = useRef();
  const iframeRef = useRef();
  const notMobile = !useSelector(selectMobile);
  const { handleIFrame } = useIFrame();

  const fixedSizes = {
    width: 800,
    height: 1426,
  };

  const a = useFixedSize(containerRef, iframeRef, fixedSizes);

  return (
    <div
      className={cx('scorm')}
      ref={containerRef}
      style={{
        height: `${notMobile ? fixedSizes.height * a?.scale : a?.height}px`,
        width: `${notMobile ? fixedSizes.width * 0.4562 : window?.innerWidth - 40}px`,
      }}
      onClick={handleIFrame}
    >
      <IFrame iframeRef={iframeRef} src={src} className={cx('iframe')} />
    </div>
  );
};