import classNames from 'classnames/bind';

import { Heading } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Title = ({ children, className }) => (
  <Heading level={1} className={cx('component', className)}>
    {children}
  </Heading>
);
