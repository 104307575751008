import classNames from 'classnames/bind';

import styles from './styles.pcss';

import { Text } from 'components';

const cx = classNames.bind(styles);

export const Label = ({ children, className }) => (
  <Text className={cx('component', className)}>
    {children}
  </Text>
);
