import classNames from 'classnames/bind';

import { AccordionContent } from './components/AccordionContent';
import { AccordionTab } from './components/AccordionTab';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Accordion = ({ children, className }) => (
  <ol className={cx('component', className)}>
    {children}
  </ol>
);

Accordion.Content = AccordionContent;
Accordion.Tab = AccordionTab;
