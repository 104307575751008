import { createAction } from '@reduxjs/toolkit';

export const setContent = createAction('SET_CONTENT');
export const setArticles = createAction('SET_ARTICLES');
export const setLectures = createAction('SET_LECTURES');
export const setCourses = createAction('SET_COURSES');
export const setFutureWebinars = createAction('SET_FUTURE_WEBINARS');
export const setPastWebinars = createAction('SET_PAST_WEBINARS');
export const setFutureConferences = createAction('SET_FUTURE_CONFERENCES');
export const setPastConferences = createAction('SET_PAST_CONFERENCES');
export const setCoursesSeries = createAction('SET_COURSES_SERIES');
export const setWebinarsSeries = createAction('SET_WEBINARS_SERIES');
