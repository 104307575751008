import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ShowMoreButton, WebinarRow } from 'components';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

export const Template = ({
  content,
  loaded,
  amount,
  fullyLoaded,
  onLoadMore,
  className,
}) => {
  if (!content || !content?.length) return null;

  return (
    <div className={cx(className)}>
      <WebinarRow
        content={loaded?.length > 0 ? [...content, ...loaded] : content}
        shouldRenderInfo
      />

      {!fullyLoaded && useMemo(() => (
        <ShowMoreButton total={amount} onClick={onLoadMore} />
      ), [onLoadMore])}
    </div>
  );
};

Template.propTypes = {
  amount: PropTypes.number,
  content: PropTypes.array,
  loaded: PropTypes.array,
  onLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
};
