import { useDispatch } from 'react-redux';

import { setAuthorized } from 'store/auth/actions';
import { deleteChanges, resetUserData } from 'store/user';
import { changePopupStep, setResultStatus, handleAttemptsError } from 'store/page/actions';
import { getUser, handleTokenError } from 'store/user/actions';

import { TOKEN_ERRORS, TOO_MANY_RETRIES } from 'constants/authorizationErrors';
import { POPUP_STEP, RESULT_STATUS, PROFILE_UPDATE_ERRORS } from 'constants/constants';

import { refreshAccessToken } from 'utils/refreshAccessToken';
import { deleteCookie } from 'utils/cookie';

import { updateProfile } from 'api/requests';
import { unMapUser } from 'api/dataMapper';

const useErrorPopup = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(setResultStatus(RESULT_STATUS.error));
    changePopupStep(POPUP_STEP.ready);
  };
};

export const useMakeRequest = () => {
  const dispatch = useDispatch();
  const showErrorPopup = useErrorPopup();

  const makeRequest = async (request, payload, attemptsLeft = 3) => {
    if (attemptsLeft <= 0) {
      deleteCookie('token');
      deleteCookie('refresh');
      dispatch(setAuthorized(false));
      dispatch(resetUserData());
      showErrorPopup();
      throw Error(TOO_MANY_RETRIES);
    }

    try {
      return await request(payload);
    } catch (err) {
      const { error } = err.response?.data || {};

      if (TOKEN_ERRORS[error]) {
        try {
          await refreshAccessToken();
        } catch (innerErr) {
          throw new Error(innerErr?.response?.data);
        }

        return makeRequest(request, payload, attemptsLeft - 1);
      }

      showErrorPopup();

      throw err;
    }
  };

  return makeRequest;
};

export const useProfileUpdate = () => {
  const dispatch = useDispatch();
  const makeRequest = useMakeRequest();
  const showErrorPopup = useErrorPopup();

  return (data, onSuccess) => makeRequest(() => updateProfile(unMapUser(data)))
    .then(() => onSuccess())
    .then(() => {
      dispatch(getUser());
      dispatch(deleteChanges());
    })
    .catch(({ response }) => {
      const { error, errorMessage } = response?.data || {};

      if (error === PROFILE_UPDATE_ERRORS.TOO_MANY_ATTEMPTS) {
        dispatch(handleAttemptsError(error, errorMessage));
        return;
      }

      dispatch(handleTokenError(response?.data));

      showErrorPopup();
    });
};
