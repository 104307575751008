import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState } from 'store/selectors';

export const textsSelector = (state) => getGlobalState(state).texts;

export const selectMainTexts = createSelector(textsSelector, (state) => state.main || {});
export const selectFeatureToggle = createSelector(textsSelector, (state) => state.featureToggleSettings || {});
export const selectAuthPopupTexts = createSelector(textsSelector, (state) => state.auth || {});
export const selectWebinarPageTexts = createSelector(textsSelector, (state) => state.page_webinar || {});
export const selectArticlePageTexts = createSelector(textsSelector, (state) => state.page_article || {});
export const selectLectureTexts = createSelector(textsSelector, (state) => state.page_lecture || {});
export const selectConferenceTexts = createSelector(textsSelector, (state) => state.page_conference || {});
export const selectCourseTexts = createSelector(textsSelector, (state) => state.page_course || {});
export const selectErrorPage = createSelector(selectMainTexts, (state) => state.errorPage || {});
export const selectFAQ = createSelector(textsSelector, (state) => state.faq || {});
export const selectEmailFormBanner = createSelector(selectMainTexts, (state) => state.emailForm || {});
export const selectUserAccount = createSelector(textsSelector, (state) => state.userAccount || {});
export const selectChangeEmailTitle = createSelector(selectUserAccount, (state) => state?.changeEmail);
export const selectChangePhoneTitle = createSelector(selectUserAccount, (state) => state?.changePhone);
export const selectContinueButton = createSelector(selectMainTexts, (state) => state?.continue);
export const selectEmailConfirmText = createSelector(selectMainTexts, (state) => state?.emailConfirm);

// EventSub Result Screens
export const selectEventSubScreens = (state) => selectMainTexts(state)?.eventSubScreens;

