import classNames from 'classnames/bind';
import { ButtonOrLink } from 'components';
import Arrow from '../../../../../images/arrow_mini.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Button = ({ isMobile, onClick, className }) => (
  <ButtonOrLink
    className={cx('button')}
    isRound
    mode={isMobile ? 'black' : 'secondary'}
    {...(isMobile && onClick)}
    animation={{ animated: false }}
  >
    <Arrow className={cx('arrow', className)} />
  </ButtonOrLink>
);
