import { connect } from 'react-redux';

/* Store */
import { selectPastConferences, selectPastConferencesAmount, } from 'store/content/selectors';
import {
  loadMorePastConferences,
  selectLoadedPastConferences,
  selectPastConferencesLoadedFlag,
} from 'store/pagination';

/* Components */
import { Template } from './Template';
import { PastEventsLabel } from 'components';

const PastConferencesComponent = ({ conferences, loaded, amount, onLoadMore, displayLabel, fullyLoaded }) => {
  if (!conferences || !conferences?.length) return null;

  return (
    <>
      {displayLabel && <PastEventsLabel amount={amount} />}

      <Template
        content={conferences}
        loaded={loaded}
        amount={amount}
        fullyLoaded={fullyLoaded}
        onLoadMore={onLoadMore}
      />
    </>
  )
};

const mapStateToProps = (state) => ({
  amount: selectPastConferencesAmount(state),
  conferences: selectPastConferences(state),
  loaded: selectLoadedPastConferences(state),
  fullyLoaded: selectPastConferencesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMorePastConferences,
};

export const PastConferences = connect(mapStateToProps, mapDispatchToProps)(PastConferencesComponent);
