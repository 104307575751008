import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ShowMoreButton, ConferencesRow } from 'components';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

export const Template = ({ content, loaded, amount, fullyLoaded, onLoadMore, className }) => {
  if (!content || !content?.length) return null;

  return (
    <div className={cx(className)}>
      <ConferencesRow conferences={loaded?.length > 0 ? [...content, ...loaded] : content} />

      {!fullyLoaded && useMemo(() => (
        <ShowMoreButton total={amount} onClick={onLoadMore} />
      ), [onLoadMore])}
    </div>
  );
};

Template.propTypes = {
  content: PropTypes.array,
  loaded: PropTypes.array,
  amount: PropTypes.number,
  onLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
};
