import { createReducer } from '@reduxjs/toolkit';

import {
  setContent,
  setArticles,
  setCourses,
  setLectures,
  setCoursesSeries,
  setWebinarsSeries,
  setFutureWebinars,
  setPastWebinars,
  setFutureConferences,
  setPastConferences,
} from 'store/content';

export const content = createReducer({
  articles: {
    total: 0,
    data: [],
  },
  courses: {
    total: 0,
    data: [],
  },
  lectures: {
    total: 0,
    data: [],
  },
  pastWebinars: {
    total: 0,
    data: [],
  },
  futureWebinars: {
    total: 0,
    data: [],
  },
  pastConferences: {
    total: 0,
    data: [],
  },
  futureConferences: {
    total: 0,
    data: [],
  },
  courseSeriesList: {
    total: 0,
    series: [],
  },
  webinarSeriesList: {
    total: 0,
    series: [],
  },
}, {
  [setContent.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [setArticles.type]: (state, action) => ({
    ...state,
    articles: action.payload,
  }),
  [setCourses.type]: (state, action) => ({
    ...state,
    courses: action.payload,
  }),
  [setLectures.type]: (state, action) => ({
    ...state,
    lectures: action.payload,
  }),
  [setFutureWebinars.type]: (state, action) => ({
    ...state,
    futureWebinars: action.payload,
  }),
  [setPastWebinars.type]: (state, action) => ({
    ...state,
    pastWebinars: action.payload,
  }),
  [setCoursesSeries.type]: (state, action) => ({
    ...state,
    courseSeriesList: action.payload,
  }),
  [setWebinarsSeries.type]: (state, action) => ({
    ...state,
    webinarSeriesList: action.payload,
  }),
  [setFutureConferences.type]: (state, action) => ({
    ...state,
    futureConferences: action.payload,
  }),
  [setPastConferences.type]: (state, action) => ({
    ...state,
    pastConferences: action.payload,
  }),
});
