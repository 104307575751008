import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectSearchPageTitle } from 'store/widgets';

/* Components */
import { Heading, MenuButton, Wrapper } from 'components';
import { FForm } from 'components/FForm';
import { Input, Results } from 'components/SearchPanel/components';

/* Hooks */
import { useLocation } from 'react-router-dom';
import { useSearch } from 'hooks/useSearch';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SearchPageComponent = ({ title, isDesktop }) => {
  const location = useLocation();
  const { handleValidation, handleChange, isLoading } = useSearch();
  const initialQuery = useMemo(() => (location.search
    ? decodeURIComponent(location.search.substring(location.search.indexOf('=') + 1))
    : null), [location]);

  useEffect(() => {
    if (initialQuery) {
      handleChange(initialQuery);
    }
  }, []);

  return (
    <Wrapper className={cx('wrapper')} width={1472}>
      <Heading level={1} className={cx('title')}>
        {title}
      </Heading>

      <FForm initialValues={{ search: initialQuery }} className={cx('container')}>
        <Input
          className={cx('inputContainer')}
          inputClassName={cx('input')}
          validate={handleValidation}
          onChange={handleChange}
          renderCancelButton
        >
          <Results className={cx('results')} isLoading={isLoading} />
        </Input>
      </FForm>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  title: selectSearchPageTitle(state),
  isDesktop: selectDesktop(state),
});

SearchPageComponent.propTypes = {
  title: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export const Search = connect(mapStateToProps)(SearchPageComponent);
