import React from 'react';

import styles from './styles.pcss';

export const FromBottomSlider = ({ children, active }) => (
  <>
    {React.Children.map(children, (child) => (
      child.props.id === active ? child : null
    ))}
  </>
);

const Slide = ({ children }) => (
  <div className={styles.slide}>
    {children}
  </div>
);

FromBottomSlider.Slide = Slide;
