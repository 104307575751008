import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectConferencePageTitle } from 'store/widgets';

/* Components */
import { FutureConferences, PastConferences } from 'pages/ConnectedContent';
import { PageTemplate } from '../PageTemplate';

import styles from '../styles.pcss';
const cx = classNames.bind(styles);

const ConferencesComponent = ({ title }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/conferences_page_banner.png"
  >
    <FutureConferences className={cx('futureBlock')} />

    <PastConferences displayLabel />
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectConferencePageTitle(state),
});

ConferencesComponent.propTypes = {
  title: PropTypes.string,
};

export const Conferences = connect(mapStateToProps, null)(ConferencesComponent);
