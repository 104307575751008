import PropTypes from 'prop-types';

import { SecondaryButtonOrLink } from 'components';

export const CancelButton = ({ onClick, isMobile, children, className }) => (
  <SecondaryButtonOrLink
    className={className}
    size={isMobile ? 'small' : 'large'}
    onClick={onClick}
  >
    {children}
  </SecondaryButtonOrLink>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};
