export const TAG_COLORS = {
  RED: 'red',
  GREEN: 'green',
  WHITE: 'white',
  ORANGE: 'orange',
  PURPLE: 'purple',
  BLUE: 'blue',
  GREY: 'grey',
};

export const TAG_SIZES = ['small', 'medium', 'large'];

export const TAG_TYPES = {
  default: {
    color: TAG_COLORS.WHITE,
  },
  article: {
    name: 'Статья',
    color: TAG_COLORS.BLUE,
  },
  lecture: {
    name: 'Видеолекция',
    color: TAG_COLORS.RED,
  },
  lecturesList: {
    name: 'Серия видеолекций',
    color: TAG_COLORS.RED,
  },
  course: {
    name: 'Курс',
    color: TAG_COLORS.PURPLE,
  },
  courseList: {
    name: 'Серия курсов',
    color: TAG_COLORS.PURPLE,
  },
  webinar: {
    name: 'Вебинар',
    color: TAG_COLORS.ORANGE,
  },
  webinarList: {
    name: 'Серия вебинаров',
    color: TAG_COLORS.ORANGE,
  },
  conference: {
    name: 'Конференция',
    color: TAG_COLORS.GREEN,
  },
};

export const BUTTON_SIZE = ['small', 'medium', 'large'];

export const ANIMATION_ORIENTATION = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

export const PANELS = {
  globalNavigation: 'globalNavigation',
  pageNavigation: 'pageNavigation',
};

export const SEARCH_PANEL_MODES = {
  search: 'search',
  cancel: 'cancel',
};
