import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { SEARCH_PANEL_MODES } from 'constants/components';

import { selectSearchQuery } from 'store/search';

import { BlackButtonOrLink, Text } from 'components';
import SearchIcon from '../../../../images/searchIcon.svg';
import Cross from '../../../../images/close.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ButtonComponent = ({ query, mode, onClick, className, style }) => (
  <BlackButtonOrLink
    className={(cx('button', query && 'withQuery', mode, className))}
    isRound
    onClick={onClick}
    style={style}
    animation={{ animated: false }}
  >
    {mode === SEARCH_PANEL_MODES.search ? (
      <>
        <SearchIcon className={cx('icon')} />
        {query && (
          <Text className={cx('query')}>
            {query}
          </Text>
        )}
      </>
    ) : <Cross className={cx('icon')} />}
  </BlackButtonOrLink>
);

const mapStateToProps = (state) => ({
  query: selectSearchQuery(state),
});

export const Button = connect(mapStateToProps)(ButtonComponent);
