import { useSpring } from 'react-spring';
import { useHover } from 'react-use-gesture';
import { useMemo } from 'react';

import PDF from '../../images/pdf.svg';
import Success from '../../images/pdf_success.svg';

export const useAnimation = ({ downloaded, setDownloaded }) => {
  const [container, setContainer] = useSpring(() => ({
    borderColor: '#333',
    opacity: 1,
    config: {
      duration: 250,
    },
  }));

  const [iconContainer, setIcon] = useSpring(() => ({
    backgroundColor: '#333',
    config: {
      duration: 250,
    },
  }));

  const icon = useMemo(() => (downloaded ? <Success /> : <PDF />), [downloaded]);

  const handleHover = useHover(({ hovering }) => {
    setContainer.start({ borderColor: hovering ? '#505050' : '#333' });
    setIcon.start({ backgroundColor: hovering ? '#333638' : '#2f3133' });
  });

  const handleClick = () => setDownloaded(true);

  const handleMouseDown = () => {
    setContainer.start({ opacity: 0.8 });
  };

  const handleMouseUp = () => {
    setContainer.start({ opacity: 1 });
  };

  const handleInteraction = ({
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onClick: handleClick,
  });

  return {
    container,
    iconContainer,
    icon,
    handleHover,
    handleInteraction,
  };
};