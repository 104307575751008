import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Tag } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardTags = ({ tags, onlyMainTag = true, className }) => {
  if (!tags?.length) return null;

  const mainTag = tags[0] || {};

  if (onlyMainTag) {
    return (
      <Tag
        key={mainTag?.id}
        className={cx('tag')}
        size="medium"
        type={mainTag?.type}
        text={mainTag?.text}
      />
    );
  }

  return (
    <div className={cx('component', className)}>
      {tags.map((tag) => (
        <Tag
          key={tag?.id}
          className={cx('tag')}
          size="medium"
          type={tag?.type}
          text={tag?.text}
        />
      ))}
    </div>
  );
};

CardTags.defaultProps = {
  tags: [],
};

CardTags.propTypes = {
  tags: PropTypes.array,
  onlyMainTag: PropTypes.bool,
};
