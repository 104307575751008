import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';

/* Constants */
import { PANELS } from 'constants/components';

/* Components */
import {
  Wrapper,
  MenuButton,
  DeskNavigation as Navigation, FloatingInfoPanel,
} from 'components';
import { PageHeader } from 'components/PageHeader';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PageTemplateComponent = ({ isDesktop, title, image, children, backgroundClassName }) => {
  const container = useRef(null);

  return (
    <>
      <PageHeader
        className={cx('header')}
        image={(
          <PageHeader.Image
            className={cx('background', backgroundClassName)}
            src={image}
            absolute
          />
        )}
        title={(
          <PageHeader.Title className={cx('title')}>
            {title}
          </PageHeader.Title>
        )}
      />
      <div ref={container}>
        <Wrapper className={cx('container')} fullWidth={!isDesktop} width={1472}>
          <Navigation className={cx('navigation')} />

          {children}
        </Wrapper>
      </div>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}

      <FloatingInfoPanel container={container} defaultPanel={PANELS.globalNavigation} transparent />
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
});

PageTemplateComponent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export const PageTemplate = connect(mapStateToProps, null)(PageTemplateComponent);
