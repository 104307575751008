import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSendEmailsInput } from 'store/widgets';

export const useMailingInput = (type) => {
  const texts = useSelector(selectSendEmailsInput);
  const [isActive, setActive] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);

  const handleChange = () => {
    setDisplayButton(true);
  };

  const handleFocus = () => {
    setActive(true)
  };

  const handleBlur = (e, modified) => {
    const prefix = texts?.[type]?.prefix;

    if (e.target.value === '' || e.target.value === prefix || !modified) {
      setActive(false);
      setDisplayButton(false);
    }
  };

  return {
    isActive,
    displayButton,
    handleBlur,
    handleFocus,
    handleChange,
  }
}
