import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Benefit, Text } from 'components';

/* Icons */
import Calendar from '../../../../images/calendar.svg';
import Map from '../../../../images/map_pin.svg';
import Person from '../../../../images/person.svg';
import Clock from '../../../../images/clock.svg';
import Views from '../../../../images/views.svg';
import Workshop from '../../../../images/workshop.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardInfo = ({
  date,
  time,
  place,
  participants,
  runTime,
  views,
  workshops,
  webinars,
  className,
  itemClassName,
  textClassName,
}) => (
  <div className={cx('component', className)}>
    {date && (
      <Benefit icon={<Calendar />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {date}
          {' '}
          {time}
        </Text>
      </Benefit>
    )}

    {place && (
      <Benefit icon={<Map />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {place}
        </Text>
      </Benefit>
    )}

    {participants && (
      <Benefit icon={<Person />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {participants}
        </Text>
      </Benefit>
    )}

    {runTime && (
      <Benefit icon={<Clock />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {runTime}
        </Text>
      </Benefit>
    )}

    {views && (
      <Benefit icon={<Views />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {views}
        </Text>
      </Benefit>
    )}

    {workshops && (
      <Benefit icon={<Workshop />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {workshops}
        </Text>
      </Benefit>
    )}

    {webinars && (
      <Benefit icon={<Person />} className={cx('item', itemClassName)}>
        <Text className={textClassName}>
          {webinars}
        </Text>
      </Benefit>
    )}
  </div>
);

CardInfo.propTypes = {
  date: PropTypes.string,
  place: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  runTime: PropTypes.string,
  workshops: PropTypes.string,
  webinars: PropTypes.string,
  participants: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  views: PropTypes.number,
};
