import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './styles.pcss';

// Utils
import { compose } from 'utils/fn';
import routes from '../routes';
import endpoints, { ContentEndpoint } from 'api/endpoints';
import { mapMeta } from 'utils/mappers';

/* Store */
import { rootReducer } from '../rootReducer';
import { initializeStore } from 'store';
import { breakpoints, setBreakpoint } from 'store/breakpoint';
import { selectOverlayState } from 'store/page/selectors';
import { selectAuthSetting, selectSearchSetting } from 'store/widgets';

/* Hocs */
import {
  withReduxStore,
  withAdaptivity,
  withReactRouter,
  withDataLoading,
  withBackendData,
} from 'hocs';

/* Pages & Components */
import { ErrorPage } from 'pages/Error';
import {
  AuthPopup,
  WrappedNavBar,
  EventSubscriptionPopup,
  PersonalAccountPopup,
  Footer,
  Overlay,
  Meta,
} from 'components';

/* Hooks */
import { useEmailConfirmed } from 'hooks/useEmailConfirmed';

const cx = classNames.bind(styles);

const App = ({ withOverlay, hideSearch, hideAuth, meta }) => {
  const isErrorPage = !!useRouteMatch('/error');
  const isMenu = !!useRouteMatch('/menu');
  const isSearchPage = !!useRouteMatch('/searchResults');
  useEmailConfirmed();

  return (
    <>
      <Meta meta={mapMeta(meta)} title={meta?.title} canonical={meta?.canonical} />

      {withOverlay && <Overlay />}
      <div className={cx('container')}>
        {!isErrorPage && (
          <WrappedNavBar
            className={cx('navBar')}
            hideSearch={hideSearch || isSearchPage || isMenu}
            hideAuth={hideAuth || isMenu}
          />
        )}
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route
            path="/error"
            render={() => (
              <ErrorPage errorCode={404} />
            )}
          />
          <Redirect from="/*" to="/error" />
        </Switch>
      </div>
      <AuthPopup />
      <PersonalAccountPopup />
      <EventSubscriptionPopup />

      {!isErrorPage && !isMenu && <Footer />}
    </>
  );
};

const mapStateToProps = (state) => ({
  withOverlay: selectOverlayState(state),
  hideAuth: selectAuthSetting(state),
  hideSearch: selectSearchSetting(state),
});

// Fallback на случай непроставленной в QP меты
App.defaultProps = {
  meta: {
    title: 'BEELINE BUSINESS LAB - бизнес-портал для тех, у кого нет времени на «воду»',
    description: `BEELINE BUSINESS LAB · Обучение и общение для управленцев малого и среднего бизнеса
     · Вебинары, дискуссии и разборы топовых кейсов на одном портале 
     · Эксперименты и технологии завтрашнего дня для устойчивого роста`,
  },
};

App.propTypes = {
  meta: PropTypes.object,
  withOverlay: PropTypes.bool,
  hideAuth: PropTypes.bool,
  hideSearch: PropTypes.bool,
};

export default compose(
  withBackendData(),
  withReactRouter(),
  withReduxStore(initializeStore(rootReducer)),
  withAdaptivity({ breakpoints, setBreakpoint }),
  withDataLoading(() => ({
    courses: new ContentEndpoint(endpoints.requestCourses),
    pastWebinars: new ContentEndpoint(endpoints.requestWebinars, '&isFuture=false'),
    futureWebinars: new ContentEndpoint(endpoints.requestWebinars, '&isFuture=true'),
    courseSeriesList: new ContentEndpoint(endpoints.requestCourseSeriesList),
    webinarSeriesList: new ContentEndpoint(endpoints.requestWebinarSeriesList),
    pastConferences: new ContentEndpoint(endpoints.requestConferences, '&isFuture=false'),
    futureConferences: new ContentEndpoint(endpoints.requestConferences, '&isFuture=true'),
    articles: new ContentEndpoint(endpoints.requestArticles),
    lectures: new ContentEndpoint(endpoints.requestLectures),
  })),
  connect(mapStateToProps),
)(App);
