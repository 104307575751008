import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectSearchNotFound } from 'store/widgets';
import { selectSearchResults, selectSearchIndex } from 'store/search';

/* Components */
import { Link, Preloader, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const NotFound = ({ text }) => (
  <Text className={cx('notFound')}>
    {text}
  </Text>
);

const Option = ({ title, url, onClick }) => (
  <Link className={cx('option')} routerLink href={url} onClick={onClick}>
    <Text className={cx('text')}>
      {title}
    </Text>
  </Link>
);

const ResultsComponent = ({ index, results, notFound, isLoading, onClick, className }) => {
  const renderContent = () => {
    if (isLoading) return <Preloader />;

    if (!results || !results.length) return <NotFound text={notFound} />;

    return results.map((item) => (
      <div key={`${item.title}-${index}`} className={cx('container')}>
        <Text className={cx('title')}>
          {item.title}
        </Text>

        <div className={cx('options')}>
          {item.items?.map((option) => (
            <Option
              key={option.id}
              title={option.title}
              url={option.url}
              onClick={onClick}
            />
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className={cx('component', className)}>
      {renderContent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  notFound: selectSearchNotFound(state),
  results: selectSearchResults(state),
  index: selectSearchIndex(state),
});

ResultsComponent.propTypes = {
  index: PropTypes.number,
  notFound: PropTypes.string,
  results: PropTypes.array,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export const Results = connect(mapStateToProps)(ResultsComponent);
