/**
 * this function is required to fix issues with sidebar,
 * caused by untracked layout changes
 */
export const simulateResize = () => {
  if (typeof window !== 'undefined') {
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }
};

export const getWindowWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }
};

export const getCurrentBreakpoint = (breakpoints) => breakpoints.filter((bp) => bp <= getWindowWidth())[0];
