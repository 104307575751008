export const WIDGETS = [
  'main',
  'page_lecture',
  'page_webinar',
  'page_article',
  'page_conference',
  'page_course',
  'auth',
  'faq',
  'userAccount',
  'featureToggleSettings',
];

export const PROFILE_UPDATE_ERRORS = {
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
};

export const EVENT_SUBSCRIPTION_ERRORS = {
  ALREADY_SUBSCRIBED: 'ALREADY_SUBSCRIBED',
  MAIL_NOT_CONFIRMED: 'MAIL_NOT_CONFIRMED',
};

export const SUBSCRIPTION_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  ALREADY_SUBSCRIBED: 'alreadyRegistered',
  MAIL_NOT_CONFIRMED: 'mailNotConfirmed',
};

export const DEFAULT_NAVIGATION_TAB = 'sections';

export const DEFAULT_AUTH_TAB = 'registration';
export const LOGIN_AUTH_TAB = 'login';
export const LOGIN_OPTIONS = {
  sms: 'sms',
  mobileID: 'mobileID',
};

export const ACCESS_LEVELS = {
  authOnly: 'authOnly',
  nonAuthOnly: 'nonAuthOnly',
  unlimited: 'unlimited',
};

export const ACCESS_LEVELS_KEYS = Object.keys(ACCESS_LEVELS);

export const BANNER_BUTTON_ACTIONS = {
  formSubmit: 'formSubmit',
  eventSubscription: 'eventSubscription',
  link: 'link',
};

export const MAIN_TAGS = {
  article: 'article',
  lecture: 'lecture',
  lecturesList: 'lecturesList',
  course: 'course',
  courseList: 'courseList',
  webinar: 'webinar',
  webinarList: 'webinarList',
  conference: 'conference',
};

export const REGISTRATION_FORM_STEPS = {
  initial: 'initial',
  phone: 'phone',
};

export const DEFAULT_PAGE_SIZE = 10;

export const RESULT_STATUS = {
  profileUpdated: 'profileUpdated',
  phoneUpdated: 'phoneUpdated',
  emailUpdated: 'emailUpdated',
  loginSuccess: 'loginSuccess',
  registrationSuccess: 'registrationSuccess',
  emailConfirmed: 'emailConfirmed',
  confirmationError: 'confirmationError',
  alreadyRegistered: 'alreadyRegistered',
  mailNotConfirmed: 'mailNotConfirmed',
  notRegistered: 'notRegistered',
  imageFormat: 'imageFormat',
  error: 'error',
};

export const POPUP_STEP = {
  default: '',
  initial: 'initial',
  ready: 'ready',
  form: 'form',
  changeEmail: 'changeEmail',
  changePhone: 'changePhone',
  phone: 'phone',
};

export const POPUP_TYPE = {
  default: '',
  auth: 'auth',
  userAccount: 'userAccount',
  eventSubscription: 'eventSubscription',
};

export const CONFIRM_CODE_FLAG = 'confirmCode=';