import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const InfoPiece = ({ label, children, className }) => (
  <div className={cx('component', className)}>
    <Text className={cx('label')}>
      {label}
    </Text>
    <Text className={cx('info')}>
      {children}
    </Text>
  </div>
)
