import classNames from 'classnames/bind';

import { usePopup } from 'hooks/usePopup';

import { BlackButtonOrLink, SecondaryButtonOrLink } from 'components';
import Cross from '../../../../images/close.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CloseButton = ({ onClose, mode = 'black', className }) => {
  const { closePopup } = usePopup();

  const handleClose = () => {
    onClose?.();
    closePopup();
  };

  if (mode === 'secondary') {
    return (
      <SecondaryButtonOrLink
        theme="light"
        className={cx('close', className)}
        isRound
        onClick={handleClose}
      >
        <Cross />
      </SecondaryButtonOrLink>
    )
  }

  return (
    <BlackButtonOrLink
      className={cx('close', className)}
      isRound
      onClick={handleClose}
    >
      <Cross />
    </BlackButtonOrLink>
  )
};
