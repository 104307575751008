import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { selectLoginOptions } from 'store/widgets';
import { selectLoginInProgress } from 'store/auth/selectors';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LoginOptionsComponent = ({ options, selectedOption, loginInProgress, onClick, className }) => {
  const handleClick = (option) => {
    if (!loginInProgress) {
      onClick(option);
    }
  };

  return (
    <div className={cx('tabs', className)}>
      {Object.keys(options).map((option) => (
        <Text
          key={option}
          className={cx('tab', selectedOption === option && 'active', loginInProgress && 'disabled' )}
          onClick={() => handleClick(option)}
        >
          {options[option]}
        </Text>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  options: selectLoginOptions(state),
  loginInProgress: selectLoginInProgress(state),
});

export const LoginOptions = connect(mapStateToProps)(LoginOptionsComponent);
