import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Option = ({ onClick, option, children, className }) => (option?.hidden ? null : (
  <div className={cx('component', className)} onClick={() => onClick?.(option)}>
    {children}
  </div>
));

Option.propTypes = {
  option: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
};

export const CompanyOption = ({ name, tin }) => (
  <>
    {name && (
      <Text className={cx('text')}>
        {name}
      </Text>
    )}
    {tin && (
      <Text className={cx('text', 'tin')}>
        {tin}
      </Text>
    )}
  </>
);

export const TownOption = ({ name }) => name && (
  <Text className={cx('text')}>
    {name}
  </Text>
);
