import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState, selectDesktop } from 'store/selectors';
import { authSelector } from 'store/auth/selectors';
import { userSelector } from 'store/user/selectors';
import { selectLoginTexts, selectRegistrationTexts } from 'store/widgets';

/* Constants */
import { RESULT_STATUS } from 'constants/constants';

const pageSelector = (state) => getGlobalState(state).page;

export const selectSearchMode = createSelector(pageSelector, (state) => state.searchMode);
export const selectMobileOS = createSelector(pageSelector, (state) => state.mobileOS);
export const selectOverlayState = createSelector(pageSelector, (state) => state.overlay);
export const selectPopupState = createSelector(pageSelector, (state) => state.popup);
export const selectActivePopupType = (state) => selectPopupState(state)?.type;
export const selectActivePopupStep = (state) => selectPopupState(state)?.step;
export const selectEventType = (state) => selectPopupState(state)?.eventType;
export const selectIsPopupStepForm = (state) => selectActivePopupType(state) && selectActivePopupStep(state) === 'form';
export const selectPrevUrl = createSelector(pageSelector, (state) => state.prevUrl);

export const selectResultStatus = createSelector(pageSelector, (state) => state.resultStatus);
export const selectErrorMessage = createSelector(pageSelector, (state) => state.errorMessage);
export const selectAuthPopupOpened = (state) => selectActivePopupType(state) === 'auth';
export const selectProfilePopupOpened = (state) => selectActivePopupType(state) === 'userAccount';
export const selectEventSubPopupOpened = (state) => selectActivePopupType(state) === 'eventSubscription';
export const selectAnyPopupOpened = (state) => !!selectActivePopupType(state);
export const selectDisplaySendEmailsInput = createSelector(
  [userSelector, authSelector],
  ({ email }, { isAuthorized }) => isAuthorized && !email,
);
export const selectDisplayImage = createSelector(
  [selectActivePopupStep, selectDesktop],
  (activeStep, isDesktop) => (activeStep !== 'form' || (activeStep === 'changeEmail' && activeStep === 'changePhone')) && isDesktop,
);
export const selectUserAccountPopupImage = createSelector(
  [selectActivePopupStep, selectRegistrationTexts, selectLoginTexts],
  (activeStep, reg, login) => (activeStep === 'changeEmail' || activeStep === 'changePhone' ? login?.image : reg?.image),
);

export const selectIsConfirmationError = (state) => selectResultStatus(state) === RESULT_STATUS.confirmationError;
export const selectIsEmailNotSend = (state) => selectResultStatus(state) === RESULT_STATUS.emailUpdated;

export const selectIsSearchActive = createSelector(selectSearchMode, (state) => state === 'input');
