import { connect } from 'react-redux';

/* Store */
import { selectCourses, selectCoursesAmount } from 'store/content/selectors';
import { loadMoreCourses, selectCoursesLoadedFlag, selectLoadedCourses } from 'store/pagination';

/* Components */
import { Template } from './Template';

const CoursesComponent = ({ courses, loaded, amount, fullyLoaded, onLoadMore }) => (
  <Template
    courses={courses}
    loaded={loaded}
    amount={amount}
    fullyLoaded={fullyLoaded}
    onLoadMore={onLoadMore}
  />
);

const mapStateToProps = (state) => ({
  courses: selectCourses(state),
  loaded: selectLoadedCourses(state),
  amount: selectCoursesAmount(state),
  fullyLoaded: selectCoursesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreCourses,
};

export const CoursesBlock = connect(mapStateToProps, mapDispatchToProps)(CoursesComponent);
