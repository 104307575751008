import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { animated } from "react-spring";

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Avatar = ({ image, animation }) => {
  const Component = animation ? animated.div : 'div';

  return <Component className={cx('image')} style={{ backgroundImage: `url("${image}")`, ...animation }} />
};

Avatar.propTypes = {
  image: PropTypes.string,
  animation: PropTypes.object,
};
