import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectCodeInputFields, selectCodeTimeout, selectRepeatButton } from 'store/widgets';
import {
  selectCodeInputErrorFlag,
  selectCodeInputErrorMessage,
  selectMobileIdLoginInProgress,
} from 'store/auth/selectors';

import { useCodeInput } from './useCodeInput';
import { useTimer } from 'hooks/useTimer';

import { Text, Timer } from 'components';
import { FFInputWithIcon } from 'components/Inputs';
import Alert from '../../../../images/alert.svg';
import Success from '../../../../images/success.svg';

import styles from './styles.pcss';
import Rewind from 'images/rewind.svg';

const cx = classNames.bind(styles);

const CodeInputComponent = ({
  inputProps,
  onRequestCode,
  onConfirm,
  className,
  error,
  message,
  repeatButton,
  timeout,
  isMobileId,
  ...restProps
}) => {
  const { timeMessage, time, resetTime } = useTimer(timeout, 'Код не пришел?');
  const { valid, repeatRequest, handleChange } = useCodeInput({ onConfirm, onRequestCode, resetTime });

  return (
    <div className={cx('container')}>
      <FFInputWithIcon
        {...inputProps}
        {...restProps}
        inputType={isMobileId ? 'mobileIdCode' : 'code'}
        onChangeProxy={handleChange}
        className={cx('input')}
        error={error}
        message={message}
        disabled={valid && !error}
        icon={valid && !error ? <Success /> : <Alert className={cx('alertIcon')} />}
        autoComplete="off"
      />

      {!valid && (
        <Timer
          className={cx('timer')}
          time={time}
          message={timeMessage}
          onClick={repeatRequest}
          icon={<Rewind className={cx('rewindIcon')} />}
        >
          <Text className={cx('repeatText')}>
            {repeatButton}
          </Text>
        </Timer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  inputProps: selectCodeInputFields(state),
  timeout: selectCodeTimeout(state),
  error: selectCodeInputErrorFlag(state),
  message: selectCodeInputErrorMessage(state),
  isMobileId: selectMobileIdLoginInProgress(state),
  repeatButton: selectRepeatButton(state),
});

CodeInputComponent.propTypes = {
  codeInputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  error: PropTypes.bool,
  isMobileId: PropTypes.bool,
  message: PropTypes.string,
  repeatButton: PropTypes.string,
  timeout: PropTypes.number,
  onRequestCode: PropTypes.func,
  onConfirm: PropTypes.func,
};

export const CodeInput = connect(mapStateToProps, null)(CodeInputComponent);
