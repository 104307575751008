import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Link, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TelegramChannelLink = ({ texts }) => (
  <div className={cx('component')}>
    <Text isInline className={cx('text')}>
      {texts.text}
    </Text>
    {' '}
    <Link href={texts.link?.url}>
      <Text isInline className={cx('link')}>
        {texts.link?.text}
      </Text>
    </Link>
  </div>
);

TelegramChannelLink.propTypes = {
  texts: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};
