import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectFAQTexts, selectFAQTitle } from 'store/widgets';

/* Components */
import { Accordion, Heading, Wrapper } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FAQComponent = ({ title, texts, isDesktop }) => (
  <Wrapper className={cx('container')} fullWidth={!isDesktop} width={800}>
    <Heading level={1} className={cx('title')}>
      {title}
    </Heading>

    <Accordion className={cx('accordion')}>
      {texts.map(({ key, question, answer }) => (
        <Accordion.Tab key={key} question={question} className={cx('tab')}>
          <Accordion.Content className={cx('content')}>
            {answer}
          </Accordion.Content>
        </Accordion.Tab>
      ))}
    </Accordion>
  </Wrapper>
);

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  title: selectFAQTitle(state),
  texts: selectFAQTexts(state),
});

FAQComponent.defaultProps = {
  texts: []
};

FAQComponent.propTypes = {
  isDesktop: PropTypes.bool,
  title: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    question: PropTypes.string,
    answer: PropTypes.string,
  })),
};

export const FAQ = connect(mapStateToProps)(FAQComponent);
