import { Children, useState, useRef, useEffect } from 'react';
import { getClientRect } from 'utils/getClientRect';
import { useEventListener } from 'hooks/useEventListener';

let autoSwitchTimer = null;

export const useSlider = ({ children, autoSwitch }) => {
  const [swiping, setSwiping] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState('');
  const [activeSlide, setActiveSlide] = useState(0);
  const [offset, setOffset] = useState(0);
  const [autoSwitchActive, setAutoSwitchActive] = useState(!!autoSwitch);

  const container = useRef(null);
  const movementStartPoint = useRef(0);

  const lastSlideIndex = Children.count(children) - 1;

  const returnToStart = () => {
    setActiveSlide(0);
    setOffset(0);
  };

  const returnToEnd = () => {
    const { width } = getClientRect(container);

    setActiveSlide(lastSlideIndex);
    setOffset(-width * lastSlideIndex);
  };

  const setNextSlide = () => {
    const { width } = getClientRect(container);

    if (activeSlide === lastSlideIndex) {
      returnToStart();
    } else {
      setActiveSlide((prev) => prev + 1);
      setOffset((prev) => prev - width);
    }
  };

  const setPrevSlide = () => {
    const { width } = getClientRect(container);

    if (activeSlide === 0) {
      returnToEnd();
    } else {
      setActiveSlide((prev) => prev - 1);
      setOffset((prev) => prev + width);
    }
  };

  const handleStart = (e) => {
    setSwiping(true);
    setAutoSwitchActive(false);
    movementStartPoint.current = e.touches?.[0]?.clientX || e.clientX;
  };

  const handleMove = (e) => {
    if (swiping && Math.abs((e.changedTouches?.[0]?.clientX || e.clientX) - movementStartPoint.current) > 100) {
      if (Math.sign((e.changedTouches?.[0]?.clientX || e.clientX) - movementStartPoint.current) > 0) {
        setSwipeDirection('right');
      } else {
        setSwipeDirection('left');
      }
    }
  };

  const handleEnd = () => {
    setSwiping(false);
    setSwipeDirection('');
  };

  useEventListener('resize', () => {
    if (typeof window !== 'undefined') {
      setOffset(getClientRect(container).width * Math.sign(offset));
    }
  });

  useEffect(() => {
    if (autoSwitch && autoSwitchActive) {
      autoSwitchTimer = setTimeout(setNextSlide, autoSwitch * 1000);

      return () => clearTimeout(autoSwitchTimer);
    }
  });

  useEffect(() => {
    if (swipeDirection === 'left') {
      setNextSlide();
      return;
    }

    if (swipeDirection === 'right') {
      setPrevSlide();
    }
  }, [swipeDirection]);

  return {
    container,
    offset,
    handleStart,
    handleEnd,
    handleMove,
  };
};
