import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Image = ({ src, absolute, className }) => (
  <div className={cx('component', { absolute }, className)} style={{ backgroundImage: `url(${src})` }} />
);

Image.propTypes = {
  src: PropTypes.string,
  absolute: PropTypes.bool,
};
