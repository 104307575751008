import 'core-js';
import smoothScroll from 'smoothscroll-polyfill';
import { Helmet } from 'react-helmet';

import App from 'pages/App';
import { withSSRDiscovery } from 'hocs/withSSRDiscovery';

if (module?.hot) {
  module.hot.accept();
}

if (typeof document === 'undefined') {
  global.Helmet = Helmet;
} else {
  // kick off the polyfill!
  smoothScroll.polyfill();
}


export { App };
export const SSRDiscovery = withSSRDiscovery()(App);
