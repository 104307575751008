import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounceFn } from 'ahooks';

import { searchData } from 'api/requests';

import { setSearchQuery, setSearchResults } from 'store/search';

export const useSearch = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const { run: searchResults } = useDebounceFn(
    (value) => {
      if (!value) return;

      dispatch(setSearchQuery(value));

      return searchData({ value })
        .then((res) => dispatch(setSearchResults(res)))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
    {
      wait: 500,
    },
  );

  const handleChange = (value) => {
    setLoading(true);

    searchResults(value);
  };

  const handleValidation = (value) => !value;

  return { handleValidation, handleChange, isLoading };
};
