import classNames from 'classnames/bind';

import { ErrorMessage } from '../../components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const withErrorMessage = (WrappedComponent) => ({ error, message, className, ...restProps }) => (
  <div className={cx('container', className)}>
    <WrappedComponent {...restProps} />
    <ErrorMessage error={error} message={message} />
  </div>
);
