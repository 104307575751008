import { createSelector } from '@reduxjs/toolkit';

import { getGlobalState } from 'store/selectors';

export const userSelector = (state) => getGlobalState(state).user;

export const selectUserId = createSelector(userSelector, (state) => state.id);
export const selectUserName = createSelector(userSelector, (state) => state.name);
export const selectUserEmail = createSelector(userSelector, (state) => state.email);
export const selectEmailConfirmed = createSelector(userSelector, (state) => state.emailConfirmed);
export const selectUserAvatar = createSelector(userSelector, (state) => state.avatar);
export const selectUserInitialData = createSelector(userSelector, (state) => ({
  name: state.name,
  phone: state.phoneNumber,
  email: state.email,
  company: state.company,
  position: state.position,
  mail: state.mail,
  telegram: state.telegram ? `@${state.telegram}` : state.telegram,
  town: state.town,
}));
export const selectUserChanges = createSelector(userSelector, (state) => state.changes);
export const selectChangedEmail = createSelector(selectUserChanges, (state) => state?.email);
export const selectChangedPhone = createSelector(selectUserChanges, (state) => state?.phone);
