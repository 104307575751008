import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames/bind';

/* Hooks */
import { useTabs } from 'hooks/useTabs';

/* Store */
import { resetPrevUrl } from 'store/page/actions';
import { selectDesktop } from 'store/selectors';
import { selectNavigationContentButtons, selectNavigationTabs } from 'store/widgets';

/* Components */
import { MenuButton, Wrapper } from 'components';
import { Tabs, Sections } from './components';

/* Constants */
import { DEFAULT_NAVIGATION_TAB } from 'constants/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MobileComponent = ({ isDesktop, onResetPrevUrl }) => {
  const { activeTab, changeTab } = useTabs(DEFAULT_NAVIGATION_TAB);
  const history = useHistory();

  const handleClick = () => {
    onResetPrevUrl();
    history.goBack();
  };

  return (
    <>
      <Wrapper className={cx('container')} padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={800}>
        <Tabs activeTab={activeTab} onChange={changeTab} />

        {activeTab === DEFAULT_NAVIGATION_TAB && <Sections />}
      </Wrapper>
      <MenuButton isActive onClick={handleClick} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  tabs: selectNavigationTabs(state),
  contentButtons: selectNavigationContentButtons(state),
});

const mapDispatchToProps = {
  onResetPrevUrl: resetPrevUrl,
};

MobileComponent.propTypes = {
  isDesktop: PropTypes.bool,
  tabs: PropTypes.array,
  contentButtons: PropTypes.array,
};

export const Mobile = connect(mapStateToProps, mapDispatchToProps)(MobileComponent);
