import { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

/* Constants */
import { SEARCH_PANEL_MODES } from 'constants/components';

/* Hooks */
import { useSearch } from 'hooks/useSearch';
import { useOverlay } from 'hooks/useOverlay';

/* Store */
import { selectSearchQuery } from 'store/search';

/* Components */
import { FForm } from 'components/FForm';
import { AnimatedContainer, Button, Results, Input } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SearchPanelComponent = ({ query, className }) => {
  const [buttonMode, setButtonMode] = useState(true);
  const { handleValidation, handleChange, isLoading } = useSearch();
  const { addOverlay, removeOverlay } = useOverlay();

  const toggleMode = () => setButtonMode((prev) => !prev);

  const handleClick = () => {
    toggleMode();
    buttonMode ? addOverlay() : removeOverlay();
  };

  return (
    <FForm className={cx('component', className)} initialValues={{ search: query }}>
      <div className={cx('container')}>
        <AnimatedContainer className={cx('animated')} borderRadius={100} buttonMode={buttonMode}>
          <Input
            validate={handleValidation}
            onChange={handleChange}
            onClick={handleClick}
            renderSubmitButton
          >
            {!buttonMode && (
              <Results
                className={cx('results')}
                onClick={handleClick}
                isLoading={isLoading}
              />
            )}
          </Input>
        </AnimatedContainer>
      </div>

      <Button mode={buttonMode ? SEARCH_PANEL_MODES.search : SEARCH_PANEL_MODES.cancel} onClick={handleClick} />
    </FForm>
  );
};

const mapStateToProps = (state) => ({
  query: selectSearchQuery(state),
});

export const SearchPanel = connect(mapStateToProps)(SearchPanelComponent);