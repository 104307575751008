import breakpointReducer from './breakpoint/reducers';

import { page } from 'store/page/reducers';
import { auth } from 'store/auth/reducers';
import { texts } from 'store/widgets/reducers';
import { lecture } from 'store/lecture/reducers';
import { course } from 'store/course/reducers';
import { webinar } from 'store/webinar/reducers';
import { article } from 'store/article/reducers';
import { content } from 'store/content/reducers';
import { user } from 'store/user/reducers';
import { banners } from "store/banners/reducers";
import { search } from 'store/search';
import { series } from 'store/series';
import { pagination } from 'store/pagination';

export const createRootReducer = () => (state = {}, action) => ({
  ...state,
  breakpoint: breakpointReducer(state.breakpoint, action),
  page: page(state.page, action),
  auth: auth(state.auth, action),
  texts: texts(state.texts, action),
  lecture: lecture(state.lecture, action),
  course: course(state.course, action),
  webinar: webinar(state.webinar, action),
  article: article(state.article, action),
  content: content(state.content, action),
  user: user(state.user, action),
  banners: banners(state.banners, action),
  search: search(state.search, action),
  series: series(state.series, action),
  pagination: pagination(state.pagination, action),
});
