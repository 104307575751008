import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop, selectMobile } from 'store/selectors';
import { selectConferencePageButton } from 'store/widgets';

/* Hooks */
import { useEventSubPopup } from 'hooks/useEventSubPopup';
import { useRestrictedAccess } from 'hooks/useRestricredAccess';

/* Components */
import { LargeCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConferenceComponent = ({
  id,
  image,
  tags,
  date,
  time,
  place,
  registered,
  title,
  description,
  buttonText,
  isFuture,
  isMobile,
  isDesktop,
  conferencePath,
  className,
}) => {
  const { open } = useEventSubPopup();
  const { handleRestrictedAccess } = useRestrictedAccess();

  const handleEventSub = (e) => {
    if (isFuture) {
      e.preventDefault();

      handleRestrictedAccess(( ) => open('conference', id));
    }
  };

  return (
    <LargeCard
      className={cx('component', className)}
      href={conferencePath}
      tag={<LargeCard.Tags tags={tags} className={cx('tags')} />}
      image={image}
      topRight={isMobile && (
        <LargeCard.Info
          textClassName={cx('info')}
          date={date}
          time={time}
        />
      )}
      info={!isMobile && (
        <LargeCard.Info
          textClassName={cx('info')}
          date={date}
          time={time}
          place={isDesktop && isFuture && place}
          participants={isDesktop && isFuture && registered}
        />
      )}
      button={(
        <LargeCard.Button isFuture={isFuture} onClick={handleEventSub}>
          {buttonText}
        </LargeCard.Button>
      )}
      title={(
        <LargeCard.Title className={cx('title')}>
          {title}
        </LargeCard.Title>
      )}
      description={(
        <LargeCard.Description className={cx('description')}>
          {description}
        </LargeCard.Description>
      )}
    />
  );
};

ConferenceComponent.propTypes = {
  conferencePath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  tags: PropTypes.array,
  date: PropTypes.string,
  time: PropTypes.string,
  place: PropTypes.string,
  buttonText: PropTypes.string,
  registered: PropTypes.number,
  isFuture: PropTypes.bool,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  isMobile: selectMobile(state),
  isDesktop: selectDesktop(state),
  conferencePath: `/conference/${props.alias}`,
  buttonText: props.isFuture
    ? selectConferencePageButton(state)?.register
    : selectConferencePageButton(state)?.watch,
});

export const Conference = connect(mapStateToProps, null)(ConferenceComponent);
