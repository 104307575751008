import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const EmptyButton = ({ children, className, disabled, routerLink, ...props }) => (
  <button className={cx('component', className)} disabled={disabled} type="button" {...props}>
    {children}
  </button>
);
