import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Text } from 'components';

/* Constants */
import { TAG_SIZES, TAG_TYPES } from 'constants/components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const TYPES = Object.keys(TAG_TYPES);

export const Tag = ({
  size,
  type,
  text,
  className,
  children,
}) => (
  <Text className={cx('component', size, TAG_TYPES?.[type]?.color || 'white', className)}>
    {TAG_TYPES?.[type]?.name || text}
    {children}
  </Text>
);

Tag.propTypes = {
  size: PropTypes.oneOf(TAG_SIZES),
  type: PropTypes.oneOf(TYPES),
  text: PropTypes.string,
};
