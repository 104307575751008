import { useSpring } from 'react-spring';
import { useHover } from 'react-use-gesture';

export const useAnimations = ({ open }) => {
  const [{ borderColor, opacity }, setContainer] = useSpring(() => ({
    borderColor: '#333',
    opacity: 1,
    config: {
      duration: 250,
    },
  }));

  const [{ width, height }, setAvatar] = useSpring(() => ({
    width: '120px',
    height: '120px',
    config: {
      duration: 200,
    },
  }));

  const [textAnimation, setText] = useSpring(() => ({
    opacity: 0,
    y: 40,
    delay: 300,
    config: {
      duration: 300,
    },
  }));

  const handleHover = useHover(({ hovering }) => setContainer.start({ borderColor: hovering ? '#505050' : '#333' }));

  const handleMouseDown = () => {
    setContainer.start({ opacity: 0.8 });
  };

  const handleMouseUp = () => {
    setContainer.start({ opacity: 1 });
    setAvatar.start({ width: open ? '120px' : '160px', height: open ? '120px' : '160px' });
    setText.start({ opacity: open ? 0 : 0.75, y: open ? 40 : 0 });
  };

  return {
    container: { borderColor, opacity },
    avatar: { width, height },
    handleHover,
    handleMouseDown,
    handleMouseUp,
    textAnimation,
  };
};
