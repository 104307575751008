import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectWebinarTitle } from 'store/webinar/selectors';
import { selectWebinarPageButton } from 'store/widgets';

/* Components */
import { FloatingInfoPanel } from 'components';
import { PageHeader } from 'components/PageHeader';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FloatingPanelComponent = ({ title, container, buttonText, onMainButtonClick }) => (
  <FloatingInfoPanel
    title={(
      <FloatingInfoPanel.Title>
        {title}
      </FloatingInfoPanel.Title>
    )}
    backButton={<FloatingInfoPanel.BackButton />}
    shareButton={<FloatingInfoPanel.ShareButton />}
    mainButton={(
      <PageHeader.Button className={cx('button')} onClick={onMainButtonClick}>
        {buttonText}
      </PageHeader.Button>
    )}
    container={container}
  />
);

const mapStateToProps = (state) => ({
  title: selectWebinarTitle(state),
  buttonText: selectWebinarPageButton(state),
});

FloatingPanelComponent.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onMainButtonClick: PropTypes.func,
};

export const FloatingPanel = connect(mapStateToProps)(FloatingPanelComponent);
