import classNames from 'classnames/bind';

import { SubmitButton } from '../../components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const withSubmitButton = (WrappedComponent) => ({ valid, renderSubmitButton, submitDisabled, onClick, className, ...restProps }) => (
  <div className={cx('container', className)}>
    <WrappedComponent {...restProps} className={cx('component')} />

    {renderSubmitButton && (
      <SubmitButton
        className={cx('submitButton')}
        disabled={submitDisabled || !valid}
        onClick={onClick}
      />
    )}
  </div>
);
