import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { changePhoneForm } from 'utils/formValidators';

/* Store */
import { selectChangePhoneTitle, selectContinueButton } from 'store/widgets';
import { selectChangedPhone } from 'store/user/selectors';
import { setResultStatus } from 'store/page/actions';
import { changePhoneConfirmRequest, changePhoneRequest, setPhone } from 'store/user/actions';
import { selectPhoneInputErrorFlag } from 'store/auth/selectors';

/* Hooks */
import { usePhoneInput } from 'hooks/usePhoneInput';
import { useAuthRequests } from 'hooks/useAuthRequests';

/* Constants */
import { RESULT_STATUS } from 'constants/constants';

/* Components */
import { PhoneInput, SubmitButton } from 'components/Inputs';
import { ChangeData } from '../ChangeData';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ChangePhoneComponent = ({
  title,
  continueButton,
  changedPhone,
  phoneInputError,
  onSetPhone,
  onSetResultStatus,
  onChangeRequest,
  onChangeConfirmRequest,
}) => {
  const [confirmed, setConfirmed] = useState(false);

  const displaySubmitButton = () => setConfirmed(true);

  const handleChangeConfirm = (code) => onChangeConfirmRequest(code);

  const { handleEditNumber, codeInput, displayCodeInput } = usePhoneInput();

  const { handleRequest, handleConfirm } = useAuthRequests({
    onConfirm: handleChangeConfirm,
    onRequestCode: () => onChangeRequest({ phone: changedPhone }),
    onRequestSuccess: displayCodeInput,
    onConfirmSuccess: displaySubmitButton,
  });

  const handleSuccess = () => onSetResultStatus(RESULT_STATUS.phoneUpdated);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSuccess();
  };

  return (
    <ChangeData className={cx('container')} title={title} handleValidate={changePhoneForm}>
      <PhoneInput
        className={cx('input')}
        codeInput={codeInput}
        onBlur={onSetPhone}
        disabled={codeInput}
        renderSubmitButton={!codeInput}
        onSubmit={handleRequest}
        onConfirm={handleConfirm}
        hideStatus
        editButton={codeInput && (
          <PhoneInput.Edit
            className={cx('button')}
            disabled={!phoneInputError || confirmed}
            onClick={handleEditNumber}
          />
        )}
      />

      {confirmed && (
        <SubmitButton className={cx('submitButton')} onClick={handleSubmit}>
          {continueButton}
        </SubmitButton>
      )}
    </ChangeData>
  );
};

const mapStateToProps = (state) => ({
  title: selectChangePhoneTitle(state),
  changedPhone: selectChangedPhone(state),
  continueButton: selectContinueButton(state),
  phoneInputError: selectPhoneInputErrorFlag(state),
});

const mapDispatchToProps = {
  onSetPhone: setPhone,
  onSetResultStatus: setResultStatus,
  onChangeRequest: changePhoneRequest,
  onChangeConfirmRequest: changePhoneConfirmRequest,
};

ChangePhoneComponent.propTypes = {
  title: PropTypes.string,
  continueButton: PropTypes.string,
  changedPhone: PropTypes.string,
  onSetPhone: PropTypes.func,
  onSetResultStatus: PropTypes.func,
  onChangeRequest: PropTypes.func,
  onChangeConfirmRequest: PropTypes.func,
};

export const ChangePhone = connect(mapStateToProps, mapDispatchToProps)(ChangePhoneComponent);
