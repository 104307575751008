import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import endpoints from 'api/endpoints';
import { compose } from 'utils/fn';
import { withDataLoading, Endpoint } from 'hocs';
import { useFloatingButton } from 'components/FloatingUpButton/useFloatingButton';

/* Store */
import { selectDesktop } from 'store/selectors';
import {
  selectLectureBanners,
  selectLectureContent,
  selectLectureDescription,
  selectLectureDuration,
  selectLectureImage,
  selectLectureRelatedContent,
  selectLectureRelatedContentSlides,
  selectLectureTags,
  selectLectureTitle,
  selectLectureVideo,
  selectLectureVideoSource,
  selectLectureViews,
} from 'store/lecture/selectors';

/* Components */
import {
  Wrapper,
  ContentContainer,
  FloatingUpButton,
  MenuButton,
  DeskNavigation as Navigation,
  VideoBlock,
  BannersBlock,
  RelatedContent,
  LectureCard,
} from 'components';
import { PageHeader } from 'components/PageHeader';
import { FloatingPanel } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LecturePage = ({
  title,
  description,
  image,
  video,
  tags,
  views,
  duration,
  content,
  relatedContent,
  slides,
  banners,
  isDesktop,
  isExternal,
}) => {
  const container = useRef(null);

  const { renderButton } = useFloatingButton(container);

  return (
    <>
      <Wrapper padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
        <Navigation className={cx('navigation')} />
      </Wrapper>

      <PageHeader
        className={cx('header')}
        image={(
          <PageHeader.Image
            className={cx('background')}
            // src="//security-api.ru/st/QP/Beelinelab/images/lecture_page_banner.png"
            absolute
          />
        )}
        tags={<PageHeader.Tags className={cx('tags')} tags={tags} />}
        title={(
          <PageHeader.Title className={cx('title')}>
            {title}
          </PageHeader.Title>
        )}
        theme={(
          <PageHeader.Theme className={cx('description')}>
            {description}
          </PageHeader.Theme>
        )}
        info={(
          <PageHeader.InfoPanel
            length={duration}
            views={views}
          />
        )}
      />
      <div ref={container}>
        <Wrapper className={cx('content')} padding={isDesktop ? 0 : 20} fullWidth={!isDesktop} width={800}>
          <ContentContainer className={cx('parsedContent')}>
            {content}
          </ContentContainer>

          <VideoBlock className={cx('video')} src={video} isExternal={isExternal} thumbnail={image} />
        </Wrapper>

        <Wrapper className={cx('banners')} padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
          <BannersBlock banners={banners} />

          {relatedContent && (
            <RelatedContent slides={slides}>
              {relatedContent.map((lecture) => (
                <LectureCard className={cx('card')} key={lecture.id} shouldRedirect {...lecture} />
              ))}
            </RelatedContent>
          )}
        </Wrapper>

        {isDesktop && renderButton && <FloatingUpButton />}

        <FloatingPanel container={container} />
      </div>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  title: selectLectureTitle(state),
  image: selectLectureImage(state),
  tags: selectLectureTags(state),
  description: selectLectureDescription(state),
  duration: selectLectureDuration(state),
  views: selectLectureViews(state),
  content: selectLectureContent(state),
  relatedContent: selectLectureRelatedContent(state),
  slides: selectLectureRelatedContentSlides(state),
  banners: selectLectureBanners(state),
  video: selectLectureVideo(state),
  isExternal: selectLectureVideoSource(state),
});

LecturePage.propTypes = {
  duration: PropTypes.string,
  content: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  views: PropTypes.number,
  slides: PropTypes.number,
  tags: PropTypes.array,
  banners: PropTypes.array,
  relatedContent: PropTypes.array,
  isDesktop: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export const Lecture = compose(
  withDataLoading(({ alias }) => ({
    lecture: new Endpoint(`${endpoints.requestLecture}/?alias=${alias}`)
  })),
  connect(mapStateToProps, null),
)(LecturePage);
