import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { compose } from 'utils/fn';
import { withDataLoading } from 'hocs';

import {
  selectErrorPageButtonText,
  selectErrorPageDescription,
  selectErrorPageImage,
  selectErrorPageTitle
} from 'store/widgets';
import { selectDesktop, selectMobile } from 'store/selectors';

import { Logo, Wrapper } from 'components';
import { Title, Description, Image, Button } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ErrorPageComponent = ({ title, description, buttonText, image, isDesktop, isMobile }) => (
  <Wrapper
    fullWidth
    padding={isDesktop ? 16 : 20}
    className={cx('wrapper')}
    backgroundImage={isDesktop ? undefined : image}
  >
    <div className={cx('component')}>
      {!isMobile && <Logo className={cx('logo')} />}

      <div className={cx('content')}>
        <Title className={cx('title')}>
          {title}
        </Title>

        <Description className={cx('description')}>
          {description}
        </Description>

        <Button>
          {buttonText}
        </Button>
      </div>
    </div>
    {isDesktop && <Image src={image} />}
  </Wrapper>
);

const mapStateToProps = (state, props) => ({
  title: selectErrorPageTitle(props.errorCode)(state),
  description: selectErrorPageDescription(props.errorCode)(state),
  buttonText: selectErrorPageButtonText(props.errorCode)(state),
  image: selectErrorPageImage(props.errorCode)(state),
  isDesktop: selectDesktop(state),
  isMobile: selectMobile(state),
});

ErrorPageComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  image: PropTypes.string,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export const ErrorPage = compose(
  withDataLoading(),
  connect(mapStateToProps),
)(ErrorPageComponent);
