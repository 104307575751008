import React from 'react';
import { BREAKPOINT_1004 } from 'store/breakpoint/constants';
import { getCurrentBreakpoint, simulateResize } from './helpers';
import { useEventListener } from '../useEventListener';
import { useOnMount } from 'hooks/useOnMount';

/**
 * Update each time a resolution changes
 */
export const useAdaptiveSize = (breakpoints) => {
  const [breakpoint, setBreakpoint] = React.useState(BREAKPOINT_1004);

  React.useEffect(() => {
    simulateResize();
  }, [breakpoint]);

  useOnMount(() => {
    setBreakpoint(getCurrentBreakpoint(breakpoints));
  });

  useEventListener('resize', React.useCallback(() => {
    /** return closest breakpoint that we surpassed in width */
    setBreakpoint(getCurrentBreakpoint(breakpoints));
  }, [breakpoints]), typeof window !== 'undefined' ? window : null);

  return breakpoint;
};
