import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

/* Components & Icons */
import { ButtonOrLink, Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Timer = ({ message, time, onClick, icon, className, children }) => (
  <div className={cx('component', className)}>
    <Text className={cx('timeLeft')}>
      {message}
    </Text>

    <div className={cx('codeRepeat')}>
      {icon}
      <ButtonOrLink
        className={cx('repeatButton', time > 0 && 'disabled')}
        onClick={onClick}
        disabled={time > 0}
      >
        {children}
      </ButtonOrLink>
    </div>
  </div>
);

Timer.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string,
  onClick: PropTypes.func,
};
