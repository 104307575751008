import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Title, Description, Button, SendEmail } from './components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Result = ({
  text,
  description,
  buttonText,
  sendEmail,
  onClick,
  onClose,
  className,
}) => {
  const handleClick = () => {
    onClick?.();
    onClose?.();
  };

  return (
    <div className={cx('container', className)}>
      <Title title={text} />

      <Description description={description} />

      <div className={cx('buttonsContainer')}>
        <Button onClick={handleClick} text={buttonText} />

        <SendEmail text={sendEmail} onClose={onClose} />
      </div>
    </div>
  );
};

Result.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  sendEmail: PropTypes.string,
  onClick: PropTypes.func,
};
