import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { MAX_LENGTHS } from 'constants/input';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Input = ({
  type,
  inputType,
  disabled,
  children,
  autofocus,
  className,
  inputClassName,
  valid,
  dispatch,
  ...props
}) => (
  <div className={cx('component', className)}>
    <input
      {...props}
      maxLength={MAX_LENGTHS[inputType]}
      type={type}
      className={cx('input', inputClassName, { disabled })}
      disabled={disabled}
      autoFocus={autofocus}
    />
    {children}
  </div>
);

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputType: PropTypes.string,
  type: PropTypes.string,
  autofocus: PropTypes.string,
  disabled: PropTypes.bool,
  withLabel: PropTypes.bool,
  valid: PropTypes.bool,
};
