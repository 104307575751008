import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { cxBool } from 'utils/format-string';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Text = ({
  children, size, isBlack, isError, isInline, isCentered, isBold, className,
  ...otherAttributes
}) => {
  const Tag = isInline ? 'span' : 'div';
  return (
    <Tag
      className={cx('component', className, cxBool({ isCentered, isBold, isBlack, isError }))}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
      {...otherAttributes}
    />
  );
};

Text.defaultProps = {
  size: 'regular',
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  size: PropTypes.string,
  isError: PropTypes.bool,
  isBlack: PropTypes.bool,
  isInline: PropTypes.bool,
  isCentered: PropTypes.bool,
  isBold: PropTypes.bool,
};
