import { createAction } from '@reduxjs/toolkit';
import { POPUP_STEP } from 'constants/constants';

export const setSearchMode = createAction('SET_SEARCH_MODE');
export const setMobileOS = createAction('SET_MOBILE_OS');
export const setOverlay = createAction('SET_OVERLAY');
export const setPopup = createAction('SET_POPUP');
export const setPrevUrl = createAction('SET_PREV_URL');
export const setResultStatus = createAction('SET_RESULT_STATUS');
export const setErrorMessage = createAction('SET_ERROR_MESSAGE');

export const resetResultStatus = () => setResultStatus('');
export const changePopupStep = (step) => setPopup({ step });
export const resetPopup = () => setPopup({ type: null, step: POPUP_STEP.default, eventType: null });
export const resetPrevUrl = () => setPrevUrl('');
export const removeOverlay = () => setOverlay(false);

export const handleAttemptsError = (error, message) => (dispatch) => {
  dispatch(setResultStatus(error));
  dispatch(setErrorMessage(message));
};
