import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { animated, Spring } from 'react-spring';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectCopySuccessText, selectShareButtonText } from 'store/widgets';

/* Components */
import { SecondaryButtonOrLink, Text } from 'components';
import Share from '../../images/share.svg';
import Check from '../../images/check.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ShareButtonComponent = ({ copySuccessful, defaultText, onClick, isDesktop, fullSize, className }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    const link = typeof window !== 'undefined' && window.location.href;

    onClick?.();

    // TODO: проверить работу в IE и в safari на айфонах
    navigator.clipboard.writeText(link).then(() => setCopied(true));
  };

  const renderContent = useCallback(() => {
    if (copied) {
      return (
        <>
          <Check className={cx('successIcon')} />

          {(isDesktop || fullSize) && (
            <Spring
              from={{ transform: 'matrix(0.6, 0, 0, 1, -15, 0)' }}
              to={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
            >
              {(style) => (
                <animated.div style={style}>
                  <Text className={cx('text')}>
                    {copySuccessful}
                  </Text>
                </animated.div>
              )}
            </Spring>
          )}
        </>
      );
    }

    return (
      <div className={cx('share')}>
        <Share className={cx('icon')} />

        {((isDesktop && defaultText) || fullSize) && (
          <Text className={cx('text', { fullSize })}>
            {defaultText}
          </Text>
        )}
      </div>
    );
  }, [copied]);

  return (
    <SecondaryButtonOrLink
      className={cx('component', className)}
      size="small"
      onClick={handleClick}
      isAdaptive={fullSize}
    >
      <div className={cx('content')}>
        {renderContent()}
      </div>
    </SecondaryButtonOrLink>
  );
};

ShareButtonComponent.propTypes = {
  onClick: PropTypes.func,
  fullSize: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  defaultText: selectShareButtonText(state),
  copySuccessful: selectCopySuccessText(state),
});

export const ShareButton = connect(mapStateToProps)(ShareButtonComponent);
