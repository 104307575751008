import { reduce } from 'utils/fn';
import { isIE } from 'utils/detectIE';

/**
 * Takes a template like "Age: {{age}}" and replaces {{age}} on values
 * from the object `vars` with the appropriate fields
 * e.g.
 * templateParser('Age: {{age}}', { age: 21 }) => 'Age: 21'
 * @param {string} string
 * @param {object} vars
 */
export const templateParser = (string, vars) => {
  if (!string) return '';
  if (!vars) return string;
  return string.replace(/{{(\w+)}}/ig, (full, match) => vars[match]);
};

/**
 * Принимает в себя массив со склонениями слова из переменной dictionary и число,
 * к которому нужно применить необходимое склонение;
 * возвращает необходимое склонение слова из массива;
 * @param number {number} - Число;
 * @param nounArray {array} - Массив со склонениями слова;
 * @return {string} - Слово с необходимым склонением;
 */

export const declension = (nounArray, number) => {
  const num = Math.floor(number);
  const cases = [2, 0, 1, 1, 1, 2];
  const index = (num % 100 > 4 && num % 100 < 20)
    ? 2
    : cases[(num % 10 < 5) ? num % 10 : 5];
  return nounArray[index];
};

const dictionary = {
  seconds: ['секунду', 'секунды', 'секунд'],
  minutes: ['минута', 'минуты', 'минут'],
  articles: ['статью', 'статьи', 'статей'],
  month: [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
  ],
};

export const translate = (word, number) => {
  if (!dictionary[word]) return null;
  return declension(dictionary[word], number);
};

/**
 * Приводит номер телефона +7 900 123 44 55
 * К формату для передачи в API 79001234455
 * @param {string} phoneNumber - номер телефона, введенный пользователем
 * @returns {string}
 */
export const unFormatPhone = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;

  return phoneNumber.replace('+7 ', '7').split(' ').join('');
};

/**
 * Приводит ctn (номре телефона, полученный из API) 9001234455
 * К формату для отображения пользователю +7 900 123 44 55
 * @param {string} ctn - номер телефона, полученный из API
 * @returns {string}
 */
export const formatPhone = (ctn) => {
  if (ctn.length < 5) return ctn;

  const mainNumber = ctn.slice(ctn.length - 10, ctn.length);
  const code = mainNumber.slice(0, 3);
  const FstTelNum = mainNumber.slice(3, 6);
  const SndTelNum = mainNumber.slice(6, 8);
  const TrdTelNum = mainNumber.slice(8, 10);

  return `+7 ${code} ${FstTelNum} ${SndTelNum} ${TrdTelNum}`;
};

/**
* Input: isAvailableFlag
* Output: availableFlag
*/
const statusToClass = (status) => (status.indexOf('is') === 0 ? (
  status.substring(2, 3).toLowerCase() + status.substring(3)
) : status);

/**
 * Remove `is` and convert next letter to lowerCase
 */
export const cxBool = (classes) => reduce(classes, (result, value, key) => ({
  ...result,
  [statusToClass(key)]: value,
}), {});

/**
 * Убирает из начала названия prop word и возвращает объект props с отформатированными названиями пропсов;
 * если в названии пропса нет запрашиваемого слова, проп добавляется в новый объект как есть
 */
export const mapProps = (props, word) => Object.keys(props).reduce((newProps, prop) => {
  if (prop.includes(word)) {
    newProps[`${prop.substring(word.length, word.length + 1).toLowerCase()}${prop.substring(word.length + 1)}`] = props[prop];
  } else {
    newProps[prop] = props[prop];
  }
  return newProps;
}, {});

/**
 * Capitalizes first letter of the string
 * @param {string} string
 * @returns {string}
 */
function capitalize(string) {
  return string.substring(0, 1).toUpperCase() + string.substring(1);
}

const getMinutes = (min) => {
  if (!min) return;

  const hour = 60;

  if (min < hour) {
    return min;
  }

  if (min > hour) {
    return min % hour;
  }

  return 0;
};

const getHours = (min) => {
  if (!min) return;

  const hour = 60;
  const dayInHours = 24;
  const dayInMinutes = 1440;

  if (min > dayInMinutes) {
    return Math.trunc(min / dayInHours) > 60 ? Math.trunc(min / dayInMinutes) : 0;
  }

  if (min < dayInMinutes && min >= hour) {
    return Math.trunc(min / hour);
  }

  return 0;
};


const getDays = (min) => {
  if (!min) return;

  const dayInMinutes = 1440;

  if (min >= dayInMinutes) {
    return Math.trunc(min / dayInMinutes);
  }

  return 0;
};

// TODO: fix more than 1 day length
export const formatRuntime = (min) => {
  if (!min) return;

  const minutes = getMinutes(min) ? `${getMinutes(min)} мин` : '';
  const hours = getHours(min) ? `${getHours(min)} ч ` : '';
  const days = getDays(min) ? `${getDays(min)} д ` : '';

  return `${days}${hours}${minutes}`;
};

export const isDateValid = (date) => !Number.isNaN(date.getTime());

export const formatDate = (dateInput, showYear = false) => {
  if (!dateInput) {
    return null;
  }

  const date = new Date(dateInput * 1000);

  if (!isDateValid(date)) {
    return null;
  }

  const dateNow = new Date();
  const year = date.getFullYear() < dateNow.getFullYear() || showYear ? date.getFullYear() : '';

  return `${date.getDate()} ${dictionary.month[date.getMonth()]}${year && ` ${year}`}`;
};

export function formatEventDate(date) {
  if (!date) return null;

  const d = new Date(date);
  const isPast = Date.now() >= d;
  const time = d.toLocaleTimeString().substring(0, isIE() ? 8 : 5);
  const day = d.toLocaleDateString('ru-RU', { day: 'numeric' });
  const month = d.toLocaleDateString('ru-RU', { month: 'numeric' });
  const year = d.toLocaleDateString('ru-RU', { year: 'numeric' });

  return { time, day, month, year, isPast };
}

export function formatWebinarDate(date) {
  if (!date) return null;

  const { time, day, month, year, isPast } = formatEventDate(date);

  return day + ' ' + dictionary.month[month - 1] + (!isPast ?  ' в ' + time : '') + (isPast ? ' ' + year : '');
}

export function formatConferenceDate(from, to) {
  if (!from || !to) return null;

  const dateFrom = formatEventDate(from);
  const dateTo = formatEventDate(to);

  return dateFrom.day + ' ' + dictionary.month[dateFrom.month - 1] + ' ' + (dateFrom.isPast ? dateFrom.year : dateFrom.time + '-' + dateTo.time);
}

export function formatCourseDate(from, to) {
  if (!from || !to) return null;

  const dateFrom = formatEventDate(from);
  const dateTo = formatEventDate(to);

  return capitalize(dictionary.month[dateFrom.month - 1]) + '-' + capitalize(dictionary.month[dateTo.month - 1]) + ' ' + dateTo.year;
}
