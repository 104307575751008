import { connect } from 'react-redux';
import classNames from 'classnames/bind';

/* Utils */
import { isIE } from 'utils/detectIE';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectSearchSetting } from 'store/widgets';

/* Components */
import { DeskNavigation as Navigation, NavBar } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const GlobalNavigationComponent = ({ isDesktop, hideSearch }) => (
  <div className={cx('component', isIE() && 'ie')}>
    <NavBar className={cx('navbar')} hideSearch={hideSearch} />

    {isDesktop && <Navigation className={cx('navigation')} />}
  </div>
);

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  hideSearch: selectSearchSetting(state),
});

export const GlobalNavigation = connect(mapStateToProps)(GlobalNavigationComponent);
