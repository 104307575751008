import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text, Select } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Options = ({
  required,
  defaultOption,
  onClick,
  notFound,
  options,
  Component,
  className,
}) => (
  <div className={cx('component', className)}>
    {!required && defaultOption && <Select.DefaultOption onClick={onClick} defaultOption={defaultOption} /> }

    {!options.length && (
      <Select.Option>
        <Text className={cx('text')}>
          {notFound}
        </Text>
      </Select.Option>
    )}

    {options?.map((option) => (
      <Select.Option onClick={onClick} key={option.id} option={option}>
        <Component {...option} />
      </Select.Option>
    ))}
  </div>
);

Options.propTypes = {
  notFound: PropTypes.string,
  required: PropTypes.bool,
  defaultOption: PropTypes.object,
  onClick: PropTypes.func,
  options: PropTypes.array,
};
