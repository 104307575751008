import { useCallback, useState } from 'react';
import { useThrottleFn } from 'ahooks';
import { validateText } from 'utils/formValidators';

export const useSelect = ({ requestData, onBlur }) => {
  const [isOpen, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedInput, setSelectedInput] = useState('');

  const closeOptions = () => setOpen(false);

  const toggleOptions = () => setOpen((prev) => !prev);

  const { run: handleRequest } = useThrottleFn((value) => requestData(value).then((res) => {
    if (res) {
      setResults(res);
      setOpen(true);
    }
  }), {
    wait: 1000,
  });

  const handleChange = (value, shouldRequest) => {
    if (value?.length > 2 && shouldRequest && !validateText(value)) {
      handleRequest(value);
    }

    setSelectedInput(value);
  };

  const handleClick = ({ name }) => {
    setSelectedInput(name);
    closeOptions();
    onBlur?.(name);
  };

  const handleValueProxy = useCallback((value) => (selectedInput || value), [selectedInput]);

  return {
    isOpen,
    results,
    toggleOptions,
    handleClick,
    handleChange,
    handleValueProxy,
    selectedInput,
  };
};
