import { createReducer } from '@reduxjs/toolkit';

import { setSearchQuery, setSearchResults, resetResults } from './actions';

const defaultState = {
  query: '',
  results: {
    data: null,
    searchIndex: 0,
  },
};

export const search = createReducer(defaultState, {
  [setSearchQuery.type]: (state, action) => {
    state.query = action.payload;
  },
  [setSearchResults.type]: (state, action) => {
    state.results = {
      data: action.payload,
      searchIndex: state.results.searchIndex + 1,
    };
  },
  [resetResults.type]: (state) => {
    state.results = defaultState.results;
  },
});
