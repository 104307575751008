import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectIsAuthorized } from 'store/auth/selectors';
import { selectUserAvatar } from 'store/user/selectors';

import { BlackButtonOrLink } from 'components';
import Person from '../../../../images/person.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const UserButtonComponent = ({ avatar, onClick, isAuthorized, className }) => (
  <BlackButtonOrLink
    className={cx('component', className)}
    isRound
    onClick={onClick}
    animation={{ animated: false }}
  >
    {isAuthorized && avatar
      ? <div className={cx('avatar')} style={{ backgroundImage: avatar ? `url("${avatar}")` : undefined }} />
      : <Person />}
  </BlackButtonOrLink>
);

const mapStateToProps = (state) => ({
  isAuthorized: selectIsAuthorized(state),
  avatar: selectUserAvatar(state),
});

UserButtonComponent.propTypes = {
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  isAuthorized: PropTypes.bool,
};

export const UserButton = connect(mapStateToProps)(UserButtonComponent);
