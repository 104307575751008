import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Title = ({ title }) => {
  if (!title) return null;

  return (
    <Text className={cx('title')}>
      {title}
    </Text>
  );
};
