import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

/* Store */
import { selectActivePopupType, selectOverlayState } from 'store/page/selectors';
import { selectIsAuthorized } from 'store/auth/selectors';
import { selectDesktop } from 'store/selectors';

/* Components */
import { Logo, Wrapper, SearchPanel } from 'components';
import { UserButton } from './components';

/* Hooks */
import { usePopup } from 'hooks/usePopup';

const cx = classNames.bind(styles);

const withWrapper = (WrappedComponent) => ({ isDesktop, ...restProps }) => (
  <Wrapper width={1472} padding={isDesktop ? 44 : 20}>
    <WrappedComponent {...restProps} />
  </Wrapper>
);

const NavBarComponent = ({
  withOverlay,
  isDesktop,
  isAuthorized,
  className,
  hideAuth,
  hideSearch,
  activePopup,
}) => {
  const { openAuthPopup, openAccountPopup } = usePopup();

  const handleOpenPopup = () => {
    if (isAuthorized) {
      openAccountPopup();
      return;
    }

    openAuthPopup();
  };

  const userButtonDisplayConditions = !hideAuth && !activePopup && (!withOverlay || isDesktop);
  const searchDisplayConditions = !activePopup && !hideSearch;

  return (
    <div className={cx('container', className)}>
      {(!withOverlay || isDesktop) && <Logo className={cx('logo')} />}

      <div className={cx('buttons', withOverlay && 'activeSearch')}>
        {searchDisplayConditions && <SearchPanel className={cx('search')} />}

        {userButtonDisplayConditions && <UserButton className={cx('auth')} onClick={handleOpenPopup} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  withOverlay: selectOverlayState(state),
  isDesktop: selectDesktop(state),
  isAuthorized: selectIsAuthorized(state),
  activePopup: selectActivePopupType(state),
});

NavBarComponent.propTypes = {
  withOverlay: PropTypes.bool,
  isDesktop: PropTypes.bool,
  content: PropTypes.shape({
    logo: PropTypes.object,
    navbar: PropTypes.shape({
      buttons: PropTypes.array,
    }),
  }),
  isAuthorized: PropTypes.bool,
  activePopup: PropTypes.string,
};

export const NavBar = connect(mapStateToProps)(NavBarComponent);

export const WrappedNavBar = connect(mapStateToProps)(withWrapper(NavBar));
