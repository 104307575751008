import PropTypes from 'prop-types';

import { PrimaryButtonOrLink } from 'components';

export const SaveButton = ({ isMobile, disabled, onClick, children, className }) => (
  <PrimaryButtonOrLink
    className={className}
    size={isMobile ? 'small' : 'large'}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </PrimaryButtonOrLink>
);

SaveButton.propTypes = {
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
