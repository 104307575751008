import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { VerticalCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const VerticalArticleCard = ({ title, articlePath, image, tags, readTime, className }) => (
  <VerticalCard
    className={cx('component', className)}
    href={articlePath}
    image={image}
    imageClassName={cx('image')}
    tags={tags}
    info={<VerticalCard.Info className={cx('info')} runTime={readTime} />}
  >
    <VerticalCard.Title className={cx('title')}>
      {title}
    </VerticalCard.Title>
  </VerticalCard>
);

VerticalArticleCard.propTypes = {
  title: PropTypes.string,
  articlePath: PropTypes.string,
  image: PropTypes.string,
  readTime: PropTypes.string,
  tags: PropTypes.node,
};
