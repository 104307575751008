import { useMemo } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { CoursesRow, ShowMoreButton } from 'components';

export const Template = ({ courses, loaded, amount, fullyLoaded, onLoadMore }) => {
  if (!courses || !courses?.length) return null;

  return (
    <>
      <CoursesRow content={loaded?.length > 0 ? [...courses, ...loaded] : courses} shouldRedirect={false} />

      {!fullyLoaded && useMemo(() => (
        <ShowMoreButton
          total={amount}
          onClick={onLoadMore}
        />
      ), [onLoadMore])}
    </>
  );
};

Template.propTypes = {
  courses: PropTypes.array,
  loaded: PropTypes.array,
  amount: PropTypes.number,
  onLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
};
