import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useIsExternalModule } from 'hooks/useIsExternalModule';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Link = ({ routerLink, href, onClick, children, className, ...restProps }) => {
  const isRemoteModule = useIsExternalModule();
  const shouldUseRouterLink = routerLink && !isRemoteModule;

  return shouldUseRouterLink ? (
    <RouterLink to={href} className={className} onClick={onClick}>
      {children}
    </RouterLink>
  ) : (
    <a
      className={cx('component', className)}
      rel="noreferrer"
      href={href}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  href: PropTypes.string,
  routerLink: PropTypes.bool,
};

export const RouterLink = ({ to, onClick, className, children }) => (
  <ReactRouterLink to={to} className={cx('component', className)} onClick={onClick}>
    {children}
  </ReactRouterLink>
);
