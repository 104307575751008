import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { LectureCard, LectureSeriesCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const LecturesRow = ({
  lectures,
  lecturesSeries,
  shouldRedirect,
  className,
}) => (
  <div className={cx('container', className)}>
    {lectures?.map((lecture) => (
      <LectureCard
        key={lecture.id}
        shouldRedirect={shouldRedirect}
        className={cx('card')}
        {...lecture}
      />
    ))}

    {lecturesSeries?.map((lecture) => (
      <LectureSeriesCard
        key={lecture.id}
        shouldRedirect={shouldRedirect}
        className={cx('card', 'largeCard')}
        {...lecture}
      />
    ))}
  </div>
);

LecturesRow.propTypes = {
  content: PropTypes.array,
  shouldRedirect: PropTypes.bool,
  isTablet: PropTypes.bool,
  lectures: PropTypes.array,
  lecturesSeries: PropTypes.array,
};
