import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectUserName, selectUserEmail, selectUserAvatar } from 'store/user/selectors';
import { postAvatar } from 'store/user/actions';

/* Components */
import { Text } from 'components';
import { Avatar } from './Avatar';

import styles from './styles.pcss';
import { useMakeRequest } from 'hooks/useMakeRequest';

const cx = classNames.bind(styles);

const PersonalInfoComponent = ({ avatar, name, email, onChangeAvatar, className }) => {
  const makeRequest = useMakeRequest();

  const handleChangeAvatar = (e) => makeRequest(() => onChangeAvatar(e));

  return (
    <div className={cx('component', className)}>
      <Avatar
        className={cx('avatar')}
        avatar={avatar}
        onChange={handleChangeAvatar}
      />

      <div className={cx('info')}>
        <Text className={cx('name')}>
          {name}
        </Text>

        <Text className={cx('email')}>
          {email}
        </Text>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  name: selectUserName(state),
  email: selectUserEmail(state),
  avatar: selectUserAvatar(state),
});

const mapDispatchToProps = {
  onChangeAvatar: postAvatar,
};

PersonalInfoComponent.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  onChangeAvatar: PropTypes.func,
};

export const PersonalInfo = connect(mapStateToProps, mapDispatchToProps)(PersonalInfoComponent);
