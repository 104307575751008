import { connect } from 'react-redux';

/* Store */
import { selectWebinarSeries, selectWebinarSeriesAmount } from 'store/content/selectors';
import {
  loadMoreWebinarSeries,
  selectLoadedWebinarSeries,
  selectWebinarSeriesLoadedFlag,
} from 'store/pagination';

/* Components */
import { Template } from './Template';

const WebinarSeriesComponent = ({ series, loaded, amount, fullyLoaded, onLoadMore }) => (
  <Template
    content={series}
    loaded={loaded}
    amount={amount}
    fullyLoaded={fullyLoaded}
    onLoadMore={onLoadMore}
  />
);

const mapStateToProps = (state) => ({
  amount: selectWebinarSeriesAmount(state),
  series: selectWebinarSeries(state),
  loaded: selectLoadedWebinarSeries(state),
  fullyLoaded: selectWebinarSeriesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreWebinarSeries,
};

export const WebinarSeries = connect(mapStateToProps, mapDispatchToProps)(WebinarSeriesComponent);
