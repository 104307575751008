import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import routes from '../../routes';

/* Store */
import { selectLogo } from 'store/widgets';

/* Components */
import { Text, Link } from 'components';

const cx = classNames.bind(styles);

const LogoComponent = ({ logo, className }) => (
  <Link routerLink href={routes[0].path}>
    <div className={cx('container', className)}>
      <img src={logo?.icon} alt="Логотип Beeline" className={cx('icon')} />
      <Text className={cx('title')}>
        {logo?.title}
      </Text>
    </div>
  </Link>
);

LogoComponent.propTypes = {
  logo: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  logo: selectLogo(state),
});

export const Logo = connect(mapStateToProps)(LogoComponent);
