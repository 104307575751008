import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Hooks */
import { useVideoControls } from './useVideoControls';
import { useIFrame } from 'hooks/useIFrame';

/* Components */
import { Horizontal } from 'components/Scorm/components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Video = ({ src, handlePlay, notAuthorized }) => (
  <video
    onPlay={handlePlay}
    className={cx('video', { notAuthorized })}
    src={src}
    controls
    controlsList="nodownload"
  />
);

export const VideoBlock = ({ isExternal, thumbnail, src, className }) => {
  const { handlePlay, notAuthorized } = useVideoControls();
  const { handleIFrame } = useIFrame();

  return src ? (
    <div className={cx('container', className)} id="video" onClick={handleIFrame}>
      {isExternal ? (
        <Horizontal src={src} />
      ) : (
        <Video
          src={src}
          handlePlay={handlePlay}
          thumbnail={thumbnail}
          notAuthorized={notAuthorized}
        />
      )}
    </div>
  ) : <img className={cx('container')} src={thumbnail} width={800} alt="" />;
};

VideoBlock.propTypes = {
  thumbnail: PropTypes.string,
  src: PropTypes.string,
  isExternal: PropTypes.bool,
};
