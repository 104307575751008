import { createReducer } from '@reduxjs/toolkit';

import { setCurrentLecture } from 'store/lecture/actions';

export const lecture = createReducer({}, {
  [setCurrentLecture.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
