import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

/* Store */
import { selectInfoPanelLabels } from 'store/widgets';

/* Components */
import { InfoPiece } from './components/InfoPiece';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const InfoPanelComponent = ({
  labels,
  date,
  length,
  registered,
  placesLeft,
  readTime,
  views,
  className,
}) => (
  <div className={cx('component', className)}>
    {date && (
      <InfoPiece className={cx('item')} label={labels.date}>
        {date}
      </InfoPiece>
    )}

    {length && (
      <InfoPiece className={cx('item')} label={labels.length}>
        {length}
      </InfoPiece>
    )}

    {registered && (
      <InfoPiece className={cx('item')} label={labels.registered}>
        {registered}
      </InfoPiece>
    )}

    {placesLeft && (
      <InfoPiece className={cx('item')} label={labels.left}>
        {placesLeft}
      </InfoPiece>
    )}

    {readTime && (
      <InfoPiece className={cx('item')} label={labels.readTime}>
        {readTime}
      </InfoPiece>
    )}

    {views && (
      <InfoPiece className={cx('item')} label={labels.views}>
        {views}
      </InfoPiece>
    )}
  </div>
);

InfoPanelComponent.propTypes = {
  labels: PropTypes.object,
  date: PropTypes.string,
  length: PropTypes.string,
  readTime: PropTypes.string,
  registered: PropTypes.number,
  placesLeft: PropTypes.number,
  views: PropTypes.number,
};

const mapStateToProps = (state) => ({
  labels: selectInfoPanelLabels(state),
});

export const InfoPanel = connect(mapStateToProps)(InfoPanelComponent);
