import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectMainAuthTexts, selectRegistrationTexts } from 'store/widgets';

/* Components */
import { LoginForm, RegistrationForm, Tab, FromBottomSlider } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FormStepComponent = ({
  active,
  onClick,
  onNextStep,
  registration,
  texts,
  tabs,
}) => (
  <div className={cx('component')}>
    <div className={cx('tabs')}>
      {tabs?.map((tab) => (
        <Tab key={tab} active={active === tab} onClick={() => onClick(tab)}>
          {texts?.[tab]?.tabName}
        </Tab>
      ))}
    </div>

    <FromBottomSlider active={active}>
      <FromBottomSlider.Slide id="registration">
        <RegistrationForm
          content={registration}
          changeTab={onClick}
          onNextStep={onNextStep}
        />
      </FromBottomSlider.Slide>
      <FromBottomSlider.Slide id="login">
        <LoginForm onNextStep={onNextStep} changeTab={onClick} />
      </FromBottomSlider.Slide>
    </FromBottomSlider>
  </div>
);

const mapStateToProps = (state) => ({
  registration: selectRegistrationTexts(state),
  texts: selectMainAuthTexts(state),
  tabs: Object.keys(selectMainAuthTexts(state)),
});

FormStepComponent.propTypes = {
  active: PropTypes.string,
  onClick: PropTypes.func,
  onNextStep: PropTypes.func,
  registration: PropTypes.object,
};

export const FormStep = connect(mapStateToProps)(FormStepComponent);
