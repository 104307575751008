import { useDispatch, useSelector } from 'react-redux';

/* Api */
import { subscribeToEvent } from 'api/requests';

/* Store */
import { setResultStatus } from 'store/page';
import { selectUserId } from 'store/user/selectors';

/* Hooks */
import { usePopup } from 'hooks/usePopup';
import { useMakeRequest } from 'hooks/useMakeRequest';

/* Constants */
import { SUBSCRIPTION_STATUS, POPUP_STEP, POPUP_TYPE, EVENT_SUBSCRIPTION_ERRORS } from 'constants/constants';
import { TOKEN_ERRORS } from 'constants/authorizationErrors';
import { selectAuthRefreshEnabled } from 'store/widgets';

export const useEventSubPopup = () => {
  const dispatch = useDispatch();
  const id = useSelector(selectUserId);
  const authRefreshEnabled = useSelector(selectAuthRefreshEnabled);
  const makeRequest = useMakeRequest();
  const { openPopup, closePopup } = usePopup();

  const handleOpen = (type, eventId) => makeRequest(() => subscribeToEvent({ eventId, id }))
    .then(() => {
      dispatch(setResultStatus(SUBSCRIPTION_STATUS.SUCCESS));

      openPopup({ type: POPUP_TYPE.eventSubscription, step: POPUP_STEP.ready, eventType: type });
    })
    .catch(({ response }) => {
      if (authRefreshEnabled && TOKEN_ERRORS[response?.data?.error]) {
        openPopup({ type: POPUP_TYPE.auth, step: POPUP_STEP.form });
        return;
      }

      if (EVENT_SUBSCRIPTION_ERRORS[response?.data?.error]) {
        dispatch(setResultStatus(SUBSCRIPTION_STATUS[response?.data?.error]));
      } else {
        dispatch(setResultStatus(SUBSCRIPTION_STATUS.ERROR));
      }

      openPopup({ type: POPUP_TYPE.eventSubscription, step: POPUP_STEP.ready, eventType: type });
    });

  const handleClose = () => {
    closePopup();
  };

  return { open: handleOpen, close: handleClose };
};
