import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Author = ({ image, name, description, renderInfo, className }) => (
  <div className={cx('component', className)}>
    <div className={cx('icon')} style={{ backgroundImage: `url("${image}")` }} />

    {renderInfo && (
      <div className={cx('info')}>
        {name && (
          <Text className={cx('name')}>
            {name}
          </Text>
        )}

        {description && (
          <Text className={cx('description')}>
            {description}
          </Text>
        )}
      </div>
    )}
  </div>
);

Author.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  renderInfo: PropTypes.bool,
};
