import { selectMainTexts, selectMobileIdAuthSetting } from 'store/widgets';
import { selectContentAmount, selectFutureEventsAmount } from 'store/content/selectors';
import { createSelector } from '@reduxjs/toolkit';

export const selectMainTitle = (state) => selectMainTexts(state).title;
export const selectNavbarTexts = (state) => selectMainTexts(state).navbar;
export const selectMainAuthTexts = (state) => selectMainTexts(state).auth;
export const selectDisclaimerText = (state) => selectMainTexts(state).disclaimer?.text;
export const selectLogo = (state) => selectMainTexts(state).logo;
export const selectShareButtonText = (state) => selectMainTexts(state).shareButton?.defaultText;
export const selectCopySuccessText = (state) => selectMainTexts(state).shareButton?.success;
export const selectMobileIdButton = (state) => selectMainTexts(state).mobileIdButton;
export const selectMobileIdText = (state) => selectMainTexts(state).mobileIdPendingText;
export const selectLoginTexts = (state) => selectMainTexts(state).auth?.login;
export const selectRegistrationTexts = (state) => selectMainTexts(state).auth?.registration;
export const selectDefaultPopupImage = (state) => selectRegistrationTexts(state)?.image;
export const selectInfoPanelLabels = (state) => selectMainTexts(state)?.infoPanelLabels;
export const selectPastEventsLabel = (state) => selectMainTexts(state)?.pastEventsLabel;
export const selectNavigation = (state) => selectMainTexts(state)?.navigation;
export const selectMainPageSlider = (state) => selectMainTexts(state)?.slider;
export const selectMainPageSliderAutoSwitch = (state) => selectMainPageSlider(state)?.autoSwitch;
export const selectMainPageBanners = (state) => selectMainPageSlider(state)?.banners;
export const selectSendEmailsInput = (state) => selectMainTexts(state)?.sendEmails;
export const selectDocumentsLabel = (state) => selectMainTexts(state)?.documentsLabel;
export const selectRelatedContentTitle = (state) => selectMainTexts(state)?.relatedContentTitle;
export const selectSearchPageTitle = (state) => selectMainTexts(state)?.searchPageTitle;
export const selectSearchNotFound = (state) => selectMainTexts(state)?.searchNotFound;
export const selectShowMoreText = (state) => selectMainTexts(state)?.showMoreText;
export const selectInputWarningText = (state) => selectMainTexts(state)?.inputWarningText;
export const selectRepeatWarningText = (state) => selectMainTexts(state)?.repeatEmailConfirmationError;

export const selectNavigationMenu = (state) => selectNavigation(state)?.menu;
export const selectNavigationTabs = (state) => selectNavigation(state)?.tabs;

export const selectNavigationContentButtons = (state) => selectNavigation(state)?.contentButtons.reduce((acc, button) => {
  if (button.key === 'futureEvents') {
    acc.push({ amount: selectFutureEventsAmount(state), ...button });
  } else {
    acc.push({ amount: selectContentAmount(state, button.key), ...button });
  }

  return acc;
}, []);

export const selectLoginOptions = createSelector(
  [selectLoginTexts, selectMobileIdAuthSetting],
  ({ options }, hideMobileId) => hideMobileId ? { sms: options?.sms } : options )
