import { createReducer } from '@reduxjs/toolkit';

import {
  setFormData,
  setAuthorized,
  setError,
  setMobileIdAuth,
  resetError,
  setLoginInProgress,
  resetFormData,
} from 'store/auth/actions';
import { getCookie } from 'utils/cookie';

const defaultState = {
  form: {
    name: '',
    phone: '',
    email: '',
    company: '',
  },
  mobileIdLoginInProgress: false,
  loginInProgress: false,
  isAuthorized: !!getCookie('token'),
  error: {
    phoneInput: null,
    codeInput: null,
    mobileId: null,
  },
};

export const auth = createReducer(defaultState, {
  [setFormData.type]: (state, action) => {
    state.form = ({
      ...state.form,
      ...action.payload,
    });
  },
  [setAuthorized.type]: (state, action) => {
    state.isAuthorized = !!action.payload;
  },
  [setError.type]: (state, action) => {
    state.error = {
      ...state.error,
      ...action.payload,
    };
  },
  [setMobileIdAuth.type]: (state, action) => {
    state.mobileIdLoginInProgress = action.payload;
  },
  [setLoginInProgress.type]: (state, action) => {
    state.loginInProgress = action.payload;
  },
  [resetError.type]: (state) => {
    state.error = defaultState.error;
  },
  [resetFormData.type]: (state) => {
    state.form = defaultState.form;
  }
});
