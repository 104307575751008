import classNames from 'classnames/bind';

import { Logo } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const NavBar = ({ withLogo = true, closeButton, className }) => (
  <div className={cx('component', { withLogo }, className)}>
    {withLogo && <Logo />}
    {closeButton}
  </div>
);
