import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Label = ({ label }) => (
  <Text className={cx('label')}>
    {label}
  </Text>
);

Label.propTypes = {
  label: PropTypes.string,
};
