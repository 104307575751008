import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import {
  selectFooterCopyright,
  selectFooterDesignBy,
  selectFooterInputSetting,
  selectFooterLinks
} from 'store/widgets';
import { selectDisplaySendEmailsInput } from 'store/page/selectors';
import { selectDesktop } from 'store/selectors';

/* Components */
import { SendEmailsInput, Wrapper } from 'components';
import { Links, Info } from './components';
import { FForm } from 'components/FForm';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FooterComponent = ({ links, copyright, designBy, isDesktop, displayInput, className }) => (
  <Wrapper className={cx('component', className)} width={1472} padding={isDesktop ? 44 : 20}>
    {displayInput && (
      <FForm className={cx('form')}>
        <SendEmailsInput className={cx('inputContainer')} type="email" inputClassName={cx('input')} />
      </FForm>
    )}

    <div className={cx('footer')}>
      {links && <Links links={links} className={cx('links')} />}

      <div className={cx('info')}>
        {copyright && (
          <Info className={cx('infoItem')}>
            {copyright}
          </Info>
        )}

        {designBy && (
          <Info className={cx('infoItem')}>
            {designBy}
          </Info>
        )}
      </div>
    </div>
  </Wrapper>
);

const mapStateToProps = (state) => ({
  links: selectFooterLinks(state),
  copyright: selectFooterCopyright(state),
  designBy: selectFooterDesignBy(state),
  isDesktop: selectDesktop(state),
  displayInput: !selectFooterInputSetting(state) && selectDisplaySendEmailsInput(state),
});

FooterComponent.propTypes = {
  links: PropTypes.array,
  copyright: PropTypes.string,
  designBy: PropTypes.string,
  isDesktop: PropTypes.bool,
  displayInput: PropTypes.bool,
}

export const Footer = connect(mapStateToProps)(FooterComponent);
