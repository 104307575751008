import { PrimaryButtonOrLink, Text } from 'components';

export const Button = ({ text, onClick }) => {
  if (!text) return null;

  return (
    <PrimaryButtonOrLink isAdaptive size="large" onClick={onClick}>
      <Text isBlack>
        {text}
      </Text>
    </PrimaryButtonOrLink>
  );
};
