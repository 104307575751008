import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ErrorMessage = ({ error, message }) => error && message ? (
  <Text className={cx('message')} isError>
    {message}
  </Text>
) : null;

ErrorMessage.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
};
