import { useState } from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useAnimation } from './useAnimation';

import { Link, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const DocumentDownload = ({ className, link, text, size }) => {
  const [downloaded, setDownloaded] = useState(false);

  const {
    container,
    iconContainer,
    icon,
    handleHover,
    handleInteraction,
  } = useAnimation({ downloaded, setDownloaded });

  return (
    <Link className={cx('link', className)} href={link} download>
      <animated.div
        className={cx('container', className)}
        style={{ ...container }}
        {...handleHover()}
        {...handleInteraction}
      >
        <animated.div className={cx('iconContainer')} style={{ ...iconContainer }} {...handleHover()}>
          <div className={cx('icon', { downloaded })}>
            {icon}
          </div>
        </animated.div>
        {text && (
          <div className={cx('content')}>
            <Text className={cx('text')}>
              {text}
            </Text>
            {size && (
              <Text className={cx('size')}>
                {size}
              </Text>
            )}
          </div>
        )}
      </animated.div>
    </Link>
  );
};

DocumentDownload.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  isMobile: PropTypes.bool,
};
