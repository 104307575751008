import { createSelector } from '@reduxjs/toolkit';
import { selectWebinarPageTexts } from 'store/widgets';
import { selectWebinarAuthors, selectWebinarStatus } from 'store/webinar/selectors';

export const selectWebinarPageButton = (state) => (
  selectWebinarStatus(state)
    ? selectWebinarPageTexts(state).button?.register
    : selectWebinarPageTexts(state).button?.watch);

export const selectWebinarPageSpeakersLabel = createSelector(
  [selectWebinarPageTexts, selectWebinarAuthors],
  (texts, authors) => (authors?.length > 1 ? texts?.speakers?.multiple : texts?.speakers?.single),
);

export const selectWebinarsPageTitle = (state) => selectWebinarPageTexts(state).title;
