import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Author } from './Author';
import { PartnersBlock } from './PartnersBlock';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CardCreators = ({ authors, partners, shouldRenderInfo, className }) => {
  if (!authors && !partners) return null;

  if (authors) {
    return (
      <div className={cx('authors', className)}>
        {authors.map(({ id, image, name, description }) => (
          <Author
            key={id}
            className={cx('author')}
            renderInfo={shouldRenderInfo}
            name={name}
            description={description}
            image={image}
          />
        ))}
      </div>
    );
  }

  if (partners) {
    return <PartnersBlock className={cx('partners', className)} partners={partners} />;
  }
};

CardCreators.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  })),
  partners: PropTypes.array,
};
