import classNames from 'classnames/bind';
import PreloaderImage from './preloader.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Preloader = ({ className }) => (
  <div className={cx('component', className)}>
    <div className={cx('preloader')}>
      <PreloaderImage />
    </div>
  </div>
);
