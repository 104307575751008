import { useEffect, useState } from 'react';
import { useEventListener } from 'hooks/useEventListener';

export const useFixedSize = (ref, innerRef, sizes) => {
  if (typeof window === 'undefined') return null;

  const [state, setState] = useState({
    width: undefined,
    height: undefined,
    scale: 1,
  });

  const resizeHandler = () => {
    const innerRation = (window?.innerWidth - 40) / sizes.width;
    const height = sizes.height * innerRation;

    const scaleW = Math.min(1, ref.current?.offsetWidth / sizes.width);

    setState(() => ({
      height,
      ratio: innerRation,
      scale: Math.min(scaleW),
    }));
  };

  useEventListener('resize', resizeHandler);

  useEffect(resizeHandler, []);

  return state;
};
