import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Hooks */
import { usePopup } from 'hooks/usePopup';

/* Constants */
import { POPUP_STEP } from 'constants/constants';

/* Components */
import { Popup, Step, Wizard, ResultStep } from 'components';
import { ChangeEmail, ChangePhone, Form } from './Steps';

/* Store */
import {
  selectActivePopupStep,
  selectDisplayImage,
  selectProfilePopupOpened,
  selectResultStatus,
  selectUserAccountPopupImage,
} from 'store/page/selectors';
import { changePopupStep, resetResultStatus } from 'store/page';
import { resetErrors } from 'store/auth/actions';
import { selectDesktop, selectMobile } from 'store/selectors';
import { selectProfileResultScreens } from 'store/widgets';
import { deleteChanges } from 'store/user';
import { selectUserChanges } from 'store/user/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PersonalAccountPopupComponent = ({
  displayImage,
  popupImage,
  resultStatus,
  activeStep,
  onResetError,
  onChangeStep,
  onResetResult,
  isDesktop,
  isMobile,
  isFullView,
  isOpened,
  profileResultScreens,
  userChanges,
  onDeleteChanges,
}) => {
  const { closePopup } = usePopup();

  const handleClose = () => {
    onResetError();

    if (resultStatus) {
      onResetResult();
    }

    if (userChanges) {
      onDeleteChanges();
    }

    closePopup();
  };

  useEffect(() => {
    if (resultStatus && profileResultScreens[resultStatus]) {
      onChangeStep(POPUP_STEP.ready);
    }
  }, [resultStatus])

  return (
    <Wizard
      className={cx('wrapper')}
      activeStep={activeStep}
      onClose={handleClose}
      opened={isOpened}
      image={displayImage && <Popup.Image image={popupImage} />}
      closeButton={(
        <Popup.CloseButton
          mode={isDesktop ? 'secondary' : 'black'}
          onClose={handleClose}
        />
      )}
      withNavBar
      withLogo={isFullView}
      isFullView={isFullView}
    >
      <Step name={POPUP_STEP.form}>
        <Form />
      </Step>

      <Step name={POPUP_STEP.changePhone}>
        <ChangePhone />
      </Step>

      <Step name={POPUP_STEP.changeEmail}>
        <ChangeEmail />
      </Step>

      <Step name={POPUP_STEP.ready}>
        <ResultStep texts={profileResultScreens} onClose={handleClose} />
      </Step>
    </Wizard>
  );
};

PersonalAccountPopupComponent.propTypes = {
  popupImage: PropTypes.string,
  resultStatus: PropTypes.string,
  activeStep: PropTypes.string,
  onChangeStep: PropTypes.func,
  onResetError: PropTypes.func,
  onResetResult: PropTypes.func,
  onDeleteChanges: PropTypes.func,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isFullView: PropTypes.bool,
  isOpened: PropTypes.bool,
  profileResultScreens: PropTypes.object,
  userChanges: PropTypes.object,
};

const mapStateToProps = (state) => ({
  displayImage: selectDisplayImage(state),
  popupImage: selectUserAccountPopupImage(state),
  profileResultScreens: selectProfileResultScreens(state),
  resultStatus: selectResultStatus(state),
  activeStep: selectActivePopupStep(state),
  isDesktop: selectDesktop(state),
  isMobile: selectMobile(state),
  isFullView: !selectDesktop(state) && selectActivePopupStep(state) !== 'ready',
  isOpened: selectProfilePopupOpened(state),
  userChanges: selectUserChanges(state),
});

const mapDispatchToProps = {
  onChangeStep: changePopupStep,
  onResetError: resetErrors,
  onResetResult: resetResultStatus,
  onDeleteChanges: deleteChanges,
};

export const PersonalAccountPopup = connect(mapStateToProps, mapDispatchToProps)(PersonalAccountPopupComponent);
