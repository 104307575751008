import axios from 'axios';
import endpoints from '../endpoints';
import {setAuthTokenCookie, setRefreshTokenCookie} from "utils/cookie";

export const requestUser = () => axios.get(endpoints.userProfile);

export const postUserAvatar = async ({ file }) => {
  const formData = new FormData();
  formData.set('file', file, file.name);

  return axios.post(endpoints.postAvatar, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const changePhone = (phoneNumber) => axios.post(endpoints.phoneChange, { phoneNumber }).then(({ data }) => data);

export const confirmPhoneChange = ({ code, confirmationToken }) => axios.post(endpoints.confirmPhoneChange, {
  code,
  confirmationToken,
}).then(({ data }) => {
  setAuthTokenCookie(data.accessToken);

  if (data.refreshToken) {
    setRefreshTokenCookie(data.refreshToken);
  }

  return data;
});

