import { connect } from 'react-redux';

/* Store */
import { selectFutureConferences, selectFutureConferencesAmount } from 'store/content/selectors';
import {
  loadMoreFutureConferences,
  selectFutureConferencesLoadedFlag,
  selectLoadedFutureConferences,
} from 'store/pagination';

/* Components */
import { Template } from './Template';

const FutureConferencesComponent = ({ conferences, loaded, amount, fullyLoaded, onLoadMore, className }) => (
  <Template
    content={conferences}
    loaded={loaded}
    amount={amount}
    fullyLoaded={fullyLoaded}
    onLoadMore={onLoadMore}
    className={className}
  />
);

const mapStateToProps = (state) => ({
  amount: selectFutureConferencesAmount(state),
  conferences: selectFutureConferences(state),
  loaded: selectLoadedFutureConferences(state),
  fullyLoaded: selectFutureConferencesLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMoreFutureConferences,
};

export const FutureConferences = connect(mapStateToProps, mapDispatchToProps)(FutureConferencesComponent);
