import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { animated, Spring } from 'react-spring';
import { isIE } from 'utils/detectIE';

import { selectDesktop } from 'store/selectors';

import { Wrapper } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PanelLayoutComponent = ({ transformValue, show, isDesktop, withBorder, transparent, children }) => (
  <Spring
    from={{ transform: `translateY(-${transformValue}px)`, opacity: 0 }}
    to={{
      transform: show ? 'translateY(0px)' : `translateY(-${transformValue}px)`,
      opacity: show ? 1 : 0,
    }}
  >
    {(style) => (
      <animated.div className={cx('component', { withBorder, transparent }, isIE() && 'IE')} style={style}>
        <Wrapper className={cx('wrapper')} width={1472} padding={isDesktop ? 44 : 0}>
          {children}
        </Wrapper>
      </animated.div>
    )}
  </Spring>
);

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
});

export const PanelLayout = connect(mapStateToProps)(PanelLayoutComponent);