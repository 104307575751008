export const MASKS = {
  phone: {
    mask: '___ ___ __ __',
    regEx: /\+7|\D/g,
  },
  captcha: {
    mask: '____',
    regEx: /\W/g,
  },
  code: {
    mask: '_ _ _ _',
    regEx: /\D/g,
  },
  mobileIdCode: {
    mask: '_ _ _ _ _ _',
    regEx: /\D/g,
  },
};

export const MAX_LENGTHS = {
  phone: 16,
  captcha: 4,
  code: 7,
  mobileIdCode: 11,
};
