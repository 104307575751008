import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { Conference } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ConferencesRow = ({ conferences }) => conferences?.map((conference) => (
  <Conference key={conference.alias} className={cx('card')} {...conference} />
));

ConferencesRow.propTypes = {
  conferences: PropTypes.array,
};
