import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Utils */
import endpoints from 'api/endpoints';
import { compose } from 'utils/fn';
import { scrollTo } from 'utils/scrollTo';

/* Hocs */
import { withDataLoading, Endpoint } from 'hocs';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectCoursePageButton, selectCoursePageSpeakersLabel } from 'store/widgets';
import {
  selectCourseTitle,
  selectCourseTheme,
  selectCourseTags,
  selectCourseContent,
  selectCourseBackgroundImage,
  selectCourseViews,
  selectCourseLength,
  selectCourseVideo,
  selectCourseSource,
  selectCourseBanners,
  selectCourseDocuments,
  selectCourseTeachers,
  selectCourseRelatedContent,
  selectCourseRelatedContentSlides,
} from 'store/course/selectors';

/* Components */
import {
  Wrapper,
  ContentContainer,
  FloatingUpButton,
  MenuButton,
  VideoBlock,
  BannersBlock,
  SpeakersBlock,
  Documents,
  Disclaimer,
  DeskNavigation as Navigation,
  RelatedContent,
  CourseCard,
  Scorm,
} from 'components';
import { PageHeader } from 'components/PageHeader';
import { FloatingPanel } from './components/FloatingPanel';
import { useFloatingButton } from 'components/FloatingUpButton/useFloatingButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CoursePage = ({
  image,
  title,
  tags,
  theme,
  views,
  length,
  buttonText,
  content,
  isDesktop,
  thumbnail,
  video,
  isExternal,
  banners,
  teachers,
  documents,
  relatedContent,
  slides,
  blockLabel,
}) => {
  const container = useRef(null);

  const { renderButton } = useFloatingButton(container);

  const handleClick = () => scrollTo('video');

  return (
    <>
      <Wrapper padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
        <Navigation className={cx('navigation')} />
      </Wrapper>

      <PageHeader
        className={cx('header')}
        image={(
          <PageHeader.Image
            className={cx('background')}
            src={image}
            absolute
          />
        )}
        tags={<PageHeader.Tags className={cx('tags')} tags={tags} />}
        title={(
          <PageHeader.Title className={cx('title')}>
            {title}
          </PageHeader.Title>
        )}
        theme={(
          <PageHeader.Theme className={cx('theme')}>
            {theme}
          </PageHeader.Theme>
        )}
        info={(
          <PageHeader.InfoPanel
            views={views}
            length={length}
          />
        )}
        button={(
          <PageHeader.Button className={cx('button')} onClick={handleClick}>
            {buttonText}
          </PageHeader.Button>
        )}
      />
      <div ref={container}>
        <Wrapper className={cx('content')} padding={isDesktop ? 0 : 20} fullWidth={!isDesktop} width={800}>
          <Scorm />

          <VideoBlock className={cx('media')} thumbnail={thumbnail} src={video} isExternal={isExternal} />

          <div className={cx('innerContent')}>
            <ContentContainer className={cx('parsedContent')}>
              {content}
            </ContentContainer>

            <SpeakersBlock className={cx('speakers')} speakers={teachers} blockLabel={blockLabel} />

            <Documents className={cx('documents')} documents={documents} />

            <Disclaimer className={cx('disclaimer')} />
          </div>
        </Wrapper>

        <Wrapper className={cx('banners')} padding={isDesktop ? 44 : 20} fullWidth={!isDesktop} width={1472}>
          <BannersBlock banners={banners} />

          {relatedContent && (
            <RelatedContent slides={slides}>
              {relatedContent.map((course) => (
                <CourseCard
                  className={cx('card')}
                  key={course.id}
                  shouldRedirect
                  isBottomImage
                  {...course}
                />
              ))}
            </RelatedContent>
          )}
        </Wrapper>

        {isDesktop && renderButton && <FloatingUpButton />}

        <FloatingPanel container={container} onMainButtonClick={handleClick} />
      </div>

      {!isDesktop && <MenuButton href="/menu" isLink routerLink />}
    </>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  image: selectCourseBackgroundImage(state),
  title: selectCourseTitle(state),
  tags: selectCourseTags(state),
  theme: selectCourseTheme(state),
  views: selectCourseViews(state),
  length: selectCourseLength(state),
  buttonText: selectCoursePageButton(state),
  content: selectCourseContent(state),
  video: selectCourseVideo(state),
  isExternal: selectCourseSource(state),
  banners: selectCourseBanners(state),
  teachers: selectCourseTeachers(state),
  blockLabel: selectCoursePageSpeakersLabel(state),
  documents: selectCourseDocuments(state),
  relatedContent: selectCourseRelatedContent(state),
  slides: selectCourseRelatedContentSlides(state),
});

CoursePage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  length: PropTypes.string,
  buttonText: PropTypes.string,
  thumbnail: PropTypes.string,
  video: PropTypes.string,
  content: PropTypes.string,
  blockLabel: PropTypes.string,
  views: PropTypes.number,
  slides: PropTypes.number,
  tags: PropTypes.array,
  banners: PropTypes.array,
  teachers: PropTypes.array,
  documents: PropTypes.array,
  relatedContent: PropTypes.array,
  isDesktop: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export const Course = compose(
  withDataLoading(({ alias }) => ({
    course: new Endpoint(`${endpoints.requestCourse}/?alias=${alias}`),
  })),
  connect(mapStateToProps, null),
)(CoursePage);
