import classNames from 'classnames/bind';

import { useIFrame } from 'hooks/useIFrame';

import { IFrame } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Horizontal = ({ src }) => {
  const { handleIFrame } = useIFrame();

  return (
    <div className={cx('scorm')} onClick={handleIFrame}>
      <IFrame src={src} className={cx('iframe')} />
    </div>
  );
};
