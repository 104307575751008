import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';

/* Api */
import { repeatEmail } from 'api/requests/auth';

/* Store */
import { selectIsConfirmationError, selectIsEmailNotSend } from 'store/page/selectors';
import { setPopup, setResultStatus } from 'store/page';

/* Components */
import { Text, Timer } from 'components';
import Alert from '../../../../images/alert.svg';

/* Constants */
import { POPUP_STEP, POPUP_TYPE, CONFIRM_CODE_FLAG } from 'constants/constants';

/* Hooks */
import { useTimer } from 'hooks/useTimer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SendEmailComponent = ({ text, onClose, isConfirmationError, isEmailNotSend }) => {
  if (!text) return null;

  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation()?.search || '';
  const code = search.substring(search.indexOf(CONFIRM_CODE_FLAG) + CONFIRM_CODE_FLAG.length);
  const { timeMessage, time } = useTimer(600);

  const handleClick = () => {
    if (isConfirmationError) {
      repeatEmail({ code })
        .then(() => {
          onClose();
          history.replace({ search: '' });
        });
      return;
    }

    if (isEmailNotSend) {
      dispatch(setPopup({ type: POPUP_TYPE.userAccount, step: POPUP_STEP.changeEmail }));
      dispatch(setResultStatus(''));
    }
  };

  return (
    <div className={cx('container')}>
      <Timer
        className={cx('timer')}
        time={time}
        message={timeMessage}
        onClick={handleClick}
        icon={<Alert className={cx('alert')} />}
      >
        <Text className={cx('text')}>
          {text}
        </Text>
      </Timer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isConfirmationError: selectIsConfirmationError(state),
  isEmailNotSend: selectIsEmailNotSend(state),
});

export const SendEmail = connect(mapStateToProps)(SendEmailComponent);
