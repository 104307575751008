import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Hooks */
import { useFloatingInfoPanel } from './useFloatingInfoPanel';

/* Constants */
import { PANELS } from 'constants/components';

/* Store */
import { selectMobile } from 'store/selectors';

/* Components */
import { ShareButton } from 'components';
import {
  Title,
  BackButton,
  PanelLayout,
  PageNavigation,
  GlobalNavigation,
} from './components';

const FloatingInfoPanelComponent = ({
  container,
  title,
  backButton,
  shareButton,
  mainButton,
  transparent,
  defaultPanel,
  isMobile,
}) => {
  if (typeof window === 'undefined') return null;

  const { panel, renderPanel, scrollUp } = useFloatingInfoPanel({ ref: container, defaultPanel });

  const transformValue = useMemo(() => (isMobile ? 200 : 250), [isMobile]);

  if (!panel || typeof window === 'undefined') return null;

  return (
    <>
      <PanelLayout
        show={renderPanel && panel === PANELS.pageNavigation}
        transformValue={transformValue}
        withBorder
      >
        <PageNavigation
          scrollUp={isMobile ? scrollUp : true}
          title={title}
          backButton={backButton}
          shareButton={shareButton}
          mainButton={mainButton}
        />
      </PanelLayout>

      <PanelLayout
        show={renderPanel && panel === PANELS.globalNavigation}
        transformValue={transformValue}
        transparent={transparent}
      >
        <GlobalNavigation />
      </PanelLayout>
    </>
  );
};

FloatingInfoPanelComponent.propTypes = {
  defaultPanel: PropTypes.oneOf(Object.keys(PANELS)),
  title: PropTypes.node,
  backButton: PropTypes.node,
  shareButton: PropTypes.node,
  mainButton: PropTypes.node,
  isMobile: PropTypes.bool,
  transparent: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
});

export const FloatingInfoPanel = connect(mapStateToProps)(FloatingInfoPanelComponent);

FloatingInfoPanel.Title = Title;
FloatingInfoPanel.BackButton = BackButton;
FloatingInfoPanel.ShareButton = ShareButton;
