import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { PersonCard, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SpeakersBlock = ({ speakers, blockLabel, className }) => {
  if (!speakers) return null;

  return (
    <div className={cx('container', className)}>
      <Text className={cx('label')}>
        {blockLabel}
      </Text>
      {speakers.map((speaker) => (
        <PersonCard key={speaker.id} className={cx('speaker')} {...speaker} />
      ))}
    </div>
  );
};

SpeakersBlock.propTypes = {
  blockLabel: PropTypes.string,
  speakers: PropTypes.array,
};
