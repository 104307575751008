import React from 'react';
import PropTypes from 'prop-types';
import { StaticRouter, BrowserRouter } from 'react-router-dom';

export function withReactRouter() {
  const Router = ({ requestUrl, context, children }) => (
    typeof window === 'undefined' ? (
      <StaticRouter location={requestUrl} context={context}>
        {children}
      </StaticRouter>
    ) : (
      <BrowserRouter>
        {children}
      </BrowserRouter>
    )
  );

  Router.propTypes = {
    children: PropTypes.node,
  };

  return (WrappedComponent) => (
    ({ requestUrl, context, ...props }) => (
      <Router requestUrl={requestUrl} context={context}>
        <WrappedComponent {...props} requestUrl={requestUrl} />
      </Router>
    )
  );
}
