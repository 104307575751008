import { connect } from 'react-redux';
import { animated, Spring } from 'react-spring';
import classNames from 'classnames/bind';

import { selectDesktop } from 'store/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AnimatedContainerComponent = ({ buttonMode, borderRadius, isDesktop, className, children }) => {
  if (isDesktop) {
    return (
      <Spring
        from={{
          width: '0%',
          borderRadius: '0px',
          opacity: 0,
        }}
        to={{
          width: buttonMode ? '0%' : '100%',
          borderRadius: buttonMode ? '0px' : `${borderRadius}px`,
          opacity: buttonMode ? 0 : 1,
        }}
      >
        {(style) => (
          <animated.div className={cx('container', className)} style={style}>
            {children}
          </animated.div>
        )}
      </Spring>
    );
  }

  return !buttonMode && (
    <div className={cx('container', className)}>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
});

export const AnimatedContainer = connect(mapStateToProps)(AnimatedContainerComponent);
