import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Utils */
import { compose } from 'utils/fn';
import endpoints from 'api/endpoints';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectWebinarSeriesTitle, selectWebinarSeriesWebinars } from 'store/series';

/* Hocs */
import { withDataLoading, Endpoint} from 'hocs';

/* Components */
import { WebinarRow } from 'components';
import { PageTemplate } from 'pages/ContentList';

const WebinarsSeriesComponent = ({
  isDesktop,
  title,
  content,
}) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/webinar_page_banner.png"
  >
    {content && (
      <WebinarRow
        content={content}
        shouldRenderInfo={isDesktop}
        shouldRedirect={false}
      />
    )}
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  isDesktop: selectDesktop(state),
  title: selectWebinarSeriesTitle(state),
  content: selectWebinarSeriesWebinars(state),
});

WebinarsSeriesComponent.propTypes = {
  isDesktop: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.array,
};

export const WebinarsSeries = compose(
  withDataLoading(({ alias }) => ({
    webinarSeries: new Endpoint(`${endpoints.requestWebinarSeries}/?alias=${alias}`),
  })),
  connect(mapStateToProps, null),
)(WebinarsSeriesComponent);
