import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectWebinarsPageTitle } from 'store/widgets';

/* Components */
import { PageTemplate } from '../PageTemplate';
import { FutureWebinars, PastWebinars, WebinarSeries } from 'pages/ConnectedContent';

import styles from '../styles.pcss';
const cx = classNames.bind(styles);

const WebinarsComponent = ({ title }) => (
  <PageTemplate
    title={title}
    // image="//security-api.ru/st/QP/Beelinelab/images/webinar_page_banner.png"
  >
    <WebinarSeries />

    <FutureWebinars className={cx('futureBlock')} />

    <PastWebinars displayLabel />
  </PageTemplate>
);

const mapStateToProps = (state) => ({
  title: selectWebinarsPageTitle(state),
});

WebinarsComponent.propTypes = {
  title: PropTypes.string,
};

export const Webinars = connect(mapStateToProps)(WebinarsComponent);
