import classNames from 'classnames/bind';
import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PersonalInfo = ({ name, description, className }) => (
  <div className={cx('component', className)}>
    <Text className={cx('name')}>
      {name}
    </Text>
    <Text className={cx('description')}>
      {description}
    </Text>
  </div>
);
