import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectDesktop } from 'store/selectors';
import { selectWebinarPageTexts } from 'store/widgets';

/* Components */
import { VerticalCard } from 'components';

/* Hooks */
import { useEventSubPopup } from 'hooks/useEventSubPopup';
import { useRestrictedAccess } from 'hooks/useRestricredAccess';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WebinarCardComponent = ({
  id,
  title,
  shouldRenderInfo,
  videoLength,
  date,
  tags,
  registered,
  authors,
  webinarPath,
  buttonText,
  isDesktop,
  isFuture,
  className,
  isLastEmptyCard,
}) => {
  const { open } = useEventSubPopup();
  const { handleRestrictedAccess } = useRestrictedAccess();

  const handleEventSub = (e) => {
    if (isFuture) {
      e.preventDefault();

      handleRestrictedAccess(() => open('webinar', id));
    }
  };

  if (isLastEmptyCard) {
    return (
      <VerticalCard href="/webinars" className={cx('component', 'empty', className)} />
    );
  }

  return (
    <VerticalCard
      className={cx('component', className)}
      href={webinarPath}
      tags={<VerticalCard.Tags tags={tags} />}
      topRight={(
        <VerticalCard.Info
          itemClassName={cx('item')}
          runTime={isDesktop ? videoLength : null}
          participants={isDesktop ? registered : null}
          date={!isDesktop ? date : null}
        />
      )}
      info={isDesktop && <VerticalCard.Info date={date} />}
      button={(
        <VerticalCard.Button className={cx('button')} isFuture={isFuture} onClick={handleEventSub}>
          {buttonText}
        </VerticalCard.Button>
      )}
    >
      <div className={cx('content')}>
        <VerticalCard.Title className={cx('title')}>
          {title}
        </VerticalCard.Title>

        <VerticalCard.Creators
          shouldRenderInfo={shouldRenderInfo && authors?.length <= 1}
          authors={authors}
          className={cx('authors')}
        />
      </div>
    </VerticalCard>
  );
};

const mapStateToProps = (state, props) => ({
  isDesktop: selectDesktop(state),
  webinarPath: `/webinar/${props.alias}`,
  buttonText: props.isFuture
    ? selectWebinarPageTexts(state).button?.register
    : selectWebinarPageTexts(state).button?.watch,
});

WebinarCardComponent.propTypes = {
  title: PropTypes.string,
  webinarPath: PropTypes.string,
  date: PropTypes.string,
  buttonText: PropTypes.string,
  videoLength: PropTypes.string,
  id: PropTypes.number,
  registered: PropTypes.number,
  tags: PropTypes.array,
  authors: PropTypes.array,
  shouldRenderInfo: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isFuture: PropTypes.bool,
};

export const WebinarCard = connect(mapStateToProps)(WebinarCardComponent);
