import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectMobileIdText } from 'store/widgets';

import { Preloader, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LoadingComponent = ({ text }) => (
  <>
    <Preloader className={cx('preloader')} />

    <Text isCentered>
      {text}
    </Text>
  </>
);

const mapStateToProps = (state) => ({
  text: selectMobileIdText(state),
});

LoadingComponent.propTypes = {
  text: PropTypes.string,
};

export const Loading = connect(mapStateToProps)(LoadingComponent);
