import classNames from 'classnames/bind';
import { useIFrame } from 'hooks/useIFrame';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const IFrame = ({ src, style, iframeRef, className }) => {
  const { notAuthorized } = useIFrame();

  return (
    <iframe
      sandbox="allow-same-origin allow-scripts"
      allowTransparency="true"
      allow="autoplay; fullscreen"
      className={cx('iframe', className, { notAuthorized })}
      src={src}
      style={style}
      ref={iframeRef}
    />
  );
};
