import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Hooks */
import { useEventSubPopup } from 'hooks/useEventSubPopup';
import { useRestrictedAccess } from 'hooks/useRestricredAccess';

/* Components */
import { PrimaryButtonOrLink } from 'components';

/* Constants */
import { BANNER_BUTTON_ACTIONS } from 'constants/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Button = ({
  action,
  eventType = 'webinar',
  eventId,
  buttonText,
  link,
  className,
}) => {
  const { open } = useEventSubPopup();
  const { handleRestrictedAccess } = useRestrictedAccess();

  const handleClick = () => {
    if (action === BANNER_BUTTON_ACTIONS.eventSubscription) {
      handleRestrictedAccess(( ) => open(eventType, eventId));
    }
  };

  return (
    <PrimaryButtonOrLink
      size="large"
      className={cx('component', className)}
      arrow={{
        render: true,
        mode: 'normal'
      }}
      onClick={handleClick}
      isLink={action === BANNER_BUTTON_ACTIONS.link}
      routerLink={!link?.isExternal}
      href={link?.url}
    >
      {buttonText}
    </PrimaryButtonOrLink>
  )
};

Button.propTypes = {
  action: PropTypes.string,
  eventType: PropTypes.string,
  eventId: PropTypes.number,
  buttonText: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    isExternal: PropTypes.bool,
  }),
};
