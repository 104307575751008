import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PartnersBlock = ({ partners, className }) => (
  <div className={cx('component', className)}>
    {partners.map(({ name, image }) => (
      <div className={cx('imageContainer')}>
        <img key={name} src={image} alt="" className={cx('image')} />
      </div>
    ))}
  </div>
);

PartnersBlock.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
  })),
};
