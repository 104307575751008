import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ChangeTab = ({ onClick, children, className }) => (
  <Text className={cx('component', className)} onClick={onClick}>
    {children}
  </Text>
);
