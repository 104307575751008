import { connect } from 'react-redux';

/* Store */
import { selectPastWebinars, selectPastWebinarsAmount } from 'store/content/selectors';
import {
  loadMorePastWebinars,
  selectLoadedPastWebinars,
  selectPastWebinarsLoadedFlag,
} from 'store/pagination';

/* Components */
import { Template } from './Template';
import { PastEventsLabel } from 'components';

const PastWebinarsComponent = ({
  webinars, loaded, amount, onLoadMore, displayLabel, fullyLoaded,
}) => {
  if (!webinars || !webinars?.length) return null;

  return (
    <>
      {displayLabel && <PastEventsLabel amount={amount} />}

      <Template
        content={webinars}
        loaded={loaded}
        amount={amount}
        fullyLoaded={fullyLoaded}
        onLoadMore={onLoadMore}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  amount: selectPastWebinarsAmount(state),
  webinars: selectPastWebinars(state),
  loaded: selectLoadedPastWebinars(state),
  fullyLoaded: selectPastWebinarsLoadedFlag(state),
});

const mapDispatchToProps = {
  onLoadMore: loadMorePastWebinars,
};

export const PastWebinars = connect(mapStateToProps, mapDispatchToProps)(PastWebinarsComponent);
