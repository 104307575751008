import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Utils */
import { setDataInStore } from 'utils/setDataInStore';

/* Api */
import { WidgetEndpoint } from 'api/endpoints';

/* Hocs */
import { withEndpointData } from 'hocs';

/* Store */
import { getUser } from 'store/user/actions';
import { textsSelector } from 'store/widgets';
import { selectUserId } from 'store/user/selectors';
import { selectIsAuthorized } from 'store/auth/selectors';

/* Constants */
import { WIDGETS } from 'constants/constants';

/* Components */
import { Preloader } from 'components';

/* Hooks */
import { useMakeRequest } from 'hooks/useMakeRequest';

// Для подгрузки всех виджетов в стор + дополнительных endpoints
export const withDataLoading = (endpoints) => (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const makeRequest = useMakeRequest();
    const isAuthorized = useSelector(selectIsAuthorized);
    const userId = useSelector(selectUserId);
    const widgets = useSelector(textsSelector);
    const widgetsLoaded = Object.keys(widgets).some((item) => widgets[item]);

    useEffect(() => {
      if (isAuthorized && !userId) {
        makeRequest(() => dispatch(getUser()));
      }
    }, []);

    useEffect(() => {
      if (!props.isLoading) {
        setDataInStore({
          data: props.data,
          dispatch,
          shouldLoadWidgets: !widgetsLoaded,
        });
      }
    }, [props.isLoading]);

    return props.isLoading ? <Preloader /> : <WrappedComponent {...props} isAuthorized={isAuthorized} />;
  };

  return withEndpointData(({ params }) => ({
    widgets: new WidgetEndpoint(WIDGETS.join(',')),
    ...endpoints?.(params),
  }))(Component);
};
