import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { VerticalCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CourseCard = ({
  alias,
  title,
  videoLength,
  tags,
  cardImage,
  isBottomImage,
  className,
}) => (
  <VerticalCard
    className={cx('component', className)}
    href={`/course/${alias}`}
    image={cardImage}
    imageClassName={cx('image', { isBottomImage })}
    tags={<VerticalCard.Tags tags={tags} onlyMainTag />}
    topRight={<VerticalCard.Info runTime={videoLength} />}
    isBottomImage={isBottomImage}
  >
    <VerticalCard.Title className={cx('title', { isBottomImage })}>
      {title}
    </VerticalCard.Title>
  </VerticalCard>
);

CourseCard.defaultProps = {
  isBottomImage: PropTypes.bool,
};

CourseCard.propTypes = {
  alias: PropTypes.string,
  cardImage: PropTypes.string,
  title: PropTypes.string,
  videoLength: PropTypes.string,
  coursePath: PropTypes.string,
  tags: PropTypes.array,
  isBottomImage: PropTypes.bool,
};
