import classNames from 'classnames/bind';

import { PrimaryButtonOrLink } from 'components';

import routes from '../../../../routes';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Button = ({ children, className }) => (
  <PrimaryButtonOrLink
    isLink
    routerLink
    href={routes[0].path}
    className={cx('component', className)}
    size="large"
    arrow={{
      render: true,
      mode: 'reversed'
    }}
  >
    {children}
  </PrimaryButtonOrLink>
);
