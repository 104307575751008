import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleMask } from 'utils/mask';

import { resetErrors } from 'store/auth/actions';
import { selectMobileIdLoginInProgress } from 'store/auth/selectors';

export const useCodeInput = ({ onConfirm, onRequestCode, resetTime }) => {
  const dispatch = useDispatch();
  const [valid, setCodeValid] = useState(false);
  const isMobileId = useSelector(selectMobileIdLoginInProgress);

  const codeValid = (code) => (isMobileId ? code.length === 6 : code.length === 4);

  const handleCodeConfirm = (code) => {
    const trimmedCode = code.replaceAll(' ', '');
    setCodeValid(codeValid(trimmedCode));

    if (codeValid(trimmedCode)) {
      onConfirm(trimmedCode);
    }
  };

  const repeatRequest = () => {
    onRequestCode();
    resetTime();
  };

  const handleChange = (e) => {
    dispatch(resetErrors());

    handleCodeConfirm(e.target.value);
    return handleMask(e.target.value, isMobileId ? 'mobileIdCode' : 'code');
  };

  return { valid, repeatRequest, handleChange, handleCodeConfirm };
};
