import { createReducer } from '@reduxjs/toolkit';

import { setCurrentCourse } from 'store/course/actions';

export const course = createReducer({}, {
  [setCurrentCourse.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
