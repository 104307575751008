import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { selectPrevUrl } from 'store/page/selectors';
import { selectNavigationContentButtons } from 'store/widgets';

import { ContentButton } from 'components/Navigation/components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SectionsComponent = ({ prevUrl, contentButtons, className }) => (
  <div className={cx('container', className)}>
    {contentButtons?.map(({ key, title, matchPath, amount }) => {
      const isActive = matchPath.some((item) => item === prevUrl);

      return (
        <ContentButton
          className={cx('button', { isActive })}
          key={key}
          url={matchPath[0]}
          isActive={isActive}
          isReversed
          text={(
            <ContentButton.Part className={cx('text')}>
              {title}
            </ContentButton.Part>
          )}
          amount={(
            <ContentButton.Part className={cx('amount')}>
              {amount}
            </ContentButton.Part>
          )}
        />
      )
    })}
  </div>
);

const mapStateToProps = (state) => ({
  contentButtons: selectNavigationContentButtons(state),
  prevUrl: selectPrevUrl(state),
});

export const Sections = connect(mapStateToProps)(SectionsComponent);
