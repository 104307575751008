import { createSelector } from '@reduxjs/toolkit';
import { getGlobalState } from 'store/selectors';
import { MOBILE_ID } from 'constants/authorizationErrors';

export const authSelector = (state) => getGlobalState(state).auth;

export const selectFormData = createSelector(authSelector, (state) => state.form);
export const selectPhoneNumber = createSelector(selectFormData, (state) => state.phone);
export const selectName = createSelector(selectFormData, (state) => state.name);

export const selectIsAuthorized = createSelector(authSelector, (state) => state.isAuthorized);
export const selectError = createSelector(authSelector, (state) => state.error);
export const selectLoginInProgress = createSelector(authSelector, (state) => state.loginInProgress);
export const selectMobileIdLoginInProgress = createSelector(authSelector, (state) => state.mobileIdLoginInProgress);
export const selectPhoneInputError = (state) => selectError(state).phoneInput;
export const selectPhoneInputErrorFlag = (state) => selectPhoneInputError(state)?.error;
export const selectPhoneInputErrorMessage = (state) => selectPhoneInputError(state)?.errorMessage;
export const selectPhoneInputErrorType = (state) => selectPhoneInputError(state)?.errorType;

export const selectCodeInputError = (state) => selectError(state).codeInput;
export const selectCodeInputErrorFlag = (state) => selectCodeInputError(state)?.error;
export const selectCodeInputErrorMessage = (state) => selectCodeInputError(state)?.errorMessage;

export const selectMobileIdError = (state) => selectError(state).mobileId;
export const selectMobileIdErrorMessage = (state) => selectMobileIdError(state)?.errorMessage;
export const selectMobileIdNeedsOTPError = (state) => selectMobileIdError(state)?.errorType === MOBILE_ID.MOBILEID_NEEDS_OTP;
