import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { mobileIdLogin } from 'store/auth/actions';
import { selectMobileIdErrorMessage, selectMobileIdLoginInProgress } from 'store/auth/selectors';

/* Components */
import { Text } from 'components';
import { MobileIdAuthButton, Loading } from 'components/AuthForm/components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MobileIdLoginComponent = ({ valid, startMobileIdLogin, isPending, errorMessage }) => (isPending ? <Loading /> : (
  <>
    <MobileIdAuthButton disabled={!valid || !!errorMessage} onClick={startMobileIdLogin} />
    {errorMessage && (
      <Text className={cx('error')} isError isCentered>
        {errorMessage}
      </Text>
    )}
  </>
));

const mapStateToProps = (state) => ({
  errorMessage: selectMobileIdErrorMessage(state),
  isPending: selectMobileIdLoginInProgress(state),
});

const mapDispatchToProps = {
  startMobileIdLogin: mobileIdLogin,
};

MobileIdLoginComponent.propTypes = {
  errorMessage: PropTypes.string,
  isPending: PropTypes.bool,
  valid: PropTypes.bool,
  startMobileIdLogin: PropTypes.func,
};

export const MobileIdLogin = connect(mapStateToProps, mapDispatchToProps)(MobileIdLoginComponent);
