import { Children } from 'react';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectRelatedContentTitle } from 'store/widgets';

/* Components */
import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RelatedContentComponent = ({ title, children, slides, className }) => {
  if (!children) return null;

  return (
    <div className={cx('container')}>
      <Text className={cx('title')}>
        {title}
      </Text>

      <Swiper
        className={cx('slider', className)}
        spaceBetween={20}
        slidesPerView={slides}
        grabCursor={Children.count(children) > slides}
      >
        {Children.map(children, (child) => (
          <SwiperSlide className={cx('slide')}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  title: selectRelatedContentTitle(state),
});

RelatedContentComponent.propTypes = {
  title: PropTypes.string,
  slides: PropTypes.number,
};

export const RelatedContent = connect(mapStateToProps)(RelatedContentComponent);
