import { useEffect, useState } from 'react';

export const useWizard = ({ opened, activeStep }) => {
  const [step, setStep] = useState(activeStep);
  const [nextStep, setNextStep] = useState(null);

  useEffect(() => {
    setStep(null);
    setNextStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    setTimeout(() => {
      setStep(activeStep);
      setNextStep(activeStep);
    }, 100);
  }, [opened]);

  useEffect(() => {
    if (step === null) {
      setTimeout(() => {
        setStep(activeStep);
        setNextStep(activeStep);
      }, 100);
    }
  }, [step]);

  const onStepChange = (newStep) => {
    setStep(newStep);
  };

  const handleExited = () => {
    setStep(nextStep);
    setNextStep(null);
  };

  return {
    step,
    nextStep,
    handleExited,
    onStepChange,
  };
};
