import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* API */
import { requestCities } from 'api/requests';

/* Hooks */
import { useMakeRequest } from 'hooks/useMakeRequest';

/* Store */
import { selectTownInputFields } from 'store/widgets';

/* Components */
import { Select } from 'components';

const TownInputComponent = ({
  inputProps,
  disabled,
  onBlur,
  className,
}) => {
  const makeRequest = useMakeRequest();

  const handleRequest = (value) => makeRequest(() => requestCities(value));

  return (
    <Select
      className={className}
      inputProps={inputProps}
      requestData={handleRequest}
      disabled={disabled}
      onBlur={onBlur}
      Component={(option) => <Select.TownOption name={option?.name} />}
    />
  );
};

const mapStateToProps = (state) => ({
  inputProps: selectTownInputFields(state),
});

TownInputComponent.propTypes = {
  inputProps: PropTypes.object,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export const TownInput = connect(mapStateToProps, null)(TownInputComponent);
