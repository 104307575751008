import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ButtonOrLink, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ContentButton = ({ text, url, amount, isActive, isReversed, className }) => (
  <ButtonOrLink
    isLink
    routerLink
    href={url}
    animation={{ animated: false }}
    className={cx('component', { isReversed }, className, isActive ? 'active' : 'inactive')}
  >
    {amount}
    {text}
  </ButtonOrLink>
);

ContentButton.propTypes = {
  text: PropTypes.node,
  amount: PropTypes.node,
  url: PropTypes.string,
  isActive: PropTypes.bool,
};

const Part = ({ children, className }) => (
  <Text className={className}>
    {children}
  </Text>
);

ContentButton.Part = Part;
