import classNames from 'classnames/bind';

import { FFInput } from 'components/Inputs';
import { Options, CompanyOption, TownOption, Option, DefaultOption } from './components';
import Arrow from '../../../../images/arrow_mini.svg';

import { useSelect } from './useSelect';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Select = ({
  inputProps,
  disabled,
  requestData,
  onBlur,
  Component,
  className,
  ...restProps
}) => {
  const {
    isOpen,
    results,
    toggleOptions,
    handleClick,
    handleChange,
    handleValueProxy,
  } = useSelect({ requestData, onBlur });

  return (
    <div className={cx('container', className)}>
      <FFInput
        className={cx('input', { isOpen })}
        {...inputProps}
        {...restProps}
        disabled={disabled}
        onChangeProxy={(e) => handleChange(e.target.value, true)}
        valueProxy={handleValueProxy}
        autoComplete="off"
        withBorder={false}
      >
        {!disabled && <Arrow className={cx('arrow')} onClick={toggleOptions} />}
      </FFInput>

      {isOpen && (
        <Options
          className={cx('options')}
          options={results}
          onClick={handleClick}
          notFound={inputProps?.notFound}
          Component={Component}
        />
      )}
    </div>
  );
};

Select.CompanyOption = CompanyOption;
Select.TownOption = TownOption;
Select.Option = Option;
Select.DefaultOption = DefaultOption;
