import axios from 'axios';
import endpoints from '../endpoints';
import { getCookie, setAuthTokenCookie, setRefreshTokenCookie } from 'utils/cookie';
import { STATUS } from 'constants/mobileId';

const requestHandler = (config) => {
  if (getCookie('token')) config.headers['X-Auth-Token'] = getCookie('token');
  return config;
};

export const removeAuthInterceptor = axios.interceptors.request.use(requestHandler);

export const createProfile = (phoneNumber) => axios.post(endpoints.register, { phoneNumber }).then(({ data }) => data);

export const confirmProfile = ({ code, confirmationToken, formData }) => axios.post(endpoints.confirmRegister, {
  code,
  confirmationToken,
  ...formData,
}).then(({ data }) => {
  setAuthTokenCookie(data.accessToken);

  if (data.refreshToken) {
    setRefreshTokenCookie(data.refreshToken);
  }

  return data;
});

export const requestLogin = (phoneNumber) => axios.post(endpoints.login, { phoneNumber }).then(({ data }) => data);

export const requestLoginConfirm = ({ code, confirmationToken }) => axios.post(endpoints.confirmLogin, {
  code,
  confirmationToken,
}).then(({ data }) => {
  setAuthTokenCookie(data.accessToken);

  if (data.refreshToken) {
    setRefreshTokenCookie(data.refreshToken);
  }

  return data;
});

export const requestLogout = () => axios.post(endpoints.logout).then(({ data }) => data);

export const updateProfile = (profileData) => axios.post(endpoints.updateProfile, profileData);

export const requestMobileId = ({ phoneNumber }) => axios.post(endpoints.mobileId, { phoneNumber }).then(({ data }) => data);

export const requestMobileIdResult = ({ operationId }) => axios.get(endpoints.mobileIdResult, { params: { operationId } })
  .then(({ status, data }) => {
    if (status === 200) return { status: STATUS.success, data };
    if (status === 204) return { status: STATUS.retry };
  });

const checkStatus = ({ operationId, maxWaitingTime, resolve, reject }) => {
  const start = Date.now();

  requestMobileIdResult({ operationId })
    .then(({ status, data }) => {
      const diff = Date.now() - start;

      if (status === STATUS.retry && diff < maxWaitingTime) {
        setTimeout(() => checkStatus({
          operationId,
          maxWaitingTime,
          resolve,
          reject,
        }), 1000);
      } else if (status === STATUS.success) {
        setAuthTokenCookie(data.accessToken);

        if (data.refreshToken) {
          setRefreshTokenCookie(data.refreshToken);
        }

        resolve(data);
      } else {
        reject(STATUS.timeout);
      }
    })
    .catch(reject);
};

let operationId = null;

export const startMobileIdAuth = ({ phoneNumber }) => new Promise((resolve, reject) => requestMobileId({ phoneNumber })
  .then((res) => {
    operationId = res.operationId;

    setTimeout(() => checkStatus({
      operationId,
      maxWaitingTime: res.timeSeconds * 1000,
      resolve,
      reject,
    }), 2500);
  })
  .catch(reject));

export const repeatEmail = (data) => axios.post(endpoints.repeatEmail, data);

export const confirmMobileIdCode = ({ code }) => new Promise((resolve, reject) => axios.post(endpoints.mobileIdOtp, { operationId, code })
  .then(() => setTimeout(() => checkStatus({
    operationId,
    resolve,
    reject,
  }), 2500))
  .catch(reject));