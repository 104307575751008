import { POPUP_STEP } from 'constants/constants';
import { validate } from 'utils/validate';

export const loginForm = (values) => ({
  phone: !values.phone || undefined,
});

export const registrationForm = (values, step, requireCompany) => ({
  name: !values.name || undefined,
  phone: step === POPUP_STEP.phone ? !values.phone : undefined,
  company: requireCompany ? !values.company || undefined : undefined,
});

export const changeEmailForm = (values) => ({
  email: !values.email || undefined,
});

export const changePhoneForm = (values) => ({
  phone: !values.phone || undefined,
});

export const personalAccountForm = (values, requireTown) => ({
  name: !values.name || undefined,
  town: requireTown ? !values.town || undefined : undefined,
});

export const validateText = (value) => validate('text', value);

export const validatePhone = (value) => validate('phone', value);

export const validateEmail = (value) => validate('email', value);