import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { VerticalCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const VerticalLectureCard = ({
  tags,
  title,
  authors,
  partners,
  videoLength,
  views,
  buttonText,
  className,
}) => (
  <VerticalCard
    className={cx('component', className)}
    imageClassName={cx('image')}
    tags={<VerticalCard.Tags tags={tags} onlyMainTag />}
    topRight={(
      <VerticalCard.Info
        runTime={videoLength}
        views={views}
        itemClassName={cx('item')}
        textClassName={cx('infoText')}
      />
    )}
    button={(
      <VerticalCard.Button
        className={cx('button')}
        size="large"
        theme="light"
      >
        {buttonText}
      </VerticalCard.Button>
    )}
  >
    <div className={cx('content')}>
      <VerticalCard.Title className={cx('title')}>
        {title}
      </VerticalCard.Title>

      <VerticalCard.Creators authors={authors} partners={partners} />
    </div>
  </VerticalCard>
);

VerticalLectureCard.defaultProps = {
  buttonText: 'Посмотреть запись',
};

VerticalLectureCard.propTypes = {
  buttonText: PropTypes.string,
  videoLength: PropTypes.string,
  views: PropTypes.number,
};
