import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';

import { selectIsAuthorized } from 'store/auth/selectors';

export const useVideoControls = () => {
  const notAuthorized = !useSelector(selectIsAuthorized);
  const { openAuthPopup } = usePopup();

  const handlePlay = (e) => {
    if (notAuthorized) {
      e.preventDefault();
      e.target.pause();
      e.target.currentTime = 0;
      openAuthPopup();
    }
  };

  return { handlePlay, notAuthorized };
};
