import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

/* Store */
import { selectDisclaimerText } from 'store/widgets';

/* Components */
import { Text } from 'components';

const cx = classNames.bind(styles);

const DisclaimerComponent = ({ text, className }) => (
  <div className={cx('container', className)}>
    <Text className={cx('text')}>
      {text}
    </Text>
  </div>
);

DisclaimerComponent.propTypes = {
  text: PropTypes.string,
};

const mapStateToProps = (state) => ({
  text: selectDisclaimerText(state),
});

export const Disclaimer = connect(mapStateToProps)(DisclaimerComponent);
