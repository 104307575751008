import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ShareButton } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ButtonsContainer = ({ button, children, className }) => (
  <div className={cx('component', className)}>
    {button && (
      <div className={cx('container')}>
        {button}
        <ShareButton className={cx('shareButton')} />
      </div>
    )}

    <div className={cx('info')}>
      {children}
      {!button && <ShareButton className={cx('shareButton')} />}
    </div>
  </div>
);

ButtonsContainer.propTypes = {
  button: PropTypes.node,
};
