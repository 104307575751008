import {
  mapArticle,
  mapConference,
  mapCourse,
  mapCourseSeries,
  mapLecture,
  mapWebinar,
  mapWebinarSeries,
} from 'api/dataMapper';

export const mappers = {
  article: mapArticle,
  course: mapCourse,
  lecture: mapLecture,
  webinar: mapWebinar,
  articles: (data) => ({
    total: data?.total,
    data: data?.articles?.map((part) => mapArticle(part)),
  }),
  courses: (data) => ({
    total: data?.total,
    data: data?.courses?.map((course) => mapCourse(course)),
  }),
  lectures: (data) => ({
    total: data?.total,
    data: data?.lectures?.map((lecture) => mapLecture(lecture)),
  }),
  futureConferences: (data) => ({
    total: data?.total,
    data: data?.conferences?.map((part) => ({
      ...mapConference(part),
      isFuture: true,
    })),
  }),
  pastConferences: (data) => ({
    total: data?.total,
    data: data?.conferences?.map((part) => mapConference(part)),
  }),
  futureWebinars: (data) => ({
    total: data?.total,
    data: data?.webinars?.map((webinar) => mapWebinar(webinar)),
  }),
  pastWebinars: (data) => ({
    total: data?.total,
    data: data?.webinars?.map((webinar) => mapWebinar(webinar)),
  }),
  webinarSeries: (data) => mapWebinarSeries(data?.set),
  courseSeries: (data) => mapCourseSeries(data?.set),
  webinarSeriesList: (data) => ({
    total: data?.total,
    series: data?.series?.map((part) => mapWebinarSeries(part)),
  }),
  courseSeriesList: (data) => ({
    total: data?.total,
    series: data?.series?.map((part) => mapCourseSeries(part)),
  }),
};

export const mapMeta = (meta) => {
  if (!meta || !meta?.title) return null;

  return Object.keys(meta)
    ?.filter((key) => key !== 'title' && key !== 'canonical')
    ?.map((key) => ({ name: key, content: meta[key] }));
};
