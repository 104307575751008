import { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useSlider } from 'components/Slider/useSlider';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Slider = ({ children, autoSwitch, className, itemClassName }) => {
  const {
    container,
    offset,
    handleStart,
    handleEnd,
    handleMove,
  } = useSlider({ children, autoSwitch });

  return (
    <div
      className={cx('container', className)}
      ref={container}
      onMouseDown={handleStart}
      onMouseMove={(e) => {
        e.preventDefault();
        handleMove(e);
      }}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      <div className={cx('wrapper')} style={{ transform: `translateX(${offset}px)` }}>
        {Children.map(children, (item) => (
          <div className={cx('item', itemClassName)}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

Slider.propTypes = {
  autoSwitch: PropTypes.number,
};
