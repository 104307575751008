import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { WebinarCard, WebinarSeriesCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const WebinarRow = ({
  content,
  shouldRenderInfo,
  className,
}) => (
  <div className={cx('container', className)}>
    {content?.map((item) => {
      if (item.type === 'series') {
        return (
          <WebinarSeriesCard
            key={item.alias}
            className={cx('card', 'largeCard')}
            {...item}
          />
        );
      }
      return (
        <WebinarCard
          key={item.alias}
          shouldRenderInfo={shouldRenderInfo}
          className={cx('card', 'webinarCard')}
          {...item}
        />
      );
    })}
  </div>
);

WebinarRow.propTypes = {
  shouldRenderInfo: PropTypes.bool,
  content: PropTypes.array,
};
