import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectPastEventsLabel } from 'store/widgets';

import { Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PastEventsLabelComponent = ({ text, amount, className }) => (
  <div className={cx('component', className)}>
    <Text className={cx('text')}>
      {text}
    </Text>
    <Text className={cx('amount')}>
      {amount}
    </Text>
  </div>
);

const mapStateToProps = (state) => ({
  text: selectPastEventsLabel(state),
});

PastEventsLabelComponent.propTypes = {
  text: PropTypes.string,
  amount: PropTypes.number,
}

export const PastEventsLabel = connect(mapStateToProps)(PastEventsLabelComponent);
