import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

/* Store */
import { selectPhoneInputFields } from 'store/widgets';
import {
  selectIsAuthorized,
  selectPhoneInputErrorFlag,
  selectPhoneInputErrorMessage,
} from 'store/auth/selectors';

/* Hooks */
import { useEventListener } from 'hooks/useEventListener';
import { usePhoneInput } from 'hooks/usePhoneInput';

/* Components */
import { CodeInput, EditButton, FFPhoneInput } from 'components/Inputs';

/* Icons */
import Success from '../../../../images/success.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PhoneInputComponent = ({
  inputProps,
  onBlur,
  onConfirm,
  onSubmit,
  error,
  message,
  isAuthorized,
  hideStatus,
  codeInput,
  editButton,
  renderSubmitButton,
  disabled,
  className,
}) => {
  const { handleChange, handleValidate, valid } = usePhoneInput();

  useEventListener('keydown', (e) => {
    if (e.code === 'Enter' && valid) {
      onSubmit?.();
    }
  });

  return (
    <>
      <div className={cx('component', className)}>
        <div className={cx('container')}>
          <FFPhoneInput
            {...inputProps}
            className={cx('input')}
            inputType="phone"
            error={error}
            message={message}
            validate={handleValidate}
            disabled={disabled}
            onBlur={onBlur}
            onChangeProxy={handleChange}
            icon={!hideStatus && isAuthorized && <Success className={cx('status')} />}
            renderSubmitButton={renderSubmitButton}
            onClick={onSubmit}
          />

          {editButton}
        </div>
      </div>

      {!error && codeInput && (
        <CodeInput onConfirm={onConfirm} onRequestCode={onSubmit} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  inputProps: selectPhoneInputFields(state),
  error: selectPhoneInputErrorFlag(state),
  message: selectPhoneInputErrorMessage(state),
  isAuthorized: selectIsAuthorized(state),
});

PhoneInputComponent.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    required: PropTypes.bool,
  }),
  error: PropTypes.bool,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEdit: PropTypes.func,
  onConfirm: PropTypes.func,
  onSubmit: PropTypes.func,
  isAuthorized: PropTypes.bool,
  codeInput: PropTypes.bool,
  disabled: PropTypes.bool,
  renderSubmitButton: PropTypes.bool,
  editButton: PropTypes.node,
};

export const PhoneInput = connect(mapStateToProps)(PhoneInputComponent);

PhoneInput.Edit = EditButton;
