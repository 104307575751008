import { createReducer } from '@reduxjs/toolkit';

import {
  setMoreArticles,
  setMoreFutureConferences,
  setMorePastConferences,
  setMoreCourses,
  setMoreLectures,
  setMoreFutureWebinars,
  setMorePastWebinars,
  setMoreCourseSeries,
  setMoreWebinarSeries,
} from 'store/pagination';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';

export const pagination = createReducer({
  articles: {
    fullyLoaded: false,
    content: [],
  },
  courses: {
    fullyLoaded: false,
    content: [],
  },
  lectures: {
    fullyLoaded: false,
    content: [],
  },
  pastWebinars: {
    fullyLoaded: false,
    content: [],
  },
  futureWebinars: {
    fullyLoaded: false,
    content: [],
  },
  pastConferences: {
    fullyLoaded: false,
    content: [],
  },
  futureConferences: {
    fullyLoaded: false,
    content: [],
  },
  courseSeriesList: {
    fullyLoaded: false,
    content: [],
  },
  webinarSeriesList: {
    fullyLoaded: false,
    content: [],
  },
}, {
  [setMoreArticles.type]: (state, action) => {
    state.articles.content = [...state.articles.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.articles.fullyLoaded = true;
    }
  },
  [setMoreFutureConferences.type]: (state, action) => {
    state.futureConferences.content = [...state.futureConferences.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.futureConferences.fullyLoaded = true;
    }
  },
  [setMorePastConferences.type]: (state, action) => {
    state.pastConferences.content = [...state.pastConferences.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.pastConferences.fullyLoaded = true;
    }
  },
  [setMoreCourses.type]: (state, action) => {
    state.courses.content = [...state.courses.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.courses.fullyLoaded = true;
    }
  },
  [setMoreLectures.type]: (state, action) => {
    state.lectures.content = [...state.lectures.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.lectures.fullyLoaded = true;
    }
  },
  [setMoreFutureWebinars.type]: (state, action) => {
    state.futureWebinars.content = [...state.futureWebinars.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.futureWebinars.fullyLoaded = true;
    }
  },
  [setMorePastWebinars.type]: (state, action) => {
    state.pastWebinars.content = [...state.pastWebinars.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.pastWebinars.fullyLoaded = true;
    }
  },
  [setMoreCourseSeries.type]: (state, action) => {
    state.courseSeriesList.content = [...state.courseSeriesList.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.courseSeriesList.fullyLoaded = true;
    }
  },
  [setMoreWebinarSeries.type]: (state, action) => {
    state.webinarSeriesList.content = [...state.webinarSeriesList.content, ...action.payload.data];

    if (action.payload.data?.length < DEFAULT_PAGE_SIZE) {
      state.webinarSeriesList.fullyLoaded = true;
    }
  },
});
