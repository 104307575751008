import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { resetErrors, setLoginInProgress, setPhone } from 'store/auth/actions';
import { selectMobileIdLoginInProgress } from 'store/auth/selectors';

/* Hooks */
import { usePhoneInput } from 'hooks/usePhoneInput';
import { useAuthRequests } from 'hooks/useAuthRequests';

/* Components */
import { PhoneInput } from 'components/Inputs';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AuthPhoneInputComponent = ({
  onSavePhone,
  onRequestCode,
  onConfirm,
  onResetError,
  onSetInProgress,
  mobileIdPending,
  hideSubmit,
}) => {
  const { handleEditNumber, success, codeInput, displayCodeInput } = usePhoneInput();

  const { handleRequest, handleConfirm } = useAuthRequests({
    onConfirm,
    onRequestCode,
    onRequestSuccess: displayCodeInput,
  });

  const handleSubmit = () => {
    onResetError();
    handleRequest();
    onSetInProgress(true);
  };

  return (
    <PhoneInput
      codeInput={codeInput}
      disabled={codeInput || mobileIdPending}
      onSubmit={handleSubmit}
      onConfirm={handleConfirm}
      onBlur={onSavePhone}
      renderSubmitButton={!hideSubmit && !codeInput && !success}
      editButton={(codeInput || success) && (
        <PhoneInput.Edit className={cx('editButton')} disabled={success} onClick={handleEditNumber} />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  mobileIdPending: selectMobileIdLoginInProgress(state),
});

const mapDispatchToProps = {
  onSavePhone: setPhone,
  onResetError: resetErrors,
  onSetInProgress: setLoginInProgress,
};

AuthPhoneInputComponent.propTypes = {
  hideSubmit: PropTypes.bool,
  mobileIdPending: PropTypes.bool,
  onSavePhone: PropTypes.func,
  onRequestCode: PropTypes.func,
  onConfirm: PropTypes.func,
  onResetError: PropTypes.func,
  onSetInProgress: PropTypes.func,
};

export const AuthPhoneInput = connect(mapStateToProps, mapDispatchToProps)(AuthPhoneInputComponent);
