/* eslint-disable max-len */
import { Endpoint } from 'hocs/withBackendData';

export default {
  meta: '/meta',
  searchData: '/search',
  requestCities: '/content/city',
  requestCompanies: '/company',
  userProfile: '/profile',
  updateProfile: '/profile/update',
  postAvatar: '/profile/avatar',
  phoneChange: '/auth/change',
  confirmPhoneChange: '/auth/change/confirm',
  register: '/auth/register',
  confirmRegister: '/auth/register/confirm',
  login: '/auth/login',
  confirmLogin: '/auth/login/confirm',
  logout: '/auth/logout',
  repeatEmail: '/profile/email/confirm/repeat',
  refreshToken: '/auth/refresh',
  mobileId: '/auth/mobileid',
  mobileIdOtp: '/auth/mobileid/otp',
  mobileIdResult: '/auth/mobileid/result',
  requestArticles: '/content/article/list',
  requestArticle: '/content/article',
  requestLectures: '/content/lecture/list',
  requestLecture: '/content/lecture',
  requestCourses: '/content/course/list',
  requestCourse: '/content/course',
  requestWebinars: '/content/webinar/list',
  requestWebinar: '/content/webinar',
  requestConferences: '/content/conference/list',
  requestWebinarSeries: '/content/webinar/series',
  requestCourseSeries: '/content/course/series',
  requestWebinarSeriesList: '/content/webinar/series/list',
  requestCourseSeriesList: '/content/course/series/list',
  eventSubscription: '/event/sub',
  requestBanners: '/content/banner/list',
  widgets: '/widget',
};

export const convertWidgetData = (slug, rawData) => ({
  [slug]: rawData.blocks.reduce((accum, { type, data }) => ({
    ...accum,
    [type]: data,
  }), {})[slug],
});

export const convertWidgetsData = (rawData) => rawData.blocks.reduce((accum, { type, data }) => ({
  ...accum,
  [type]: data,
}), {});

export class WidgetEndpoint extends Endpoint {
  constructor(slug) {
    super(`/widget?widgets=${slug}`, (rawData) => {
      if (slug.split(',').length > 1) {
        return convertWidgetsData(rawData);
      }

      return convertWidgetData(slug, rawData);
    });
  }
}

export class ContentEndpoint extends Endpoint {
  constructor(url, param = '') {
    super(`${url}?pageNumber=1&pageSize=10${param}`);
  }
}