import { selectUserAccount } from 'store/widgets';

export const selectAccountTitle = (state) => selectUserAccount(state).title;
export const selectAccountLogout = (state) => selectUserAccount(state).logout;
export const selectAccountAbout = (state) => selectUserAccount(state).about;
export const selectAccountExperience = (state) => selectUserAccount(state).experience;
export const selectAccountMailCheckbox = (state) => selectUserAccount(state).mail;
export const selectAccountSaveChanges = (state) => selectUserAccount(state).save;
export const selectAccountCancelChanges = (state) => selectUserAccount(state).cancel;

// Profile Result Screens
export const selectProfileResultScreens = (state) => selectUserAccount(state).profileResultScreens;