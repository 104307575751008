import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Components */
import { VerticalCard } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ArticleCardComponent = ({
  title,
  shouldRedirect,
  tags,
  articlePath,
  cardImage,
  readTime,
  className,
  isLastEmptyCard,
}) => {
  if (isLastEmptyCard) {
    return (
      <VerticalCard href="/articles" className={cx('component', className)} />
    );
  }

  return (
    <VerticalCard.Article
      className={cx('component', className)}
      title={title}
      articlePath={articlePath}
      image={cardImage}
      tags={<VerticalCard.Tags tags={tags} />}
      readTime={readTime}
    />
  );
};

const mapStateToProps = (state, props) => ({
  articlePath: `/article/${props.alias}`,
});

ArticleCardComponent.propTypes = {
  title: PropTypes.string,
  articlePath: PropTypes.string,
  cardImage: PropTypes.string,
  readTime: PropTypes.string,
  tags: PropTypes.array,
  shouldRedirect: PropTypes.bool,
  isLastEmptyCard: PropTypes.bool,
};

export const ArticleCard = connect(mapStateToProps)(ArticleCardComponent);
