import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* Store */
import { selectRequireTown, selectUserAccount, selectTelegramChannelSetting } from 'store/widgets';
import { selectUserChanges, selectUserInitialData } from 'store/user/selectors';
import { deleteChanges } from 'store/user/actions';
import { selectMobile } from 'store/selectors';
import { setResultStatus } from 'store/page';
import { logout as logoutAction } from 'store/auth/actions';

/* Utils */
import { personalAccountForm } from 'utils/formValidators';

/* Hooks */
import { usePopup } from 'hooks/usePopup';
import { useMakeRequest, useProfileUpdate } from 'hooks/useMakeRequest';

/* Constants */
import { RESULT_STATUS } from 'constants/constants';

/* Components */
import {
  Header,
  PersonalInfo,
  AboutUser,
  Experience,
  CancelButton,
  SaveButton,
  TelegramChannelLink,
} from './components';
import { Form as FForm } from 'react-final-form';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FormComponent = ({
  texts,
  initialValues,
  userChanges,
  requireTown,
  displayTelegram,
  isMobile,
  onSetResultStatus,
  onLogout,
  onDeleteChanges,
}) => {
  const { closePopup } = usePopup();
  const makeRequest = useMakeRequest();
  const updateProfile = useProfileUpdate();

  const {
    logout, title, about, experience, telegramChannel, save, cancel,
  } = texts;

  const handleLogout = () => {
    makeRequest(() => onLogout());
    closePopup();
  };

  const handleCancel = () => {
    closePopup();

    if (userChanges) {
      onDeleteChanges();
    }
  };

  const handleValidate = (values) => personalAccountForm(values, requireTown);

  const handleSuccess = () => onSetResultStatus(RESULT_STATUS.profileUpdated);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(userChanges, handleSuccess);
  };

  return (
    <div className={cx('component')}>
      <Header title={title} logout={logout} onClick={handleLogout} />

      <PersonalInfo className={cx('block')} />

      <FForm
        onSubmit={() => {}}
        initialValues={initialValues}
        validate={handleValidate}
        render={({ valid, dirty }) => (
          <div className={cx('form')}>
            <AboutUser className={cx('block')} title={about} />

            <Experience className={cx('block')} title={experience} />

            <div className={cx('buttons')}>
              <CancelButton isMobile={isMobile} onClick={handleCancel}>
                {cancel}
              </CancelButton>

              <SaveButton disabled={!dirty || !valid} isMobile={isMobile} onClick={handleSubmit}>
                {isMobile ? save?.mobile : save?.desktop}
              </SaveButton>
            </div>

            {displayTelegram && <TelegramChannelLink texts={telegramChannel} />}
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  texts: selectUserAccount(state),
  initialValues: selectUserInitialData(state),
  requireTown: selectRequireTown(state),
  isMobile: selectMobile(state),
  displayTelegram: !selectTelegramChannelSetting(state),
  userChanges: selectUserChanges(state),
});

const mapDispatchToProps = {
  onSetResultStatus: setResultStatus,
  onLogout: logoutAction,
  onDeleteChanges: deleteChanges,
};

FormComponent.propTypes = {
  texts: PropTypes.shape({
    logout: PropTypes.string,
    title: PropTypes.string,
    about: PropTypes.string,
    experience: PropTypes.string,
    telegramChannel: PropTypes.object,
    save: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    cancel: PropTypes.string,
  }),
  initialValues: PropTypes.object,
  userChanges: PropTypes.object,
  requireTown: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSetResultStatus: PropTypes.func,
  onLogout: PropTypes.func,
  onDeleteChanges: PropTypes.func,
};

export const Form = connect(mapStateToProps, mapDispatchToProps)(FormComponent);
