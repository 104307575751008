import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectMainPageSliderAutoSwitch } from 'store/widgets';

import { Banner, Slider } from 'components';

import styles from './styles.pcss';
import { selectMobile } from 'store/selectors';

const cx = classNames.bind(styles);

const BannersBlockComponent = ({ banners, autoSwitch, isMobile, className }) => !isMobile && banners?.length > 0 && (
  <div className={cx('component', className)}>
    {banners.length > 1 ? (
      <Slider autoSwitch={autoSwitch} className={cx('slider')}>
        {banners.map((banner) => <Banner key={banner.alias} className={cx('banner')} {...banner} />)}
      </Slider>
    ) : (
      <Banner className={cx('banner')} {...banners[0]} />
    )}
  </div>
);

BannersBlockComponent.defaultProps = {
  banners: [],
};

BannersBlockComponent.propTypes = {
  banners: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  autoSwitch: PropTypes.number,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
  autoSwitch: selectMainPageSliderAutoSwitch(state),
});

export const BannersBlock = connect(mapStateToProps)(BannersBlockComponent);
