import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectMobile } from 'store/selectors';

import { PrimaryButtonOrLink, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ButtonComponent = ({ onClick, isMobile, isAdaptive, children, className }) => (
  <PrimaryButtonOrLink
    className={cx('component', className)}
    size={isMobile ? 'small' : 'medium'}
    arrow={{
      render: true,
      mode: 'normal',
    }}
    onClick={onClick}
    isAdaptive={isAdaptive}
  >
    <Text isBlack>
      {children}
    </Text>
  </PrimaryButtonOrLink>
);

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMobile: selectMobile(state),
});

export const Button = connect(mapStateToProps)(ButtonComponent);
